/* tslint:disable */
/* eslint-disable */
/**
 * SicKids
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AB001RequiredActionSchema
 */
export interface AB001RequiredActionSchema {
    /**
     * 
     * @type {number}
     * @memberof AB001RequiredActionSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof AB001RequiredActionSchema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof AB001RequiredActionSchema
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof AB001RequiredActionSchema
     */
    'training_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AB001RequiredActionSchema
     */
    'individual_support_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AB001RequiredActionSchema
     */
    'signature_request_id'?: number;
}
/**
 * 
 * @export
 * @interface AC011Schema
 */
export interface AC011Schema {
    /**
     * 
     * @type {number}
     * @memberof AC011Schema
     */
    'signature_request_id': number;
    /**
     * 
     * @type {string}
     * @memberof AC011Schema
     */
    'doc_name': string;
    /**
     * 
     * @type {string}
     * @memberof AC011Schema
     */
    'publication_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof AC011Schema
     */
    'is_signed': boolean;
}
/**
 * 
 * @export
 * @interface AC012Schema
 */
export interface AC012Schema {
    /**
     * 
     * @type {number}
     * @memberof AC012Schema
     */
    'signature_request_id': number;
    /**
     * 
     * @type {string}
     * @memberof AC012Schema
     */
    'doc_name': string;
    /**
     * 
     * @type {string}
     * @memberof AC012Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof AC012Schema
     */
    'publication_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof AC012Schema
     */
    'is_signed': boolean;
    /**
     * 
     * @type {string}
     * @memberof AC012Schema
     */
    'pdf_url'?: string;
}
/**
 * 
 * @export
 * @interface AG002PutSchema
 */
export interface AG002PutSchema {
    /**
     * 
     * @type {number}
     * @memberof AG002PutSchema
     */
    'schedule_detail_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof AG002PutSchema
     */
    'checked': boolean;
}
/**
 * 
 * @export
 * @interface AG002Schema
 */
export interface AG002Schema {
    /**
     * 
     * @type {number}
     * @memberof AG002Schema
     */
    'schedule_detail_id': number;
    /**
     * 
     * @type {string}
     * @memberof AG002Schema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AG002Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof AG002Schema
     */
    'end_time': string;
}
/**
 * 
 * @export
 * @interface AH003Schema
 */
export interface AH003Schema {
    /**
     * 
     * @type {boolean}
     * @memberof AH003Schema
     */
    'push_flg_schedule_reservation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AH003Schema
     */
    'push_flg_notification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AH003Schema
     */
    'push_flg_reservation_confirm': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AH003Schema
     */
    'push_flg_signature_request': boolean;
}
/**
 * 
 * @export
 * @interface AppsAC001ChildSchema
 */
export interface AppsAC001ChildSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'family_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'given_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'gender'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'gender_remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'blood_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'school'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'post_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsAC001ChildSchema
     */
    'same_address_as_parent': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'birth_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'birth_weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'pregnancy_week'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'taking_medicine'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'join_date': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsAC001ChildSchema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsAC001ChildSchema
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAC001ChildSchema
     */
    'updated_by': string;
    /**
     * 
     * @type {AppsFCFacilitySchema}
     * @memberof AppsAC001ChildSchema
     */
    'fc_facility': AppsFCFacilitySchema;
}
/**
 * 
 * @export
 * @interface AppsAD001ChatUsersSchema
 */
export interface AppsAD001ChatUsersSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsAD001ChatUsersSchema
     */
    'fc_staff_id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsAD001ChatUsersSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD001ChatUsersSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD001ChatUsersSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD001ChatUsersSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof AppsAD001ChatUsersSchema
     */
    'unread_count': number;
    /**
     * 
     * @type {number}
     * @memberof AppsAD001ChatUsersSchema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsAD001ChatUsersSchema
     */
    'staff_pic_url'?: string;
    /**
     * 
     * @type {AppsChatMessageSchema}
     * @memberof AppsAD001ChatUsersSchema
     */
    'message_latest'?: AppsChatMessageSchema;
}
/**
 * 
 * @export
 * @interface AppsAD002ChatMessagesSchema
 */
export interface AppsAD002ChatMessagesSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'fc_staff_id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'staff_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'parent_pic_url'?: string;
    /**
     * 
     * @type {Array<AppsFCFacilitySchema>}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'fc_facilities': Array<AppsFCFacilitySchema>;
    /**
     * 
     * @type {Array<AppsChatMessageSchema>}
     * @memberof AppsAD002ChatMessagesSchema
     */
    'chat_messages': Array<AppsChatMessageSchema>;
}
/**
 * 
 * @export
 * @interface AppsAF001NotificationsSchema
 */
export interface AppsAF001NotificationsSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsAF001NotificationsSchema
     */
    'notification_id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsAF001NotificationsSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAF001NotificationsSchema
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof AppsAF001NotificationsSchema
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsAF001NotificationsSchema
     */
    'is_read': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsAF001NotificationsSchema
     */
    'fc_name': string;
}
/**
 * 
 * @export
 * @interface AppsCalendarMarkedSchema
 */
export interface AppsCalendarMarkedSchema {
    /**
     * 
     * @type {string}
     * @memberof AppsCalendarMarkedSchema
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsCalendarMarkedSchema
     */
    'marked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppsCalendarMarkedSchema
     */
    'inactive': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsCalendarMarkedSchema
     */
    'dotColor': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsCalendarMarkedSchema
     */
    'selected': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsCalendarMarkedSchema
     */
    'selectedDotColor': string;
}
/**
 * 
 * @export
 * @interface AppsCalendarSchema
 */
export interface AppsCalendarSchema {
    /**
     * 
     * @type {Array<AppsCalendarMarkedSchema>}
     * @memberof AppsCalendarSchema
     */
    'monthMarked': Array<AppsCalendarMarkedSchema>;
}
/**
 * 
 * @export
 * @interface AppsChatMessageSchema
 */
export interface AppsChatMessageSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsChatMessageSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AppsChatMessageSchema
     */
    'headquarter_staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsChatMessageSchema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsChatMessageSchema
     */
    'parent_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChatMessageSchema
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsChatMessageSchema
     */
    'is_read': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppsChatMessageSchema
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof AppsChatMessageSchema
     */
    'message_from': number;
    /**
     * 
     * @type {string}
     * @memberof AppsChatMessageSchema
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface AppsChildDetailSchema
 */
export interface AppsChildDetailSchema {
    /**
     * 
     * @type {AppsChildSchema}
     * @memberof AppsChildDetailSchema
     */
    'child': AppsChildSchema;
    /**
     * 
     * @type {FCFacilityBaseSchema}
     * @memberof AppsChildDetailSchema
     */
    'fc_facility': FCFacilityBaseSchema;
    /**
     * 
     * @type {Array<DisabilityBaseSchema>}
     * @memberof AppsChildDetailSchema
     */
    'disabilities': Array<DisabilityBaseSchema>;
    /**
     * 
     * @type {DisabilityCertificateSchema}
     * @memberof AppsChildDetailSchema
     */
    'disability_certificate'?: DisabilityCertificateSchema;
}
/**
 * 
 * @export
 * @interface AppsChildSchema
 */
export interface AppsChildSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'family_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'given_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'gender'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'gender_remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'blood_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'school'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'post_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsChildSchema
     */
    'same_address_as_parent': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'birth_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'birth_weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'pregnancy_week'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'taking_medicine'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'join_date': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsChildSchema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsChildSchema
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildSchema
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface AppsChildUpdateSchema
 */
export interface AppsChildUpdateSchema {
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'birthday': string;
    /**
     * 
     * @type {number}
     * @memberof AppsChildUpdateSchema
     */
    'gender'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'gender_remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof AppsChildUpdateSchema
     */
    'blood_type': number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'school'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'post_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'address'?: string;
    /**
     * 
     * @type {Array<AppsDisabilitySchema>}
     * @memberof AppsChildUpdateSchema
     */
    'disabilities': Array<AppsDisabilitySchema>;
    /**
     * 
     * @type {number}
     * @memberof AppsChildUpdateSchema
     */
    'birth_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsChildUpdateSchema
     */
    'birth_weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsChildUpdateSchema
     */
    'pregnancy_week'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'taking_medicine'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsChildUpdateSchema
     */
    'remarks'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsChildUpdateSchema
     */
    'same_address_as_parent': boolean;
}
/**
 * 
 * @export
 * @interface AppsDestinationScreenArgs
 */
export interface AppsDestinationScreenArgs {
    /**
     * 
     * @type {string}
     * @memberof AppsDestinationScreenArgs
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsDestinationScreenArgs
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface AppsDisabilitySchema
 */
export interface AppsDisabilitySchema {
    /**
     * 
     * @type {number}
     * @memberof AppsDisabilitySchema
     */
    'item': number;
}
/**
 * 
 * @export
 * @interface AppsFCFacilitySchema
 */
export interface AppsFCFacilitySchema {
    /**
     * 
     * @type {number}
     * @memberof AppsFCFacilitySchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'office_number': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'post_code': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'address_line': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'weekday_start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'weekday_end_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'holiday_start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'holiday_end_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'regular_holiday': string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsFCFacilitySchema
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsFCFacilitySchema
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface AppsParentDetailSchema
 */
export interface AppsParentDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsParentDetailSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'family_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'given_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'post_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'address_line'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_name1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_kana1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_tel1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_relationship1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_kana2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_tel2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_relationship2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_name3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_kana3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_tel3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentDetailSchema
     */
    'emergency_contact_relationship3'?: string;
}
/**
 * 
 * @export
 * @interface AppsParentSchema
 */
export interface AppsParentSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsParentSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'family_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'given_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'post_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'address_line'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_name1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_kana1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_tel1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_relationship1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_kana2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_tel2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_relationship2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_name3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_kana3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_tel3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'emergency_contact_relationship3'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppsParentSchema
     */
    'is_temp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppsParentSchema
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSchema
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface AppsParentSuperficialSchema
 */
export interface AppsParentSuperficialSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsParentSuperficialSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSuperficialSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSuperficialSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSuperficialSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSuperficialSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof AppsParentSuperficialSchema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsParentSuperficialSchema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface AppsPushNotificationHistory
 */
export interface AppsPushNotificationHistory {
    /**
     * 
     * @type {number}
     * @memberof AppsPushNotificationHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AppsPushNotificationHistory
     */
    'headquarter_staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsPushNotificationHistory
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsPushNotificationHistory
     */
    'parent_id': number;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'destination_screen_key': string;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'destination_screen_name': string;
    /**
     * 
     * @type {Array<AppsDestinationScreenArgs>}
     * @memberof AppsPushNotificationHistory
     */
    'destination_screen_args': Array<AppsDestinationScreenArgs>;
    /**
     * 
     * @type {boolean}
     * @memberof AppsPushNotificationHistory
     */
    'read_flg': boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof AppsPushNotificationHistory
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface AppsRegisterChatMessageSchema
 */
export interface AppsRegisterChatMessageSchema {
    /**
     * 
     * @type {number}
     * @memberof AppsRegisterChatMessageSchema
     */
    'headquarter_staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsRegisterChatMessageSchema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppsRegisterChatMessageSchema
     */
    'parent_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppsRegisterChatMessageSchema
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface AuditDocM001Schema
 */
export interface AuditDocM001Schema {
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'child_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'doc_type': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM001Schema
     */
    'make_date': string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM001Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM001Schema
     */
    'child_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM001Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'parent_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM001Schema
     */
    'file_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'assessment_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM001Schema
     */
    'individual_support_plan_id'?: number;
}
/**
 * 
 * @export
 * @interface AuditDocM002Schema
 */
export interface AuditDocM002Schema {
    /**
     * 
     * @type {number}
     * @memberof AuditDocM002Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM002Schema
     */
    'child_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM002Schema
     */
    'doc_type': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM002Schema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM002Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM002Schema
     */
    'make_date': string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM002Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM002Schema
     */
    'child_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM002Schema
     */
    'child_name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM002Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditDocM002Schema
     */
    'file_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditDocM002Schema
     */
    'training_id'?: number;
}
/**
 * 
 * @export
 * @interface AuditDocsForChildSchema
 */
export interface AuditDocsForChildSchema {
    /**
     * 
     * @type {number}
     * @memberof AuditDocsForChildSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AuditDocsForChildSchema
     */
    'doc_type': number;
}
/**
 * 
 * @export
 * @interface B001AssessmentSchema
 */
export interface B001AssessmentSchema {
    /**
     * 
     * @type {number}
     * @memberof B001AssessmentSchema
     */
    'assessment_id': number;
    /**
     * 
     * @type {number}
     * @memberof B001AssessmentSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof B001AssessmentSchema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof B001AssessmentSchema
     */
    'last_answer_date'?: string;
}
/**
 * 
 * @export
 * @interface B001AuditDocSchema
 */
export interface B001AuditDocSchema {
    /**
     * 
     * @type {number}
     * @memberof B001AuditDocSchema
     */
    'doc_type': number;
    /**
     * 
     * @type {string}
     * @memberof B001AuditDocSchema
     */
    'doc_name': string;
    /**
     * 
     * @type {number}
     * @memberof B001AuditDocSchema
     */
    'num': number;
}
/**
 * 
 * @export
 * @interface B001ChatMessageSchema
 */
export interface B001ChatMessageSchema {
    /**
     * 
     * @type {number}
     * @memberof B001ChatMessageSchema
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof B001ChatMessageSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof B001ChatMessageSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof B001ChatMessageSchema
     */
    'not_read_message_first': string;
    /**
     * 
     * @type {number}
     * @memberof B001ChatMessageSchema
     */
    'not_read_num': number;
}
/**
 * 
 * @export
 * @interface B001ChildSchema
 */
export interface B001ChildSchema {
    /**
     * 
     * @type {number}
     * @memberof B001ChildSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof B001ChildSchema
     */
    'child_name': string;
}
/**
 * 
 * @export
 * @interface B001EventSchema
 */
export interface B001EventSchema {
    /**
     * 
     * @type {string}
     * @memberof B001EventSchema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof B001EventSchema
     */
    'main_title': string;
}
/**
 * 
 * @export
 * @interface B001IndividualSupportPlanSchema
 */
export interface B001IndividualSupportPlanSchema {
    /**
     * 
     * @type {number}
     * @memberof B001IndividualSupportPlanSchema
     */
    'individual_support_plan_id': number;
    /**
     * 
     * @type {number}
     * @memberof B001IndividualSupportPlanSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof B001IndividualSupportPlanSchema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof B001IndividualSupportPlanSchema
     */
    'start_date': string;
}
/**
 * 
 * @export
 * @interface B001NotificationSchema
 */
export interface B001NotificationSchema {
    /**
     * 
     * @type {number}
     * @memberof B001NotificationSchema
     */
    'notification_id': number;
    /**
     * 
     * @type {string}
     * @memberof B001NotificationSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof B001NotificationSchema
     */
    'sent_from': string;
    /**
     * 
     * @type {string}
     * @memberof B001NotificationSchema
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof B001NotificationSchema
     */
    'read_flg': boolean;
}
/**
 * 
 * @export
 * @interface B001OrderSchema
 */
export interface B001OrderSchema {
    /**
     * 
     * @type {number}
     * @memberof B001OrderSchema
     */
    'order_id': number;
    /**
     * 
     * @type {string}
     * @memberof B001OrderSchema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof B001OrderSchema
     */
    'order_detail_num': number;
    /**
     * 
     * @type {number}
     * @memberof B001OrderSchema
     */
    'order_detail_equipment_num': number;
    /**
     * 
     * @type {number}
     * @memberof B001OrderSchema
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface B001Schema
 */
export interface B001Schema {
    /**
     * 
     * @type {Array<B001TrainingSchema>}
     * @memberof B001Schema
     */
    'trainings': Array<B001TrainingSchema>;
    /**
     * 
     * @type {Array<B001EventSchema>}
     * @memberof B001Schema
     */
    'events': Array<B001EventSchema>;
    /**
     * 
     * @type {number}
     * @memberof B001Schema
     */
    'approval_pending_training_num'?: number;
    /**
     * 
     * @type {number}
     * @memberof B001Schema
     */
    'approval_pending_event_num'?: number;
    /**
     * 
     * @type {Array<B001AuditDocSchema>}
     * @memberof B001Schema
     */
    'audit_docs': Array<B001AuditDocSchema>;
    /**
     * 
     * @type {Array<B001IndividualSupportPlanSchema>}
     * @memberof B001Schema
     */
    'individual_support_plans': Array<B001IndividualSupportPlanSchema>;
    /**
     * 
     * @type {Array<B001AssessmentSchema>}
     * @memberof B001Schema
     */
    'assessments': Array<B001AssessmentSchema>;
    /**
     * 
     * @type {Array<B001NotificationSchema>}
     * @memberof B001Schema
     */
    'notifications': Array<B001NotificationSchema>;
    /**
     * 
     * @type {Array<B001ChatMessageSchema>}
     * @memberof B001Schema
     */
    'chat_messages_headquarter': Array<B001ChatMessageSchema>;
    /**
     * 
     * @type {Array<B001ChatMessageSchema>}
     * @memberof B001Schema
     */
    'chat_messages_parent': Array<B001ChatMessageSchema>;
    /**
     * 
     * @type {Array<B001ChatMessageSchema>}
     * @memberof B001Schema
     */
    'chat_messages_staff': Array<B001ChatMessageSchema>;
    /**
     * 
     * @type {Array<B001OrderSchema>}
     * @memberof B001Schema
     */
    'orders': Array<B001OrderSchema>;
    /**
     * 
     * @type {Array<B001ChildSchema>}
     * @memberof B001Schema
     */
    'unregistered_fixed_time_slot': Array<B001ChildSchema>;
}
/**
 * 
 * @export
 * @interface B001TrainingSchema
 */
export interface B001TrainingSchema {
    /**
     * 
     * @type {string}
     * @memberof B001TrainingSchema
     */
    'time': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof B001TrainingSchema
     */
    'children': Array<string>;
}
/**
 * 
 * @export
 * @interface ChildBaseSchema
 */
export interface ChildBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildBaseSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildBaseSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildBaseSchema
     */
    'given_name': string;
}
/**
 * 
 * @export
 * @interface ChildD007Schema
 */
export interface ChildD007Schema {
    /**
     * 
     * @type {string}
     * @memberof ChildD007Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildD007Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildD007Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildD007Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildD007Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {Array<WebDisabilityCertificateSchema>}
     * @memberof ChildD007Schema
     */
    'disability_certificates': Array<WebDisabilityCertificateSchema>;
}
/**
 * 
 * @export
 * @interface ChildD008Schema
 */
export interface ChildD008Schema {
    /**
     * 
     * @type {number}
     * @memberof ChildD008Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof ChildD008Schema
     */
    'support_type': number;
    /**
     * 
     * @type {number}
     * @memberof ChildD008Schema
     */
    'date_count': number;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'date_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'date_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildD008Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildD008Schema
     */
    'child_profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface ChildEventDetailSchema
 */
export interface ChildEventDetailSchema {
    /**
     * 
     * @type {ChildBaseSchema}
     * @memberof ChildEventDetailSchema
     */
    'child': ChildBaseSchema;
    /**
     * 
     * @type {string}
     * @memberof ChildEventDetailSchema
     */
    'fc_facility_name': string;
    /**
     * 
     * @type {EventSchema}
     * @memberof ChildEventDetailSchema
     */
    'event': EventSchema;
    /**
     * 
     * @type {EventDetailSchema}
     * @memberof ChildEventDetailSchema
     */
    'event_detail'?: EventDetailSchema;
}
/**
 * 
 * @export
 * @interface ChildForListSchema
 */
export interface ChildForListSchema {
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof ChildForListSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'join_date': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'facility_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildForListSchema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface ChildForParentCreateSchema
 */
export interface ChildForParentCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof ChildForParentCreateSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentCreateSchema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildForParentCreateSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentCreateSchema
     */
    'join_date': string;
}
/**
 * 
 * @export
 * @interface ChildForParentListSchema
 */
export interface ChildForParentListSchema {
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof ChildForParentListSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'join_date': string;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildForParentListSchema
     */
    'facility_name': string;
}
/**
 * 
 * @export
 * @interface ChildH002Schema
 */
export interface ChildH002Schema {
    /**
     * 
     * @type {string}
     * @memberof ChildH002Schema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildH002Schema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildH002Schema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildH002Schema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof ChildH002Schema
     */
    'id': number;
    /**
     * 
     * @type {FileInfoSchema}
     * @memberof ChildH002Schema
     */
    'file_info'?: FileInfoSchema;
    /**
     * 
     * @type {string}
     * @memberof ChildH002Schema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildH002Schema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildH002Schema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {Array<DisabilityCertificateH002Schema>}
     * @memberof ChildH002Schema
     */
    'disability_certificates': Array<DisabilityCertificateH002Schema>;
    /**
     * 
     * @type {Array<ChildReflectionSchema>}
     * @memberof ChildH002Schema
     */
    'reflections': Array<ChildReflectionSchema>;
}
/**
 * 
 * @export
 * @interface ChildH003Schema
 */
export interface ChildH003Schema {
    /**
     * 
     * @type {string}
     * @memberof ChildH003Schema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildH003Schema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildH003Schema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildH003Schema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof ChildH003Schema
     */
    'id': number;
    /**
     * 
     * @type {FileInfoSchema}
     * @memberof ChildH003Schema
     */
    'file_info'?: FileInfoSchema;
    /**
     * 
     * @type {string}
     * @memberof ChildH003Schema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildH003Schema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildH003Schema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {Array<ChildReflectionSchema>}
     * @memberof ChildH003Schema
     */
    'reflections': Array<ChildReflectionSchema>;
}
/**
 * 
 * @export
 * @interface ChildHealthGraphSchema
 */
export interface ChildHealthGraphSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildHealthGraphSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildHealthGraphSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildHealthGraphSchema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildHealthGraphSchema
     */
    'type': number;
    /**
     * 
     * @type {Array<HealthGraphSchema>}
     * @memberof ChildHealthGraphSchema
     */
    'graph_data': Array<HealthGraphSchema>;
}
/**
 * 
 * @export
 * @interface ChildHealthSchema
 */
export interface ChildHealthSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildHealthSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildHealthSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildHealthSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<HealthBaseSchema>}
     * @memberof ChildHealthSchema
     */
    'health_records'?: Array<HealthBaseSchema>;
}
/**
 * 
 * @export
 * @interface ChildIndividualSupportDetailSchema
 */
export interface ChildIndividualSupportDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'individual_support_plan_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'plan_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'plan_is_notified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'plan_is_signed': boolean;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportDetailSchema
     */
    'monitoring_url'?: string;
}
/**
 * 
 * @export
 * @interface ChildIndividualSupportSchema
 */
export interface ChildIndividualSupportSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildIndividualSupportSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildIndividualSupportSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<IndividualSupportPlanBaseSchema>}
     * @memberof ChildIndividualSupportSchema
     */
    'Individual_support_plan_records': Array<IndividualSupportPlanBaseSchema>;
}
/**
 * 
 * @export
 * @interface ChildInfoSchema
 */
export interface ChildInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchema
     */
    'child_profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface ChildInfoSchemaOld
 */
export interface ChildInfoSchemaOld {
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'blood_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'profile_pic'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'gender'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'birth_weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'birth_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'pregnancy_week'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'medical_history'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'allergy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'taking_medicine'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'foot_size'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildInfoSchemaOld
     */
    'physical_test_record'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'eyesight_right'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'eyesight_left'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildInfoSchemaOld
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ChildReflectionSchema
 */
export interface ChildReflectionSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildReflectionSchema
     */
    'item': number;
}
/**
 * 
 * @export
 * @interface ChildScheduleDetailSchema
 */
export interface ChildScheduleDetailSchema {
    /**
     * 
     * @type {ChildBaseSchema}
     * @memberof ChildScheduleDetailSchema
     */
    'child': ChildBaseSchema;
    /**
     * 
     * @type {FCFacilityBaseSchema}
     * @memberof ChildScheduleDetailSchema
     */
    'fc_facility': FCFacilityBaseSchema;
    /**
     * 
     * @type {ScheduleDetailSchema}
     * @memberof ChildScheduleDetailSchema
     */
    'schedule_detail'?: ScheduleDetailSchema;
    /**
     * 
     * @type {ScheduleReservationFrameSchema}
     * @memberof ChildScheduleDetailSchema
     */
    'schedule_reservation_frame': ScheduleReservationFrameSchema;
}
/**
 * 
 * @export
 * @interface ChildSchema
 */
export interface ChildSchema {
    /**
     * 
     * @type {string}
     * @memberof ChildSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ChildSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof ChildSchema
     */
    'id': number;
    /**
     * 
     * @type {FileInfoSchema}
     * @memberof ChildSchema
     */
    'file_info'?: FileInfoSchema;
    /**
     * 
     * @type {string}
     * @memberof ChildSchema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildSchema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildSchema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface ChildTrainingDetailSchema
 */
export interface ChildTrainingDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildTrainingDetailSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildTrainingDetailSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildTrainingDetailSchema
     */
    'given_name': string;
    /**
     * 
     * @type {TrainingDetailSchema}
     * @memberof ChildTrainingDetailSchema
     */
    'training': TrainingDetailSchema;
    /**
     * 
     * @type {TrainingStaffSchema}
     * @memberof ChildTrainingDetailSchema
     */
    'fc_staff'?: TrainingStaffSchema;
    /**
     * 
     * @type {TrainingFacilitySchema}
     * @memberof ChildTrainingDetailSchema
     */
    'fc_facility': TrainingFacilitySchema;
}
/**
 * 
 * @export
 * @interface ChildTrainingSchema
 */
export interface ChildTrainingSchema {
    /**
     * 
     * @type {number}
     * @memberof ChildTrainingSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildTrainingSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ChildTrainingSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<TrainingProgramSchema>}
     * @memberof ChildTrainingSchema
     */
    'training_records': Array<TrainingProgramSchema>;
}
/**
 * 
 * @export
 * @interface D002Schema
 */
export interface D002Schema {
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'id': number;
    /**
     * 
     * @type {FileInfoSchema}
     * @memberof D002Schema
     */
    'file_info'?: FileInfoSchema;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'profile_pic'?: number;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'gender'?: number;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'gender_remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'blood_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'school'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'post_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'address'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof D002Schema
     */
    'same_address_as_parent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'birth_height'?: number;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'birth_weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof D002Schema
     */
    'pregnancy_week'?: number;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'taking_medicine'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'join_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof D002Schema
     */
    'updated_at'?: string;
    /**
     * 
     * @type {Array<DisabilitySchema>}
     * @memberof D002Schema
     */
    'disabilities': Array<DisabilitySchema>;
    /**
     * 
     * @type {Array<ChildReflectionSchema>}
     * @memberof D002Schema
     */
    'reflections': Array<ChildReflectionSchema>;
    /**
     * 
     * @type {Array<AuditDocsForChildSchema>}
     * @memberof D002Schema
     */
    'audit_docs': Array<AuditDocsForChildSchema>;
}
/**
 * 
 * @export
 * @interface D003HealthRecordSchema
 */
export interface D003HealthRecordSchema {
    /**
     * 
     * @type {number}
     * @memberof D003HealthRecordSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof D003HealthRecordSchema
     */
    'measurement_date': string;
    /**
     * 
     * @type {number}
     * @memberof D003HealthRecordSchema
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof D003HealthRecordSchema
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface D003Schema
 */
export interface D003Schema {
    /**
     * 
     * @type {string}
     * @memberof D003Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof D003Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof D003Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof D003Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof D003Schema
     */
    'child_profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface D004Schema
 */
export interface D004Schema {
    /**
     * 
     * @type {number}
     * @memberof D004Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof D004Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof D004Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof D004Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof D004Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof D004Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof D004Schema
     */
    'measurement_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof D004Schema
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof D004Schema
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface D006AssessmentSchema
 */
export interface D006AssessmentSchema {
    /**
     * 
     * @type {number}
     * @memberof D006AssessmentSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof D006AssessmentSchema
     */
    'answer_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof D006AssessmentSchema
     */
    'is_interviewed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof D006AssessmentSchema
     */
    'is_uploaded': boolean;
    /**
     * 
     * @type {string}
     * @memberof D006AssessmentSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof D006AssessmentSchema
     */
    'file_url'?: string;
}
/**
 * 
 * @export
 * @interface D006Schema
 */
export interface D006Schema {
    /**
     * 
     * @type {string}
     * @memberof D006Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof D006Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof D006Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof D006Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof D006Schema
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof D006Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof D006Schema
     */
    'template_url'?: string;
    /**
     * 
     * @type {Array<D006AssessmentSchema>}
     * @memberof D006Schema
     */
    'assessments': Array<D006AssessmentSchema>;
}
/**
 * 
 * @export
 * @interface D009Schema
 */
export interface D009Schema {
    /**
     * 
     * @type {number}
     * @memberof D009Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof D009Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof D009Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof D009Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof D009Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof D009Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof D009Schema
     */
    'answer_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof D009Schema
     */
    'is_interviewed': boolean;
    /**
     * 
     * @type {string}
     * @memberof D009Schema
     */
    'file_url'?: string;
}
/**
 * 
 * @export
 * @interface D010Schema
 */
export interface D010Schema {
    /**
     * 
     * @type {number}
     * @memberof D010Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof D010Schema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof D010Schema
     */
    'week_day': number;
    /**
     * 
     * @type {string}
     * @memberof D010Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof D010Schema
     */
    'end_time': string;
}
/**
 * 
 * @export
 * @interface DailyEventInfo
 */
export interface DailyEventInfo {
    /**
     * 
     * @type {string}
     * @memberof DailyEventInfo
     */
    'date': string;
    /**
     * 
     * @type {Array<EventChildRecordsSchema>}
     * @memberof DailyEventInfo
     */
    'event_children': Array<EventChildRecordsSchema>;
}
/**
 * 
 * @export
 * @interface DailyScheduleInfo
 */
export interface DailyScheduleInfo {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleInfo
     */
    'date': string;
    /**
     * 
     * @type {Array<ScheduleChildRecordsSchema>}
     * @memberof DailyScheduleInfo
     */
    'schedule_children': Array<ScheduleChildRecordsSchema>;
}
/**
 * 
 * @export
 * @interface DisabilityBaseSchema
 */
export interface DisabilityBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof DisabilityBaseSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DisabilityBaseSchema
     */
    'item': number;
}
/**
 * 
 * @export
 * @interface DisabilityCertificateH002Schema
 */
export interface DisabilityCertificateH002Schema {
    /**
     * 
     * @type {string}
     * @memberof DisabilityCertificateH002Schema
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof DisabilityCertificateH002Schema
     */
    'date_from': string;
    /**
     * 
     * @type {string}
     * @memberof DisabilityCertificateH002Schema
     */
    'date_end': string;
}
/**
 * 
 * @export
 * @interface DisabilityCertificateSchema
 */
export interface DisabilityCertificateSchema {
    /**
     * 
     * @type {string}
     * @memberof DisabilityCertificateSchema
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof DisabilityCertificateSchema
     */
    'support_type': number;
    /**
     * 
     * @type {number}
     * @memberof DisabilityCertificateSchema
     */
    'date_count': number;
    /**
     * 
     * @type {string}
     * @memberof DisabilityCertificateSchema
     */
    'date_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisabilityCertificateSchema
     */
    'date_end'?: string;
}
/**
 * 
 * @export
 * @interface DisabilitySchema
 */
export interface DisabilitySchema {
    /**
     * 
     * @type {number}
     * @memberof DisabilitySchema
     */
    'item': number;
}
/**
 * 
 * @export
 * @interface E001Schema
 */
export interface E001Schema {
    /**
     * 
     * @type {number}
     * @memberof E001Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof E001Schema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof E001Schema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'updated_by': string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof E001Schema
     */
    'template_url'?: string;
}
/**
 * 
 * @export
 * @interface E002Schema
 */
export interface E002Schema {
    /**
     * 
     * @type {number}
     * @memberof E002Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof E002Schema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof E002Schema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'child_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'meeting_file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'plan_file_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof E002Schema
     */
    'plan_is_notified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof E002Schema
     */
    'plan_is_signed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'monitoring_file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof E002Schema
     */
    'plan_idea_file_url'?: string;
}
/**
 * 
 * @export
 * @interface EventBaseSchema
 */
export interface EventBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof EventBaseSchema
     */
    'event_id': number;
    /**
     * 
     * @type {string}
     * @memberof EventBaseSchema
     */
    'main_title': string;
    /**
     * 
     * @type {string}
     * @memberof EventBaseSchema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof EventBaseSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof EventBaseSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof EventBaseSchema
     */
    'status'?: number;
}
/**
 * 
 * @export
 * @interface EventChildDetailSchema
 */
export interface EventChildDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof EventChildDetailSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventChildDetailSchema
     */
    'main_title': string;
    /**
     * 
     * @type {string}
     * @memberof EventChildDetailSchema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof EventChildDetailSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof EventChildDetailSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof EventChildDetailSchema
     */
    'limit': number;
    /**
     * 
     * @type {boolean}
     * @memberof EventChildDetailSchema
     */
    'reservable': boolean;
    /**
     * 
     * @type {EventDetailBaseSchema}
     * @memberof EventChildDetailSchema
     */
    'event_detail'?: EventDetailBaseSchema;
}
/**
 * 
 * @export
 * @interface EventChildRecordsSchema
 */
export interface EventChildRecordsSchema {
    /**
     * 
     * @type {number}
     * @memberof EventChildRecordsSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof EventChildRecordsSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof EventChildRecordsSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<EventChildDetailSchema>}
     * @memberof EventChildRecordsSchema
     */
    'events': Array<EventChildDetailSchema>;
}
/**
 * 
 * @export
 * @interface EventDetailBaseSchema
 */
export interface EventDetailBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof EventDetailBaseSchema
     */
    'event_detail_id': number;
    /**
     * 
     * @type {number}
     * @memberof EventDetailBaseSchema
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface EventDetailSchema
 */
export interface EventDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof EventDetailSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof EventDetailSchema
     */
    'event_id': number;
    /**
     * 
     * @type {number}
     * @memberof EventDetailSchema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof EventDetailSchema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof EventDetailSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailSchema
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface EventSchema
 */
export interface EventSchema {
    /**
     * 
     * @type {number}
     * @memberof EventSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'main_title': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'sub_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'facility_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof EventSchema
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'image_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventSchema
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EventSchema
     */
    'updated_by': string;
    /**
     * 
     * @type {boolean}
     * @memberof EventSchema
     */
    'is_reached': boolean;
}
/**
 * 
 * @export
 * @interface EventV001Schema
 */
export interface EventV001Schema {
    /**
     * 
     * @type {number}
     * @memberof EventV001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventV001Schema
     */
    'main_title': string;
    /**
     * 
     * @type {string}
     * @memberof EventV001Schema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof EventV001Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof EventV001Schema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof EventV001Schema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof EventV001Schema
     */
    'facility_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV001Schema
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof EventV001Schema
     */
    'num': number;
}
/**
 * 
 * @export
 * @interface EventV002Schema
 */
export interface EventV002Schema {
    /**
     * 
     * @type {number}
     * @memberof EventV002Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'main_title': string;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof EventV002Schema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'sub_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventV002Schema
     */
    'facility_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV002Schema
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EventV002Schema
     */
    'image_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventV002Schema
     */
    'num': number;
    /**
     * 
     * @type {Array<WebEventDetailsSchema>}
     * @memberof EventV002Schema
     */
    'event_details': Array<WebEventDetailsSchema>;
}
/**
 * 
 * @export
 * @interface EventV003Schema
 */
export interface EventV003Schema {
    /**
     * 
     * @type {number}
     * @memberof EventV003Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'main_title': string;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'dates': string;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof EventV003Schema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'sub_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventV003Schema
     */
    'facility_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV003Schema
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EventV003Schema
     */
    'image_url'?: string;
}
/**
 * 
 * @export
 * @interface FCFacilitiesSchema
 */
export interface FCFacilitiesSchema {
    /**
     * 
     * @type {string}
     * @memberof FCFacilitiesSchema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof FCFacilitiesSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof FCFacilitiesSchema
     */
    'is_deleted': boolean;
}
/**
 * 
 * @export
 * @interface FCFacilityBaseSchema
 */
export interface FCFacilityBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof FCFacilityBaseSchema
     */
    'fc_facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityBaseSchema
     */
    'facility_name': string;
}
/**
 * 
 * @export
 * @interface FCFacilityF002Schema
 */
export interface FCFacilityF002Schema {
    /**
     * 
     * @type {number}
     * @memberof FCFacilityF002Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'office_number': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'post_code': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'address_line': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'weekday_start_time': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'weekday_end_time': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'weekday_training_start_min'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'holiday_start_time': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'holiday_end_time': string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'holiday_training_start_min'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityF002Schema
     */
    'regular_holiday': string;
}
/**
 * 
 * @export
 * @interface FCFacilityK001Schema
 */
export interface FCFacilityK001Schema {
    /**
     * 
     * @type {string}
     * @memberof FCFacilityK001Schema
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof FCFacilityK001Schema
     */
    'remaining': number;
    /**
     * 
     * @type {number}
     * @memberof FCFacilityK001Schema
     */
    'reserved': number;
    /**
     * 
     * @type {number}
     * @memberof FCFacilityK001Schema
     */
    'training': number;
    /**
     * 
     * @type {number}
     * @memberof FCFacilityK001Schema
     */
    'event'?: number;
    /**
     * 
     * @type {number}
     * @memberof FCFacilityK001Schema
     */
    'closed': number;
}
/**
 * 
 * @export
 * @interface FCFacilityToFCStaffSchema
 */
export interface FCFacilityToFCStaffSchema {
    /**
     * 
     * @type {number}
     * @memberof FCFacilityToFCStaffSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof FCFacilityToFCStaffSchema
     */
    'staff_id': number;
    /**
     * 
     * @type {string}
     * @memberof FCFacilityToFCStaffSchema
     */
    'facility_name': string;
}
/**
 * 
 * @export
 * @interface FCStaffAllInfoSchema
 */
export interface FCStaffAllInfoSchema {
    /**
     * 
     * @type {number}
     * @memberof FCStaffAllInfoSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof FCStaffAllInfoSchema
     */
    'rank': number;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'training'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffAllInfoSchema
     */
    'password_check'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FCStaffAllInfoSchema
     */
    'facility_id_list': Array<number>;
}
/**
 * 
 * @export
 * @interface FCStaffForListSchema
 */
export interface FCStaffForListSchema {
    /**
     * 
     * @type {number}
     * @memberof FCStaffForListSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FCStaffForListSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffForListSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffForListSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffForListSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof FCStaffForListSchema
     */
    'rank': number;
    /**
     * 
     * @type {string}
     * @memberof FCStaffForListSchema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface FCStaffInfoSchema
 */
export interface FCStaffInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {number}
     * @memberof FCStaffInfoSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'rank'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'birthday'?: string;
    /**
     * 
     * @type {number}
     * @memberof FCStaffInfoSchema
     */
    'gender'?: number;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'training'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'profile_pic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'user_type': string;
    /**
     * 
     * @type {Array<FCFacilityToFCStaffSchema>}
     * @memberof FCStaffInfoSchema
     */
    'facilities': Array<FCFacilityToFCStaffSchema>;
    /**
     * 
     * @type {string}
     * @memberof FCStaffInfoSchema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface FileInfoSchema
 */
export interface FileInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof FileInfoSchema
     */
    'file_type': string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoSchema
     */
    'file_size': number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoSchema
     */
    'bucket_name': string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoSchema
     */
    'file_name': string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoSchema
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface G001Schema
 */
export interface G001Schema {
    /**
     * 
     * @type {number}
     * @memberof G001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof G001Schema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof G001Schema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof G001Schema
     */
    'rank'?: number;
    /**
     * 
     * @type {string}
     * @memberof G001Schema
     */
    'facility_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof G001Schema
     */
    'user_type': string;
    /**
     * 
     * @type {number}
     * @memberof G001Schema
     */
    'unread_count': number;
    /**
     * 
     * @type {string}
     * @memberof G001Schema
     */
    'last_sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof G001Schema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface G002ChatMessageCreateSchema
 */
export interface G002ChatMessageCreateSchema {
    /**
     * 
     * @type {number}
     * @memberof G002ChatMessageCreateSchema
     */
    'headquarter_staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof G002ChatMessageCreateSchema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof G002ChatMessageCreateSchema
     */
    'parent_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof G002ChatMessageCreateSchema
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface G002Schema
 */
export interface G002Schema {
    /**
     * 
     * @type {Array<WebChatMessageBaseSchema>}
     * @memberof G002Schema
     */
    'messages': Array<WebChatMessageBaseSchema>;
    /**
     * 
     * @type {string}
     * @memberof G002Schema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof G002Schema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface GeneralBaseSchema
 */
export interface GeneralBaseSchema {
    /**
     * 
     * @type {string}
     * @memberof GeneralBaseSchema
     */
    'keyword': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBaseSchema
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralBaseSchema
     */
    'sort': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralBaseSchema
     */
    'val': string;
}
/**
 * 
 * @export
 * @interface GeneralForListSchema
 */
export interface GeneralForListSchema {
    /**
     * 
     * @type {number}
     * @memberof GeneralForListSchema
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralForListSchema
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface GeneralSchema
 */
export interface GeneralSchema {
    /**
     * 
     * @type {string}
     * @memberof GeneralSchema
     */
    'keyword': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSchema
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralSchema
     */
    'sort': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralSchema
     */
    'val': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralSchema
     */
    'is_deleted': boolean;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HeadquarterUserInfoSchema
 */
export interface HeadquarterUserInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserInfoSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserInfoSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserInfoSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserInfoSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof HeadquarterUserInfoSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserInfoSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserInfoSchema
     */
    'user_type': string;
}
/**
 * 
 * @export
 * @interface HeadquarterUserR001Schema
 */
export interface HeadquarterUserR001Schema {
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR001Schema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR001Schema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof HeadquarterUserR001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR001Schema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface HeadquarterUserR002Schema
 */
export interface HeadquarterUserR002Schema {
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof HeadquarterUserR002Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'password_check'?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadquarterUserR002Schema
     */
    'profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface HealthBaseSchema
 */
export interface HealthBaseSchema {
    /**
     * 
     * @type {string}
     * @memberof HealthBaseSchema
     */
    'measurement_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthBaseSchema
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof HealthBaseSchema
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface HealthGraphDetailSchema
 */
export interface HealthGraphDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof HealthGraphDetailSchema
     */
    'measurement_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthGraphDetailSchema
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof HealthGraphDetailSchema
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface HealthGraphSchema
 */
export interface HealthGraphSchema {
    /**
     * 
     * @type {string}
     * @memberof HealthGraphSchema
     */
    'plot_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthGraphSchema
     */
    'avg_weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthGraphSchema
     */
    'avg_height'?: number;
    /**
     * 
     * @type {Array<HealthGraphDetailSchema>}
     * @memberof HealthGraphSchema
     */
    'data_details'?: Array<HealthGraphDetailSchema>;
}
/**
 * 
 * @export
 * @interface ISPFileSchema
 */
export interface ISPFileSchema {
    /**
     * 
     * @type {number}
     * @memberof ISPFileSchema
     */
    'file_type': number;
    /**
     * 
     * @type {boolean}
     * @memberof ISPFileSchema
     */
    'is_notified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ISPFileSchema
     */
    'is_signed': boolean;
    /**
     * 
     * @type {string}
     * @memberof ISPFileSchema
     */
    'doc_url'?: string;
}
/**
 * 
 * @export
 * @interface IndividualSupportPlanBaseSchema
 */
export interface IndividualSupportPlanBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof IndividualSupportPlanBaseSchema
     */
    'Individual_support_plan_id': number;
    /**
     * 
     * @type {number}
     * @memberof IndividualSupportPlanBaseSchema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof IndividualSupportPlanBaseSchema
     */
    'start_date': string;
    /**
     * 
     * @type {Array<ISPFileSchema>}
     * @memberof IndividualSupportPlanBaseSchema
     */
    'isp_files': Array<ISPFileSchema>;
}
/**
 * 
 * @export
 * @interface J001ReceiveSchema
 */
export interface J001ReceiveSchema {
    /**
     * 
     * @type {number}
     * @memberof J001ReceiveSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof J001ReceiveSchema
     */
    'facility_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof J001ReceiveSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof J001ReceiveSchema
     */
    'body': string;
    /**
     * 
     * @type {boolean}
     * @memberof J001ReceiveSchema
     */
    'read_flg': boolean;
    /**
     * 
     * @type {string}
     * @memberof J001ReceiveSchema
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface J001SendSchema
 */
export interface J001SendSchema {
    /**
     * 
     * @type {number}
     * @memberof J001SendSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof J001SendSchema
     */
    'facility_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof J001SendSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof J001SendSchema
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof J001SendSchema
     */
    'sent_at'?: string;
}
/**
 * 
 * @export
 * @interface J002Schema
 */
export interface J002Schema {
    /**
     * 
     * @type {number}
     * @memberof J002Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof J002Schema
     */
    'facility_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof J002Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof J002Schema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof J002Schema
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof J002Schema
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof J002Schema
     */
    'url'?: string;
    /**
     * 
     * @type {Array<WebNotificationRecipientSchema>}
     * @memberof J002Schema
     */
    'notification_recipients': Array<WebNotificationRecipientSchema>;
    /**
     * 
     * @type {string}
     * @memberof J002Schema
     */
    'sent_at'?: string;
}
/**
 * 
 * @export
 * @interface J003Schema
 */
export interface J003Schema {
    /**
     * 
     * @type {string}
     * @memberof J003Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof J003Schema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof J003Schema
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof J003Schema
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof J003Schema
     */
    'url'?: string;
    /**
     * 
     * @type {Array<WebNotificationRecipientSchema>}
     * @memberof J003Schema
     */
    'notification_recipients': Array<WebNotificationRecipientSchema>;
}
/**
 * 
 * @export
 * @interface K001UnregisteredFTSChildSchema
 */
export interface K001UnregisteredFTSChildSchema {
    /**
     * 
     * @type {number}
     * @memberof K001UnregisteredFTSChildSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof K001UnregisteredFTSChildSchema
     */
    'child_name': string;
}
/**
 * 
 * @export
 * @interface K001WeeklyDetailSchema
 */
export interface K001WeeklyDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'schedule_reservation_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof K001WeeklyDetailSchema
     */
    'start_time': string;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'child_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof K001WeeklyDetailSchema
     */
    'child_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'frame_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'frame_notified_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'training_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'training_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyDetailSchema
     */
    'support_type'?: number;
}
/**
 * 
 * @export
 * @interface K001WeeklyHolidayPlannerSchema
 */
export interface K001WeeklyHolidayPlannerSchema {
    /**
     * 
     * @type {string}
     * @memberof K001WeeklyHolidayPlannerSchema
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklyHolidayPlannerSchema
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface K001WeeklyScheduleSchema
 */
export interface K001WeeklyScheduleSchema {
    /**
     * 
     * @type {string}
     * @memberof K001WeeklyScheduleSchema
     */
    'date': string;
    /**
     * 
     * @type {Array<K001WeeklyDetailSchema>}
     * @memberof K001WeeklyScheduleSchema
     */
    'details': Array<K001WeeklyDetailSchema>;
}
/**
 * 
 * @export
 * @interface K001WeeklySchema
 */
export interface K001WeeklySchema {
    /**
     * 
     * @type {number}
     * @memberof K001WeeklySchema
     */
    'weekday_training_start_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof K001WeeklySchema
     */
    'holiday_training_start_min'?: number;
    /**
     * 
     * @type {Array<K001WeeklyScheduleSchema>}
     * @memberof K001WeeklySchema
     */
    'weekly_schedule_weekday': Array<K001WeeklyScheduleSchema>;
    /**
     * 
     * @type {Array<K001WeeklyScheduleSchema>}
     * @memberof K001WeeklySchema
     */
    'weekly_schedule_holiday': Array<K001WeeklyScheduleSchema>;
    /**
     * 
     * @type {Array<K001WeeklyHolidayPlannerSchema>}
     * @memberof K001WeeklySchema
     */
    'holiday_plan': Array<K001WeeklyHolidayPlannerSchema>;
}
/**
 * 
 * @export
 * @interface K003PutRequestSchema
 */
export interface K003PutRequestSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof K003PutRequestSchema
     */
    'dates': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof K003PutRequestSchema
     */
    'close_dates': Array<string>;
}
/**
 * 
 * @export
 * @interface K003Schema
 */
export interface K003Schema {
    /**
     * 
     * @type {number}
     * @memberof K003Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof K003Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof K003Schema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof K003Schema
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface K005ScheduleDetailSchema
 */
export interface K005ScheduleDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof K005ScheduleDetailSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof K005ScheduleDetailSchema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof K005ScheduleDetailSchema
     */
    'training_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof K005ScheduleDetailSchema
     */
    'training_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof K005ScheduleDetailSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof K005ScheduleDetailSchema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof K005ScheduleDetailSchema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof K005ScheduleDetailSchema
     */
    'parent_id': number;
    /**
     * 
     * @type {string}
     * @memberof K005ScheduleDetailSchema
     */
    'parent_name': string;
    /**
     * 
     * @type {string}
     * @memberof K005ScheduleDetailSchema
     */
    'parent_profile_pic_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof K005ScheduleDetailSchema
     */
    'has_disability_certificate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof K005ScheduleDetailSchema
     */
    'is_empty_disability_certificate_number': boolean;
    /**
     * 
     * @type {string}
     * @memberof K005ScheduleDetailSchema
     */
    'created_at': string;
}
/**
 * 予約枠と、予約枠に紐づくレコードのステータス取得
 * @export
 * @interface K005Schema
 */
export interface K005Schema {
    /**
     * 
     * @type {number}
     * @memberof K005Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof K005Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof K005Schema
     */
    'num': number;
    /**
     * 
     * @type {string}
     * @memberof K005Schema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof K005Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof K005Schema
     */
    'end_time': string;
    /**
     * 
     * @type {Array<K005ScheduleDetailSchema>}
     * @memberof K005Schema
     */
    'schedule_details': Array<K005ScheduleDetailSchema>;
}
/**
 * 
 * @export
 * @interface L001Schema
 */
export interface L001Schema {
    /**
     * 
     * @type {number}
     * @memberof L001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof L001Schema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof L001Schema
     */
    'serial': string;
    /**
     * 
     * @type {string}
     * @memberof L001Schema
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof L001Schema
     */
    'size'?: string;
    /**
     * 
     * @type {number}
     * @memberof L001Schema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof L001Schema
     */
    'stock'?: number;
    /**
     * 
     * @type {string}
     * @memberof L001Schema
     */
    'image_url'?: string;
}
/**
 * 
 * @export
 * @interface L002Schema
 */
export interface L002Schema {
    /**
     * 
     * @type {number}
     * @memberof L002Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof L002Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof L002Schema
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof L002Schema
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof L002Schema
     */
    'num': number;
}
/**
 * 
 * @export
 * @interface L003Schema
 */
export interface L003Schema {
    /**
     * 
     * @type {number}
     * @memberof L003Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof L003Schema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof L003Schema
     */
    'num': number;
    /**
     * 
     * @type {number}
     * @memberof L003Schema
     */
    'delivery_status': number;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'serial': string;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof L003Schema
     */
    'order_date': string;
}
/**
 * 
 * @export
 * @interface L004Schema
 */
export interface L004Schema {
    /**
     * 
     * @type {number}
     * @memberof L004Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'serial': string;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'size'?: string;
    /**
     * 
     * @type {number}
     * @memberof L004Schema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof L004Schema
     */
    'stock'?: number;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof L004Schema
     */
    'order_form_url'?: string;
}
/**
 * 
 * @export
 * @interface L008CartItemSchema
 */
export interface L008CartItemSchema {
    /**
     * 
     * @type {number}
     * @memberof L008CartItemSchema
     */
    'equipment_id': number;
    /**
     * 
     * @type {number}
     * @memberof L008CartItemSchema
     */
    'num': number;
}
/**
 * 
 * @export
 * @interface L008EquipmentSchema
 */
export interface L008EquipmentSchema {
    /**
     * 
     * @type {number}
     * @memberof L008EquipmentSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof L008EquipmentSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof L008EquipmentSchema
     */
    'serial': string;
    /**
     * 
     * @type {string}
     * @memberof L008EquipmentSchema
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof L008EquipmentSchema
     */
    'size'?: string;
    /**
     * 
     * @type {number}
     * @memberof L008EquipmentSchema
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof L008EquipmentSchema
     */
    'available_stock'?: number;
    /**
     * 
     * @type {string}
     * @memberof L008EquipmentSchema
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof L008EquipmentSchema
     */
    'order_form_url'?: string;
}
/**
 * 
 * @export
 * @interface L008Schema
 */
export interface L008Schema {
    /**
     * 
     * @type {number}
     * @memberof L008Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {Array<L008CartItemSchema>}
     * @memberof L008Schema
     */
    'cart_items': Array<L008CartItemSchema>;
}
/**
 * 
 * @export
 * @interface N001Schema
 */
export interface N001Schema {
    /**
     * 
     * @type {number}
     * @memberof N001Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof N001Schema
     */
    'category': number;
    /**
     * 
     * @type {string}
     * @memberof N001Schema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof N001Schema
     */
    'file_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof N001Schema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof N001Schema
     */
    'upload_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof N001Schema
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof N001Schema
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface N002Schema
 */
export interface N002Schema {
    /**
     * 
     * @type {number}
     * @memberof N002Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof N002Schema
     */
    'category': number;
    /**
     * 
     * @type {string}
     * @memberof N002Schema
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof N002Schema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof N002Schema
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof N002Schema
     */
    'upload_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof N002Schema
     */
    'file_url'?: string;
}
/**
 * 
 * @export
 * @interface N003Schema
 */
export interface N003Schema {
    /**
     * 
     * @type {number}
     * @memberof N003Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof N003Schema
     */
    'category': number;
    /**
     * 
     * @type {string}
     * @memberof N003Schema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof N003Schema
     */
    'file_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof N003Schema
     */
    'upload_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof N003Schema
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof N003Schema
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface P001Schema
 */
export interface P001Schema {
    /**
     * 
     * @type {number}
     * @memberof P001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof P001Schema
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof P001Schema
     */
    'step': number;
    /**
     * 
     * @type {number}
     * @memberof P001Schema
     */
    'status': number;
    /**
     * 
     * @type {Array<TrainingToolSchema>}
     * @memberof P001Schema
     */
    'training_tools': Array<TrainingToolSchema>;
    /**
     * 
     * @type {Array<ReflectionSchema>}
     * @memberof P001Schema
     */
    'reflections': Array<ReflectionSchema>;
    /**
     * 
     * @type {string}
     * @memberof P001Schema
     */
    'video_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof P001Schema
     */
    'video_preparing': boolean;
}
/**
 * 
 * @export
 * @interface P002Schema
 */
export interface P002Schema {
    /**
     * 
     * @type {number}
     * @memberof P002Schema
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof P002Schema
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof P002Schema
     */
    'step': number;
    /**
     * 
     * @type {number}
     * @memberof P002Schema
     */
    'movie'?: number;
    /**
     * 
     * @type {number}
     * @memberof P002Schema
     */
    'status': number;
    /**
     * 
     * @type {Array<TrainingToolSchema>}
     * @memberof P002Schema
     */
    'training_tools': Array<TrainingToolSchema>;
    /**
     * 
     * @type {Array<ReflectionSchema>}
     * @memberof P002Schema
     */
    'reflections': Array<ReflectionSchema>;
    /**
     * 
     * @type {string}
     * @memberof P002Schema
     */
    'video_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof P002Schema
     */
    'video_preparing': boolean;
}
/**
 * 
 * @export
 * @interface ParentChildrenCreateSchema
 */
export interface ParentChildrenCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof ParentChildrenCreateSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentChildrenCreateSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentChildrenCreateSchema
     */
    'email': string;
    /**
     * 
     * @type {Array<ChildForParentCreateSchema>}
     * @memberof ParentChildrenCreateSchema
     */
    'children': Array<ChildForParentCreateSchema>;
}
/**
 * 
 * @export
 * @interface ParentDetailSchema
 */
export interface ParentDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof ParentDetailSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {Array<ChildSchema>}
     * @memberof ParentDetailSchema
     */
    'children': Array<ChildSchema>;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'family_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'given_name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'post_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'address_line'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'family_structure'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_name1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_kana1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_tel1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_relationship1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_kana2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_tel2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_relationship2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_name3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_kana3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_tel3'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'emergency_contact_relationship3'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParentDetailSchema
     */
    'is_temp': boolean;
    /**
     * 
     * @type {string}
     * @memberof ParentDetailSchema
     */
    'password_check'?: string;
}
/**
 * 
 * @export
 * @interface ParentEventChildSchema
 */
export interface ParentEventChildSchema {
    /**
     * 
     * @type {number}
     * @memberof ParentEventChildSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventChildSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentEventChildSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<ParentEventDetailSchema>}
     * @memberof ParentEventChildSchema
     */
    'event_details': Array<ParentEventDetailSchema>;
}
/**
 * 
 * @export
 * @interface ParentEventDetailSchema
 */
export interface ParentEventDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ParentEventDetailSchema
     */
    'event_detail_id': number;
    /**
     * 
     * @type {number}
     * @memberof ParentEventDetailSchema
     */
    'status': number;
    /**
     * 
     * @type {EventBaseSchema}
     * @memberof ParentEventDetailSchema
     */
    'event': EventBaseSchema;
}
/**
 * 
 * @export
 * @interface ParentEventMonthRecords
 */
export interface ParentEventMonthRecords {
    /**
     * 
     * @type {number}
     * @memberof ParentEventMonthRecords
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<DailyEventInfo>}
     * @memberof ParentEventMonthRecords
     */
    'one_month_events': Array<DailyEventInfo>;
}
/**
 * 
 * @export
 * @interface ParentEventNextSchema
 */
export interface ParentEventNextSchema {
    /**
     * 
     * @type {number}
     * @memberof ParentEventNextSchema
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<ParentEventChildSchema>}
     * @memberof ParentEventNextSchema
     */
    'children': Array<ParentEventChildSchema>;
}
/**
 * 
 * @export
 * @interface ParentEventTodaySchema
 */
export interface ParentEventTodaySchema {
    /**
     * 
     * @type {number}
     * @memberof ParentEventTodaySchema
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<ParentEventChildSchema>}
     * @memberof ParentEventTodaySchema
     */
    'children': Array<ParentEventChildSchema>;
}
/**
 * 
 * @export
 * @interface ParentForParentListSchema
 */
export interface ParentForParentListSchema {
    /**
     * 
     * @type {string}
     * @memberof ParentForParentListSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentForParentListSchema
     */
    'given_name': string;
    /**
     * 
     * @type {number}
     * @memberof ParentForParentListSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentForParentListSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParentForParentListSchema
     */
    'is_temp': boolean;
    /**
     * 
     * @type {Array<ChildForParentListSchema>}
     * @memberof ParentForParentListSchema
     */
    'children': Array<ChildForParentListSchema>;
}
/**
 * 
 * @export
 * @interface ParentInfoSchema
 */
export interface ParentInfoSchema {
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof ParentInfoSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof ParentInfoSchema
     */
    'subscription_status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'user_type': string;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'profile_pic'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentInfoSchema
     */
    'profile_pic_url'?: string;
    /**
     * 
     * @type {Array<ChildInfoSchemaOld>}
     * @memberof ParentInfoSchema
     */
    'children': Array<ChildInfoSchemaOld>;
    /**
     * 
     * @type {number}
     * @memberof ParentInfoSchema
     */
    'event_detail_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParentInfoSchema
     */
    'is_approved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParentInfoSchema
     */
    'is_temp': boolean;
}
/**
 * 
 * @export
 * @interface ParentNotificationDetailSchema
 */
export interface ParentNotificationDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ParentNotificationDetailSchema
     */
    'notification_id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentNotificationDetailSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ParentNotificationDetailSchema
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof ParentNotificationDetailSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ParentNotificationDetailSchema
     */
    'fc_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentNotificationDetailSchema
     */
    'link_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentNotificationDetailSchema
     */
    'image_url'?: string;
}
/**
 * 
 * @export
 * @interface ParentScheduleChildSchema
 */
export interface ParentScheduleChildSchema {
    /**
     * 
     * @type {number}
     * @memberof ParentScheduleChildSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ParentScheduleChildSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ParentScheduleChildSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<ScheduleReservationFrameDetailSchema>}
     * @memberof ParentScheduleChildSchema
     */
    'schedule_reservation_frames': Array<ScheduleReservationFrameDetailSchema>;
}
/**
 * 
 * @export
 * @interface ParentScheduleMonthRecords
 */
export interface ParentScheduleMonthRecords {
    /**
     * 
     * @type {number}
     * @memberof ParentScheduleMonthRecords
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<DailyScheduleInfo>}
     * @memberof ParentScheduleMonthRecords
     */
    'one_month_schedules': Array<DailyScheduleInfo>;
}
/**
 * 
 * @export
 * @interface ParentScheduleNextRecordSchema
 */
export interface ParentScheduleNextRecordSchema {
    /**
     * 
     * @type {number}
     * @memberof ParentScheduleNextRecordSchema
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<ParentScheduleChildSchema>}
     * @memberof ParentScheduleNextRecordSchema
     */
    'children': Array<ParentScheduleChildSchema>;
}
/**
 * 
 * @export
 * @interface ParentScheduleTodaySchema
 */
export interface ParentScheduleTodaySchema {
    /**
     * 
     * @type {number}
     * @memberof ParentScheduleTodaySchema
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<ParentScheduleChildSchema>}
     * @memberof ParentScheduleTodaySchema
     */
    'children': Array<ParentScheduleChildSchema>;
}
/**
 * 
 * @export
 * @interface ProgramItemForSelectSchema
 */
export interface ProgramItemForSelectSchema {
    /**
     * 
     * @type {number}
     * @memberof ProgramItemForSelectSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProgramItemForSelectSchema
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof ProgramItemForSelectSchema
     */
    'step': number;
    /**
     * 
     * @type {Array<TrainingToolSchema>}
     * @memberof ProgramItemForSelectSchema
     */
    'training_tools': Array<TrainingToolSchema>;
    /**
     * 
     * @type {Array<ReflectionSchema>}
     * @memberof ProgramItemForSelectSchema
     */
    'reflections': Array<ReflectionSchema>;
    /**
     * 
     * @type {string}
     * @memberof ProgramItemForSelectSchema
     */
    'video_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProgramItemForSelectSchema
     */
    'video_preparing': boolean;
}
/**
 * 
 * @export
 * @interface ProgramSelectSchema
 */
export interface ProgramSelectSchema {
    /**
     * 
     * @type {string}
     * @memberof ProgramSelectSchema
     */
    'time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProgramSelectSchema
     */
    'played': boolean;
    /**
     * 
     * @type {P002Schema}
     * @memberof ProgramSelectSchema
     */
    'program_item': P002Schema;
    /**
     * 
     * @type {string}
     * @memberof ProgramSelectSchema
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface ReflectionSchema
 */
export interface ReflectionSchema {
    /**
     * 
     * @type {number}
     * @memberof ReflectionSchema
     */
    'reflection': number;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'msg'?: string;
}
/**
 * 
 * @export
 * @interface SRFBaseSchema
 */
export interface SRFBaseSchema {
    /**
     * 
     * @type {string}
     * @memberof SRFBaseSchema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof SRFBaseSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof SRFBaseSchema
     */
    'end_time': string;
}
/**
 * 
 * @export
 * @interface SRFCreateSchema
 */
export interface SRFCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof SRFCreateSchema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof SRFCreateSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof SRFCreateSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof SRFCreateSchema
     */
    'num': number;
}
/**
 * 予約枠と、予約枠に紐づくレコードのステータス取得
 * @export
 * @interface SRFSchema
 */
export interface SRFSchema {
    /**
     * 
     * @type {string}
     * @memberof SRFSchema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof SRFSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof SRFSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof SRFSchema
     */
    'num': number;
    /**
     * 
     * @type {number}
     * @memberof SRFSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SRFSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {Array<ScheduleDetailStatusSchema>}
     * @memberof SRFSchema
     */
    'schedule_details': Array<ScheduleDetailStatusSchema>;
}
/**
 * 
 * @export
 * @interface ScheduleChildRecordsSchema
 */
export interface ScheduleChildRecordsSchema {
    /**
     * 
     * @type {number}
     * @memberof ScheduleChildRecordsSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleChildRecordsSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleChildRecordsSchema
     */
    'given_name': string;
    /**
     * 
     * @type {Array<ScheduleReservationFrameDetailSchema>}
     * @memberof ScheduleChildRecordsSchema
     */
    'schedule_reservation_frames': Array<ScheduleReservationFrameDetailSchema>;
}
/**
 * 
 * @export
 * @interface ScheduleDetailBaseSchema
 */
export interface ScheduleDetailBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailBaseSchema
     */
    'schedule_detail_id': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailBaseSchema
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface ScheduleDetailSchema
 */
export interface ScheduleDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailSchema
     */
    'schedule_reservation_frame_id': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailSchema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailSchema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleDetailSchema
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface ScheduleDetailStatusSchema
 */
export interface ScheduleDetailStatusSchema {
    /**
     * 
     * @type {number}
     * @memberof ScheduleDetailStatusSchema
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface ScheduleReservationFrameDetailSchema
 */
export interface ScheduleReservationFrameDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'schedule_reservation_frame_id': number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'num': number;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'reservable'?: boolean;
    /**
     * 
     * @type {ScheduleDetailBaseSchema}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'schedule_detail'?: ScheduleDetailBaseSchema;
    /**
     * 
     * @type {number}
     * @memberof ScheduleReservationFrameDetailSchema
     */
    'training_id'?: number;
}
/**
 * 
 * @export
 * @interface ScheduleReservationFrameSchema
 */
export interface ScheduleReservationFrameSchema {
    /**
     * 
     * @type {number}
     * @memberof ScheduleReservationFrameSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleReservationFrameSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleReservationFrameSchema
     */
    'num': number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleReservationFrameSchema
     */
    'updated_by': string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleReservationFrameSchema
     */
    'is_reached': boolean;
}
/**
 * 
 * @export
 * @interface TokenSchema
 */
export interface TokenSchema {
    /**
     * 
     * @type {string}
     * @memberof TokenSchema
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof TokenSchema
     */
    'token_type': string;
}
/**
 * 
 * @export
 * @interface TokenWithIdSchema
 */
export interface TokenWithIdSchema {
    /**
     * 
     * @type {TokenSchema}
     * @memberof TokenWithIdSchema
     */
    'token'?: TokenSchema;
    /**
     * 
     * @type {number}
     * @memberof TokenWithIdSchema
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TrainingDetailSchema
 */
export interface TrainingDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'training_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingDetailSchema
     */
    'training_date': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDetailSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDetailSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingDetailSchema
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'temperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'service_provision_status': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'consultation_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'specialist_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'childcare_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'provided_form'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingDetailSchema
     */
    'training_result_doc_url'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingDetailSchema
     */
    'reflections': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingDetailSchema
     */
    'trainings': Array<string>;
}
/**
 * 
 * @export
 * @interface TrainingFacilitySchema
 */
export interface TrainingFacilitySchema {
    /**
     * 
     * @type {number}
     * @memberof TrainingFacilitySchema
     */
    'fc_facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingFacilitySchema
     */
    'facility_name': string;
}
/**
 * 
 * @export
 * @interface TrainingH001Schema
 */
export interface TrainingH001Schema {
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'training_date': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH001Schema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH001Schema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH001Schema
     */
    'parent_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'parent_name': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH001Schema
     */
    'parent_profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH001Schema
     */
    'absence_type'?: number;
}
/**
 * 
 * @export
 * @interface TrainingH002Schema
 */
export interface TrainingH002Schema {
    /**
     * 
     * @type {string}
     * @memberof TrainingH002Schema
     */
    'training_date': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH002Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH002Schema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'service_provision_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'consultation_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'staff2_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH002Schema
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH002Schema
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH002Schema
     */
    'temperature'?: number;
    /**
     * 
     * @type {ChildH002Schema}
     * @memberof TrainingH002Schema
     */
    'child'?: ChildH002Schema;
    /**
     * 
     * @type {Array<ProgramSelectSchema>}
     * @memberof TrainingH002Schema
     */
    'program_selects': Array<ProgramSelectSchema>;
}
/**
 * 
 * @export
 * @interface TrainingH003Schema
 */
export interface TrainingH003Schema {
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'training_date': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'facility_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'staff2_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'temperature'?: number;
    /**
     * 
     * @type {ChildH003Schema}
     * @memberof TrainingH003Schema
     */
    'child'?: ChildH003Schema;
    /**
     * 
     * @type {Array<ProgramSelectSchema>}
     * @memberof TrainingH003Schema
     */
    'program_selects': Array<ProgramSelectSchema>;
    /**
     * 
     * @type {Array<ProgramSelectSchema>}
     * @memberof TrainingH003Schema
     */
    'program_adds': Array<ProgramSelectSchema>;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'service_provision_status'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'consultation_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'specialist_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'childcare_support_addition'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'provided_form'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'provision_record_sheet_remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'support_type': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingH003Schema
     */
    'audit_doc_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'staff_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingH003Schema
     */
    'staff2_name'?: string;
}
/**
 * 
 * @export
 * @interface TrainingProgramSchema
 */
export interface TrainingProgramSchema {
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSchema
     */
    'training_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSchema
     */
    'training_date': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSchema
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingProgramSchema
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSchema
     */
    'child_id': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSchema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof TrainingProgramSchema
     */
    'service_provision_status': number;
}
/**
 * 
 * @export
 * @interface TrainingStaffSchema
 */
export interface TrainingStaffSchema {
    /**
     * 
     * @type {number}
     * @memberof TrainingStaffSchema
     */
    'fc_staff_id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingStaffSchema
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingStaffSchema
     */
    'given_name': string;
}
/**
 * 
 * @export
 * @interface TrainingToolSchema
 */
export interface TrainingToolSchema {
    /**
     * 
     * @type {number}
     * @memberof TrainingToolSchema
     */
    'training_tool': number;
    /**
     * 
     * @type {string}
     * @memberof TrainingToolSchema
     */
    'remarks'?: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface W001Schema
 */
export interface W001Schema {
    /**
     * 
     * @type {number}
     * @memberof W001Schema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof W001Schema
     */
    'facility_name': string;
    /**
     * 
     * @type {string}
     * @memberof W001Schema
     */
    'doc_name': string;
    /**
     * 
     * @type {string}
     * @memberof W001Schema
     */
    'publication_date': string;
    /**
     * 
     * @type {number}
     * @memberof W001Schema
     */
    'notified_num': number;
    /**
     * 
     * @type {number}
     * @memberof W001Schema
     */
    'no_signature_num': number;
}
/**
 * 
 * @export
 * @interface W002Schema
 */
export interface W002Schema {
    /**
     * 
     * @type {number}
     * @memberof W002Schema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof W002Schema
     */
    'doc_type': number;
    /**
     * 
     * @type {string}
     * @memberof W002Schema
     */
    'file_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof W002Schema
     */
    'publication_date': string;
}
/**
 * 
 * @export
 * @interface WebChatMessageBaseSchema
 */
export interface WebChatMessageBaseSchema {
    /**
     * 
     * @type {number}
     * @memberof WebChatMessageBaseSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WebChatMessageBaseSchema
     */
    'headquarter_staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebChatMessageBaseSchema
     */
    'staff_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WebChatMessageBaseSchema
     */
    'parent_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebChatMessageBaseSchema
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebChatMessageBaseSchema
     */
    'is_read': boolean;
    /**
     * 
     * @type {number}
     * @memberof WebChatMessageBaseSchema
     */
    'message_from': number;
    /**
     * 
     * @type {string}
     * @memberof WebChatMessageBaseSchema
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface WebDisabilityCertificateSchema
 */
export interface WebDisabilityCertificateSchema {
    /**
     * 
     * @type {number}
     * @memberof WebDisabilityCertificateSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WebDisabilityCertificateSchema
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof WebDisabilityCertificateSchema
     */
    'support_type': number;
    /**
     * 
     * @type {number}
     * @memberof WebDisabilityCertificateSchema
     */
    'date_count': number;
    /**
     * 
     * @type {string}
     * @memberof WebDisabilityCertificateSchema
     */
    'date_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebDisabilityCertificateSchema
     */
    'date_end'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebDisabilityCertificateSchema
     */
    'is_temp': boolean;
}
/**
 * 
 * @export
 * @interface WebEventDetailsSchema
 */
export interface WebEventDetailsSchema {
    /**
     * 
     * @type {number}
     * @memberof WebEventDetailsSchema
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WebEventDetailsSchema
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof WebEventDetailsSchema
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof WebEventDetailsSchema
     */
    'child_name': string;
    /**
     * 
     * @type {string}
     * @memberof WebEventDetailsSchema
     */
    'child_profile_pic_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebEventDetailsSchema
     */
    'parent_id': number;
    /**
     * 
     * @type {string}
     * @memberof WebEventDetailsSchema
     */
    'parent_name': string;
    /**
     * 
     * @type {string}
     * @memberof WebEventDetailsSchema
     */
    'parent_profile_pic_url'?: string;
}
/**
 * 
 * @export
 * @interface WebNotificationRecipientSchema
 */
export interface WebNotificationRecipientSchema {
    /**
     * 
     * @type {number}
     * @memberof WebNotificationRecipientSchema
     */
    'type': number;
    /**
     * 
     * @type {number}
     * @memberof WebNotificationRecipientSchema
     */
    'recipient'?: number;
}

/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 予約の取り消し  ## PERMISSION 親のみ実行可能
         * @summary Cancel Ag003
         * @param {number} scheduleDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAG003: async (scheduleDetailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleDetailId' is not null or undefined
            assertParamExists('cancelAG003', 'scheduleDetailId', scheduleDetailId)
            const localVarPath = `/api/v1/apps/schedules/cancel_AG003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (scheduleDetailId !== undefined) {
                localVarQueryParameter['schedule_detail_id'] = scheduleDetailId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約の取り消し  ## PERMISSION 親のみ実行可能
         * @summary Cancel Ag005
         * @param {number} scheduleDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAG005: async (scheduleDetailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleDetailId' is not null or undefined
            assertParamExists('cancelAG005', 'scheduleDetailId', scheduleDetailId)
            const localVarPath = `/api/v1/apps/events/cancel_AG005`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (scheduleDetailId !== undefined) {
                localVarQueryParameter['schedule_detail_id'] = scheduleDetailId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メッセージを削除  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Delete Chat Message
         * @param {number} parentId 
         * @param {number} staffId 
         * @param {number} chatMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChatMessage: async (parentId: number, staffId: number, chatMessageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('deleteChatMessage', 'parentId', parentId)
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('deleteChatMessage', 'staffId', staffId)
            // verify required parameter 'chatMessageId' is not null or undefined
            assertParamExists('deleteChatMessage', 'chatMessageId', chatMessageId)
            const localVarPath = `/api/v1/apps/chat_messages/{parent_id}/{staff_id}/{chat_message_id}/delete`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"staff_id"}}`, encodeURIComponent(String(staffId)))
                .replace(`{${"chat_message_id"}}`, encodeURIComponent(String(chatMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デバイストークンを削除  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Delete Mobile Device Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMobileDeviceToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('deleteMobileDeviceToken', 'token', token)
            const localVarPath = `/api/v1/apps/mobile_device_tokens/deleteMobileDeviceToken/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様のヘルスデータ(身長、体重）チャート情報取得  特定の子供の親であれば実行可能
         * @summary Get Ac004
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAC004: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getAC004', 'childId', childId)
            const localVarPath = `/api/v1/apps/health_records/{child_id}/get_AC004`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様の署名依頼一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Ac011
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAC011: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getAC011', 'childId', childId)
            const localVarPath = `/api/v1/apps/signature_requests/{child_id}/get_AC011`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Ac012
         * @param {number} childId 
         * @param {any} signatureRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAC012: async (childId: number, signatureRequestId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getAC012', 'childId', childId)
            // verify required parameter 'signatureRequestId' is not null or undefined
            assertParamExists('getAC012', 'signatureRequestId', signatureRequestId)
            const localVarPath = `/api/v1/apps/signature_requests/{child_id}/get_AC012`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (signatureRequestId !== undefined) {
                localVarQueryParameter['signature_request_id'] = signatureRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 次月トレーニング予約レコード取得  特定の子供の親であれば実行可能
         * @summary Get Ag002
         * @param {number} childId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAG002: async (childId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getAG002', 'childId', childId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAG002', 'date', date)
            const localVarPath = `/api/v1/apps/children/{child_id}/get_AG002`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バッジ数を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Badge Count
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadgeCount: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getBadgeCount', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/push_notification_histories/getBadgeCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カレンダーのマーカーを取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Calendar Marked
         * @param {number} parentId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarMarked: async (parentId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getCalendarMarked', 'parentId', parentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCalendarMarked', 'date', date)
            const localVarPath = `/api/v1/apps/calendars/{parent_id}/{date}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のチャット相手とのメッセージ一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Chat Messages
         * @param {number} staffId 
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatMessages: async (staffId: number, parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('getChatMessages', 'staffId', staffId)
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getChatMessages', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/chat_messages/users/{staff_id}`
                .replace(`{${"staff_id"}}`, encodeURIComponent(String(staffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャット相手一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Chat Users
         * @param {number} parentId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatUsers: async (parentId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getChatUsers', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/chat_messages/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択したイベントの詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Event Detail Record
         * @param {number} childId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildEventDetailRecord: async (childId: number, eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildEventDetailRecord', 'childId', childId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getChildEventDetailRecord', 'eventId', eventId)
            const localVarPath = `/api/v1/apps/events/day/detail/{child_id}/{event_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のお子様のグラフ用ヘルスデータを取得  特定の子供の親であれば実行可能
         * @summary Get Child Health Graph
         * @param {number} childId 
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildHealthGraph: async (childId: number, type: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildHealthGraph', 'childId', childId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getChildHealthGraph', 'type', type)
            const localVarPath = `/api/v1/apps/health_records/{child_id}/{type}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のお子様のヘルスデータ(身長、体重）一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Health Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildHealthRecords: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildHealthRecords', 'childId', childId)
            const localVarPath = `/api/v1/apps/health_records/{child_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のお子様の個別支援計画の詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Individual Support Detail
         * @param {number} childId 
         * @param {number} individualSupportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildIndividualSupportDetail: async (childId: number, individualSupportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildIndividualSupportDetail', 'childId', childId)
            // verify required parameter 'individualSupportId' is not null or undefined
            assertParamExists('getChildIndividualSupportDetail', 'individualSupportId', individualSupportId)
            const localVarPath = `/api/v1/apps/individual_supports/{child_id}/{individual_support_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"individual_support_id"}}`, encodeURIComponent(String(individualSupportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のお子様の個別支援計画の一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Individual Support Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildIndividualSupportRecords: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildIndividualSupportRecords', 'childId', childId)
            const localVarPath = `/api/v1/apps/individual_supports/{child_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のお子様の詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Record
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildRecord: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildRecord', 'childId', childId)
            const localVarPath = `/api/v1/apps/children/detail/{child_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択したスケジュールの詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Schedule Day Record
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildScheduleDayRecord: async (childId: number, scheduleReservationFrameId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildScheduleDayRecord', 'childId', childId)
            // verify required parameter 'scheduleReservationFrameId' is not null or undefined
            assertParamExists('getChildScheduleDayRecord', 'scheduleReservationFrameId', scheduleReservationFrameId)
            const localVarPath = `/api/v1/apps/schedules/day/detail/{child_id}/{schedule_reservation_frame_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"schedule_reservation_frame_id"}}`, encodeURIComponent(String(scheduleReservationFrameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のトレーニング詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Training Detail
         * @param {number} childId 
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildTrainingDetail: async (childId: number, trainingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildTrainingDetail', 'childId', childId)
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('getChildTrainingDetail', 'trainingId', trainingId)
            const localVarPath = `/api/v1/apps/trainings/{child_id}/{training_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"training_id"}}`, encodeURIComponent(String(trainingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択したお子様のトレーニング一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Training Records
         * @param {number} childId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildTrainingRecords: async (childId: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildTrainingRecords', 'childId', childId)
            const localVarPath = `/api/v1/apps/trainings/{child_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Children
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getChildren', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/children/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意のお子様の障がい一覧を取得（現在は使用していない）  特定の子供の親であれば実行可能
         * @summary Get Disability Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisabilityRecords: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getDisabilityRecords', 'childId', childId)
            const localVarPath = `/api/v1/apps/disabilities/{child_id}`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 親のみ実行可能
         * @summary Get Generals
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerals: async (keyword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getGenerals', 'keyword', keyword)
            const localVarPath = `/api/v1/apps/generals/{keyword}/get_generals`
                .replace(`{${"keyword"}}`, encodeURIComponent(String(keyword)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新着情報一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Notifications
         * @param {number} parentId 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (parentId: number, search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getNotifications', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/notifications/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択した月、教室に応じた日にちごとのイベント予約状況を取得（カレンダーのマーカーとして使用）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Month Records
         * @param {number} parentId 
         * @param {string} date 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentEventMonthRecords: async (parentId: number, date: string, facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentEventMonthRecords', 'parentId', parentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getParentEventMonthRecords', 'date', date)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getParentEventMonthRecords', 'facilityId', facilityId)
            const localVarPath = `/api/v1/apps/events/month/{parent_id}/{date}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 次回のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Next Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentEventNextRecords: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentEventNextRecords', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/events/next/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 本日のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Today Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentEventTodayRecords: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentEventTodayRecords', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/events/today/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教室一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Facility Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentFacilityRecords: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentFacilityRecords', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/fc_facilities/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の画像を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Image
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentImage: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentImage', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/parents/{parent_id}/get_image`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 任意の新着情報の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Notification Detail
         * @param {number} parentId 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentNotificationDetail: async (parentId: number, notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentNotificationDetail', 'parentId', parentId)
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('getParentNotificationDetail', 'notificationId', notificationId)
            const localVarPath = `/api/v1/apps/notifications/{parent_id}/{notification_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Record
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentRecord: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentRecord', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/parents/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択した月、教室に応じた日にちごとのスケジュール情報を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Month Records
         * @param {number} parentId 
         * @param {string} date 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentScheduleMonthRecords: async (parentId: number, date: string, facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentScheduleMonthRecords', 'parentId', parentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getParentScheduleMonthRecords', 'date', date)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getParentScheduleMonthRecords', 'facilityId', facilityId)
            const localVarPath = `/api/v1/apps/schedules/month/{parent_id}/{date}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 次回のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Next Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentScheduleNextRecords: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentScheduleNextRecords', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/schedules/next/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 本日のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Today Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentScheduleTodayRecords: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParentScheduleTodayRecords', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/schedules/today/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プッシュ通知フラグ取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Push Flg
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPushFlg: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getPushFlg', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/parents/{parent_id}/get_push_flg`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プッシュ通知の履歴一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Push Notification Histories
         * @param {number} parentId 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPushNotificationHistories: async (parentId: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getPushNotificationHistories', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/push_notification_histories/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 要対応リスト取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Required Actions
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequiredActions: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getRequiredActions', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/parents/{parent_id}/get_required_actions`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exceptionをログに出力  ## PERMISSION 親のみ実行可能
         * @summary Log Exception
         * @param {string} title 
         * @param {string} exception 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logException: async (title: string, exception: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('logException', 'title', title)
            // verify required parameter 'exception' is not null or undefined
            assertParamExists('logException', 'exception', exception)
            const localVarPath = `/api/v1/apps/others/log_exception`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (exception !== undefined) {
                localVarQueryParameter['exception'] = exception;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング予約確定  特定の子供の親であれば実行可能
         * @summary Put Ag002
         * @param {number} childId 
         * @param {Array<AG002PutSchema>} aG002PutSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAG002: async (childId: number, aG002PutSchema: Array<AG002PutSchema>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('putAG002', 'childId', childId)
            // verify required parameter 'aG002PutSchema' is not null or undefined
            assertParamExists('putAG002', 'aG002PutSchema', aG002PutSchema)
            const localVarPath = `/api/v1/apps/children/put_AG002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aG002PutSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デバイストークンを登録（プッシュ通知に使用）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Put Mobile Device Token
         * @param {number} parentId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMobileDeviceToken: async (parentId: number, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('putMobileDeviceToken', 'parentId', parentId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('putMobileDeviceToken', 'token', token)
            const localVarPath = `/api/v1/apps/mobile_device_tokens/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 未読メッセージを既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Read Chat Message
         * @param {number} parentId 
         * @param {number} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readChatMessage: async (parentId: number, staffId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('readChatMessage', 'parentId', parentId)
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('readChatMessage', 'staffId', staffId)
            const localVarPath = `/api/v1/apps/chat_messages/{parent_id}/{staff_id}/read`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"staff_id"}}`, encodeURIComponent(String(staffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新着情報を既読にする  ## PERMISSION 親のみ実行可能
         * @summary Read Notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('readNotification', 'notificationId', notificationId)
            const localVarPath = `/api/v1/apps/notifications/{notification_id}/read`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メッセージを登録  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Register Chat Message
         * @param {AppsRegisterChatMessageSchema} appsRegisterChatMessageSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerChatMessage: async (appsRegisterChatMessageSchema: AppsRegisterChatMessageSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appsRegisterChatMessageSchema' is not null or undefined
            assertParamExists('registerChatMessage', 'appsRegisterChatMessageSchema', appsRegisterChatMessageSchema)
            const localVarPath = `/api/v1/apps/chat_messages/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appsRegisterChatMessageSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択したスケジュールを予約  特定の子供の親であれば実行可能
         * @summary Reserve Ag003
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveAG003: async (childId: number, scheduleReservationFrameId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('reserveAG003', 'childId', childId)
            // verify required parameter 'scheduleReservationFrameId' is not null or undefined
            assertParamExists('reserveAG003', 'scheduleReservationFrameId', scheduleReservationFrameId)
            const localVarPath = `/api/v1/apps/schedules/reserve_AG003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (scheduleReservationFrameId !== undefined) {
                localVarQueryParameter['schedule_reservation_frame_id'] = scheduleReservationFrameId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択したスケジュールを予約  特定の子供の親であれば実行可能
         * @summary Reserve Ag005
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveAG005: async (childId: number, scheduleReservationFrameId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('reserveAG005', 'childId', childId)
            // verify required parameter 'scheduleReservationFrameId' is not null or undefined
            assertParamExists('reserveAG005', 'scheduleReservationFrameId', scheduleReservationFrameId)
            const localVarPath = `/api/v1/apps/events/reserve_AG005`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (scheduleReservationFrameId !== undefined) {
                localVarQueryParameter['schedule_reservation_frame_id'] = scheduleReservationFrameId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼の署名を登録  特定の子供の親であれば実行可能
         * @summary Save Ac012 Sign
         * @param {number} childId 
         * @param {number} signatureRequestId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAC012Sign: async (childId: number, signatureRequestId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('saveAC012Sign', 'childId', childId)
            // verify required parameter 'signatureRequestId' is not null or undefined
            assertParamExists('saveAC012Sign', 'signatureRequestId', signatureRequestId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveAC012Sign', 'file', file)
            const localVarPath = `/api/v1/apps/signature_requests/{child_id}/{signature_request_id}/save_AC012_sign`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"signature_request_id"}}`, encodeURIComponent(String(signatureRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様の画像を変更  特定の子供の親であれば実行可能
         * @summary Save Child Image
         * @param {number} childId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveChildImage: async (childId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('saveChildImage', 'childId', childId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveChildImage', 'file', file)
            const localVarPath = `/api/v1/apps/children/{child_id}/save_image`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画の署名を保存  特定の子供の親であれば実行可能
         * @summary Save Individual Support Sign
         * @param {number} childId 
         * @param {number} individualSupportId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIndividualSupportSign: async (childId: number, individualSupportId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('saveIndividualSupportSign', 'childId', childId)
            // verify required parameter 'individualSupportId' is not null or undefined
            assertParamExists('saveIndividualSupportSign', 'individualSupportId', individualSupportId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveIndividualSupportSign', 'file', file)
            const localVarPath = `/api/v1/apps/individual_supports/{child_id}/{individual_support_id}/save_sign`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"individual_support_id"}}`, encodeURIComponent(String(individualSupportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の画像を変更  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Save Parent Image
         * @param {number} parentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveParentImage: async (parentId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('saveParentImage', 'parentId', parentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveParentImage', 'file', file)
            const localVarPath = `/api/v1/apps/parents/{parent_id}/save_image`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プッシュ通知フラグ更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Save Push Flg
         * @param {number} parentId 
         * @param {AH003Schema} aH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePushFlg: async (parentId: number, aH003Schema: AH003Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('savePushFlg', 'parentId', parentId)
            // verify required parameter 'aH003Schema' is not null or undefined
            assertParamExists('savePushFlg', 'aH003Schema', aH003Schema)
            const localVarPath = `/api/v1/apps/parents/{parent_id}/save_push_flg`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aH003Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニングの署名を保存  特定の子供の親であれば実行可能
         * @summary Save Training Result Sign
         * @param {number} childId 
         * @param {number} trainingId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrainingResultSign: async (childId: number, trainingId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('saveTrainingResultSign', 'childId', childId)
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('saveTrainingResultSign', 'trainingId', trainingId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveTrainingResultSign', 'file', file)
            const localVarPath = `/api/v1/apps/trainings/{child_id}/{training_id}/save_sign`
                .replace(`{${"child_id"}}`, encodeURIComponent(String(childId)))
                .replace(`{${"training_id"}}`, encodeURIComponent(String(trainingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様の情報を更新  特定の子供の親であれば実行可能
         * @summary Update Child
         * @param {number} childId 
         * @param {AppsChildUpdateSchema} appsChildUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChild: async (childId: number, appsChildUpdateSchema: AppsChildUpdateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('updateChild', 'childId', childId)
            // verify required parameter 'appsChildUpdateSchema' is not null or undefined
            assertParamExists('updateChild', 'appsChildUpdateSchema', appsChildUpdateSchema)
            const localVarPath = `/api/v1/apps/children/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appsChildUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の情報を更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Parent
         * @param {number} parentId 
         * @param {AppsParentDetailSchema} appsParentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParent: async (parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('updateParent', 'parentId', parentId)
            // verify required parameter 'appsParentDetailSchema' is not null or undefined
            assertParamExists('updateParent', 'appsParentDetailSchema', appsParentDetailSchema)
            const localVarPath = `/api/v1/apps/parents/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appsParentDetailSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Push Notification Read Flg
         * @param {number} parentId 
         * @param {number} pushNotificationHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePushNotificationReadFlg: async (parentId: number, pushNotificationHistoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('updatePushNotificationReadFlg', 'parentId', parentId)
            // verify required parameter 'pushNotificationHistoryId' is not null or undefined
            assertParamExists('updatePushNotificationReadFlg', 'pushNotificationHistoryId', pushNotificationHistoryId)
            const localVarPath = `/api/v1/apps/push_notification_histories/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }

            if (pushNotificationHistoryId !== undefined) {
                localVarQueryParameter['push_notification_history_id'] = pushNotificationHistoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Push Notification Read Flg All
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePushNotificationReadFlgAll: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('updatePushNotificationReadFlgAll', 'parentId', parentId)
            const localVarPath = `/api/v1/apps/push_notification_histories/readAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の仮登録を本登録に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Temp Parent
         * @param {number} parentId 
         * @param {AppsParentDetailSchema} appsParentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTempParent: async (parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('updateTempParent', 'parentId', parentId)
            // verify required parameter 'appsParentDetailSchema' is not null or undefined
            assertParamExists('updateTempParent', 'appsParentDetailSchema', appsParentDetailSchema)
            const localVarPath = `/api/v1/apps/parents/{parent_id}/temp`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appsParentDetailSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration)
    return {
        /**
         * 予約の取り消し  ## PERMISSION 親のみ実行可能
         * @summary Cancel Ag003
         * @param {number} scheduleDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAG003(scheduleDetailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAG003(scheduleDetailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約の取り消し  ## PERMISSION 親のみ実行可能
         * @summary Cancel Ag005
         * @param {number} scheduleDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAG005(scheduleDetailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAG005(scheduleDetailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メッセージを削除  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Delete Chat Message
         * @param {number} parentId 
         * @param {number} staffId 
         * @param {number} chatMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChatMessage(parentId: number, staffId: number, chatMessageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChatMessage(parentId, staffId, chatMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デバイストークンを削除  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Delete Mobile Device Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMobileDeviceToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMobileDeviceToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様のヘルスデータ(身長、体重）チャート情報取得  特定の子供の親であれば実行可能
         * @summary Get Ac004
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAC004(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAC004(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様の署名依頼一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Ac011
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAC011(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AC011Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAC011(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Ac012
         * @param {number} childId 
         * @param {any} signatureRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAC012(childId: number, signatureRequestId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AC012Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAC012(childId, signatureRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 次月トレーニング予約レコード取得  特定の子供の親であれば実行可能
         * @summary Get Ag002
         * @param {number} childId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAG002(childId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AG002Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAG002(childId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バッジ数を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Badge Count
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBadgeCount(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBadgeCount(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カレンダーのマーカーを取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Calendar Marked
         * @param {number} parentId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarMarked(parentId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsCalendarSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarMarked(parentId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のチャット相手とのメッセージ一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Chat Messages
         * @param {number} staffId 
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatMessages(staffId: number, parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsAD002ChatMessagesSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatMessages(staffId, parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャット相手一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Chat Users
         * @param {number} parentId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatUsers(parentId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsAD001ChatUsersSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatUsers(parentId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択したイベントの詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Event Detail Record
         * @param {number} childId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildEventDetailRecord(childId: number, eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildEventDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildEventDetailRecord(childId, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のお子様のグラフ用ヘルスデータを取得  特定の子供の親であれば実行可能
         * @summary Get Child Health Graph
         * @param {number} childId 
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildHealthGraph(childId: number, type: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildHealthGraphSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildHealthGraph(childId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のお子様のヘルスデータ(身長、体重）一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Health Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildHealthRecords(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildHealthSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildHealthRecords(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のお子様の個別支援計画の詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Individual Support Detail
         * @param {number} childId 
         * @param {number} individualSupportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildIndividualSupportDetail(childId: number, individualSupportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildIndividualSupportDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildIndividualSupportDetail(childId, individualSupportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のお子様の個別支援計画の一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Individual Support Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildIndividualSupportRecords(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildIndividualSupportSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildIndividualSupportRecords(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のお子様の詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Record
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildRecord(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsChildDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildRecord(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択したスケジュールの詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Schedule Day Record
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildScheduleDayRecord(childId: number, scheduleReservationFrameId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildScheduleDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildScheduleDayRecord(childId, scheduleReservationFrameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のトレーニング詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Training Detail
         * @param {number} childId 
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildTrainingDetail(childId: number, trainingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildTrainingDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildTrainingDetail(childId, trainingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択したお子様のトレーニング一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Training Records
         * @param {number} childId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildTrainingRecords(childId: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildTrainingSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildTrainingRecords(childId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Children
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildren(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsAC001ChildSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildren(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意のお子様の障がい一覧を取得（現在は使用していない）  特定の子供の親であれば実行可能
         * @summary Get Disability Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisabilityRecords(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisabilityBaseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDisabilityRecords(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 親のみ実行可能
         * @summary Get Generals
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenerals(keyword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralBaseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGenerals(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新着情報一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Notifications
         * @param {number} parentId 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(parentId: number, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsAF001NotificationsSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(parentId, search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択した月、教室に応じた日にちごとのイベント予約状況を取得（カレンダーのマーカーとして使用）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Month Records
         * @param {number} parentId 
         * @param {string} date 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentEventMonthRecords(parentId: number, date: string, facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventMonthRecords>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentEventMonthRecords(parentId, date, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 次回のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Next Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentEventNextRecords(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventNextSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentEventNextRecords(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 本日のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Today Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentEventTodayRecords(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventTodaySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentEventTodayRecords(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教室一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Facility Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentFacilityRecords(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FCFacilityBaseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentFacilityRecords(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の画像を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Image
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentImage(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsParentSuperficialSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentImage(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 任意の新着情報の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Notification Detail
         * @param {number} parentId 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentNotificationDetail(parentId: number, notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentNotificationDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentNotificationDetail(parentId, notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Record
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentRecord(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsParentSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentRecord(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択した月、教室に応じた日にちごとのスケジュール情報を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Month Records
         * @param {number} parentId 
         * @param {string} date 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentScheduleMonthRecords(parentId: number, date: string, facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentScheduleMonthRecords>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentScheduleMonthRecords(parentId, date, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 次回のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Next Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentScheduleNextRecords(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentScheduleNextRecordSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentScheduleNextRecords(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 本日のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Today Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentScheduleTodayRecords(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentScheduleTodaySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentScheduleTodayRecords(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プッシュ通知フラグ取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Push Flg
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPushFlg(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AH003Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPushFlg(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プッシュ通知の履歴一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Push Notification Histories
         * @param {number} parentId 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPushNotificationHistories(parentId: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsPushNotificationHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPushNotificationHistories(parentId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 要対応リスト取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Required Actions
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequiredActions(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AB001RequiredActionSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequiredActions(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exceptionをログに出力  ## PERMISSION 親のみ実行可能
         * @summary Log Exception
         * @param {string} title 
         * @param {string} exception 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logException(title: string, exception: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logException(title, exception, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング予約確定  特定の子供の親であれば実行可能
         * @summary Put Ag002
         * @param {number} childId 
         * @param {Array<AG002PutSchema>} aG002PutSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAG002(childId: number, aG002PutSchema: Array<AG002PutSchema>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAG002(childId, aG002PutSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デバイストークンを登録（プッシュ通知に使用）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Put Mobile Device Token
         * @param {number} parentId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMobileDeviceToken(parentId: number, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMobileDeviceToken(parentId, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 未読メッセージを既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Read Chat Message
         * @param {number} parentId 
         * @param {number} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readChatMessage(parentId: number, staffId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readChatMessage(parentId, staffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新着情報を既読にする  ## PERMISSION 親のみ実行可能
         * @summary Read Notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNotification(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メッセージを登録  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Register Chat Message
         * @param {AppsRegisterChatMessageSchema} appsRegisterChatMessageSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerChatMessage(appsRegisterChatMessageSchema: AppsRegisterChatMessageSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerChatMessage(appsRegisterChatMessageSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択したスケジュールを予約  特定の子供の親であれば実行可能
         * @summary Reserve Ag003
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveAG003(childId: number, scheduleReservationFrameId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveAG003(childId, scheduleReservationFrameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 選択したスケジュールを予約  特定の子供の親であれば実行可能
         * @summary Reserve Ag005
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveAG005(childId: number, scheduleReservationFrameId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveAG005(childId, scheduleReservationFrameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼の署名を登録  特定の子供の親であれば実行可能
         * @summary Save Ac012 Sign
         * @param {number} childId 
         * @param {number} signatureRequestId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAC012Sign(childId: number, signatureRequestId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAC012Sign(childId, signatureRequestId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様の画像を変更  特定の子供の親であれば実行可能
         * @summary Save Child Image
         * @param {number} childId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveChildImage(childId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveChildImage(childId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画の署名を保存  特定の子供の親であれば実行可能
         * @summary Save Individual Support Sign
         * @param {number} childId 
         * @param {number} individualSupportId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveIndividualSupportSign(childId: number, individualSupportId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveIndividualSupportSign(childId, individualSupportId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の画像を変更  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Save Parent Image
         * @param {number} parentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveParentImage(parentId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveParentImage(parentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プッシュ通知フラグ更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Save Push Flg
         * @param {number} parentId 
         * @param {AH003Schema} aH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePushFlg(parentId: number, aH003Schema: AH003Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePushFlg(parentId, aH003Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニングの署名を保存  特定の子供の親であれば実行可能
         * @summary Save Training Result Sign
         * @param {number} childId 
         * @param {number} trainingId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrainingResultSign(childId: number, trainingId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrainingResultSign(childId, trainingId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様の情報を更新  特定の子供の親であれば実行可能
         * @summary Update Child
         * @param {number} childId 
         * @param {AppsChildUpdateSchema} appsChildUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChild(childId: number, appsChildUpdateSchema: AppsChildUpdateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChild(childId, appsChildUpdateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の情報を更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Parent
         * @param {number} parentId 
         * @param {AppsParentDetailSchema} appsParentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParent(parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParent(parentId, appsParentDetailSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Push Notification Read Flg
         * @param {number} parentId 
         * @param {number} pushNotificationHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePushNotificationReadFlg(parentId: number, pushNotificationHistoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePushNotificationReadFlg(parentId, pushNotificationHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Push Notification Read Flg All
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePushNotificationReadFlgAll(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePushNotificationReadFlgAll(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の仮登録を本登録に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Temp Parent
         * @param {number} parentId 
         * @param {AppsParentDetailSchema} appsParentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTempParent(parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTempParent(parentId, appsParentDetailSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppsApiFp(configuration)
    return {
        /**
         * 予約の取り消し  ## PERMISSION 親のみ実行可能
         * @summary Cancel Ag003
         * @param {number} scheduleDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAG003(scheduleDetailId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.cancelAG003(scheduleDetailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約の取り消し  ## PERMISSION 親のみ実行可能
         * @summary Cancel Ag005
         * @param {number} scheduleDetailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAG005(scheduleDetailId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.cancelAG005(scheduleDetailId, options).then((request) => request(axios, basePath));
        },
        /**
         * メッセージを削除  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Delete Chat Message
         * @param {number} parentId 
         * @param {number} staffId 
         * @param {number} chatMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChatMessage(parentId: number, staffId: number, chatMessageId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteChatMessage(parentId, staffId, chatMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * デバイストークンを削除  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Delete Mobile Device Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMobileDeviceToken(token: string, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteMobileDeviceToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様のヘルスデータ(身長、体重）チャート情報取得  特定の子供の親であれば実行可能
         * @summary Get Ac004
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAC004(childId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getAC004(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様の署名依頼一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Ac011
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAC011(childId: number, options?: any): AxiosPromise<Array<AC011Schema>> {
            return localVarFp.getAC011(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Ac012
         * @param {number} childId 
         * @param {any} signatureRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAC012(childId: number, signatureRequestId: any, options?: any): AxiosPromise<AC012Schema> {
            return localVarFp.getAC012(childId, signatureRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 次月トレーニング予約レコード取得  特定の子供の親であれば実行可能
         * @summary Get Ag002
         * @param {number} childId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAG002(childId: number, date: string, options?: any): AxiosPromise<Array<AG002Schema>> {
            return localVarFp.getAG002(childId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * バッジ数を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Badge Count
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadgeCount(parentId: number, options?: any): AxiosPromise<number> {
            return localVarFp.getBadgeCount(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * カレンダーのマーカーを取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Calendar Marked
         * @param {number} parentId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarMarked(parentId: number, date: string, options?: any): AxiosPromise<AppsCalendarSchema> {
            return localVarFp.getCalendarMarked(parentId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のチャット相手とのメッセージ一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Chat Messages
         * @param {number} staffId 
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatMessages(staffId: number, parentId: number, options?: any): AxiosPromise<AppsAD002ChatMessagesSchema> {
            return localVarFp.getChatMessages(staffId, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * チャット相手一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Chat Users
         * @param {number} parentId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatUsers(parentId: number, search?: string, options?: any): AxiosPromise<Array<AppsAD001ChatUsersSchema>> {
            return localVarFp.getChatUsers(parentId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択したイベントの詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Event Detail Record
         * @param {number} childId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildEventDetailRecord(childId: number, eventId: number, options?: any): AxiosPromise<ChildEventDetailSchema> {
            return localVarFp.getChildEventDetailRecord(childId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のお子様のグラフ用ヘルスデータを取得  特定の子供の親であれば実行可能
         * @summary Get Child Health Graph
         * @param {number} childId 
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildHealthGraph(childId: number, type: number, options?: any): AxiosPromise<ChildHealthGraphSchema> {
            return localVarFp.getChildHealthGraph(childId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のお子様のヘルスデータ(身長、体重）一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Health Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildHealthRecords(childId: number, options?: any): AxiosPromise<ChildHealthSchema> {
            return localVarFp.getChildHealthRecords(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のお子様の個別支援計画の詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Individual Support Detail
         * @param {number} childId 
         * @param {number} individualSupportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildIndividualSupportDetail(childId: number, individualSupportId: number, options?: any): AxiosPromise<ChildIndividualSupportDetailSchema> {
            return localVarFp.getChildIndividualSupportDetail(childId, individualSupportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のお子様の個別支援計画の一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Individual Support Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildIndividualSupportRecords(childId: number, options?: any): AxiosPromise<ChildIndividualSupportSchema> {
            return localVarFp.getChildIndividualSupportRecords(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のお子様の詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Record
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildRecord(childId: number, options?: any): AxiosPromise<AppsChildDetailSchema> {
            return localVarFp.getChildRecord(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択したスケジュールの詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Schedule Day Record
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildScheduleDayRecord(childId: number, scheduleReservationFrameId: number, options?: any): AxiosPromise<ChildScheduleDetailSchema> {
            return localVarFp.getChildScheduleDayRecord(childId, scheduleReservationFrameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のトレーニング詳細を取得  特定の子供の親であれば実行可能
         * @summary Get Child Training Detail
         * @param {number} childId 
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildTrainingDetail(childId: number, trainingId: number, options?: any): AxiosPromise<ChildTrainingDetailSchema> {
            return localVarFp.getChildTrainingDetail(childId, trainingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択したお子様のトレーニング一覧を取得  特定の子供の親であれば実行可能
         * @summary Get Child Training Records
         * @param {number} childId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildTrainingRecords(childId: number, skip?: number, limit?: number, options?: any): AxiosPromise<ChildTrainingSchema> {
            return localVarFp.getChildTrainingRecords(childId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Children
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren(parentId: number, options?: any): AxiosPromise<Array<AppsAC001ChildSchema>> {
            return localVarFp.getChildren(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意のお子様の障がい一覧を取得（現在は使用していない）  特定の子供の親であれば実行可能
         * @summary Get Disability Records
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisabilityRecords(childId: number, options?: any): AxiosPromise<Array<DisabilityBaseSchema>> {
            return localVarFp.getDisabilityRecords(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 親のみ実行可能
         * @summary Get Generals
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenerals(keyword: string, options?: any): AxiosPromise<Array<GeneralBaseSchema>> {
            return localVarFp.getGenerals(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 新着情報一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Notifications
         * @param {number} parentId 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(parentId: number, search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<AppsAF001NotificationsSchema>> {
            return localVarFp.getNotifications(parentId, search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択した月、教室に応じた日にちごとのイベント予約状況を取得（カレンダーのマーカーとして使用）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Month Records
         * @param {number} parentId 
         * @param {string} date 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentEventMonthRecords(parentId: number, date: string, facilityId: number, options?: any): AxiosPromise<ParentEventMonthRecords> {
            return localVarFp.getParentEventMonthRecords(parentId, date, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 次回のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Next Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentEventNextRecords(parentId: number, options?: any): AxiosPromise<ParentEventNextSchema> {
            return localVarFp.getParentEventNextRecords(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 本日のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Event Today Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentEventTodayRecords(parentId: number, options?: any): AxiosPromise<ParentEventTodaySchema> {
            return localVarFp.getParentEventTodayRecords(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 教室一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Facility Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentFacilityRecords(parentId: number, options?: any): AxiosPromise<Array<FCFacilityBaseSchema>> {
            return localVarFp.getParentFacilityRecords(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の画像を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Image
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentImage(parentId: number, options?: any): AxiosPromise<AppsParentSuperficialSchema> {
            return localVarFp.getParentImage(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 任意の新着情報の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Notification Detail
         * @param {number} parentId 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentNotificationDetail(parentId: number, notificationId: number, options?: any): AxiosPromise<ParentNotificationDetailSchema> {
            return localVarFp.getParentNotificationDetail(parentId, notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Record
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentRecord(parentId: number, options?: any): AxiosPromise<AppsParentSchema> {
            return localVarFp.getParentRecord(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択した月、教室に応じた日にちごとのスケジュール情報を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Month Records
         * @param {number} parentId 
         * @param {string} date 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentScheduleMonthRecords(parentId: number, date: string, facilityId: number, options?: any): AxiosPromise<ParentScheduleMonthRecords> {
            return localVarFp.getParentScheduleMonthRecords(parentId, date, facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 次回のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Next Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentScheduleNextRecords(parentId: number, options?: any): AxiosPromise<ParentScheduleNextRecordSchema> {
            return localVarFp.getParentScheduleNextRecords(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 本日のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Parent Schedule Today Records
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentScheduleTodayRecords(parentId: number, options?: any): AxiosPromise<ParentScheduleTodaySchema> {
            return localVarFp.getParentScheduleTodayRecords(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * プッシュ通知フラグ取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Push Flg
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPushFlg(parentId: number, options?: any): AxiosPromise<AH003Schema> {
            return localVarFp.getPushFlg(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * プッシュ通知の履歴一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Push Notification Histories
         * @param {number} parentId 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPushNotificationHistories(parentId: number, limit?: number, options?: any): AxiosPromise<Array<AppsPushNotificationHistory>> {
            return localVarFp.getPushNotificationHistories(parentId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 要対応リスト取得  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Get Required Actions
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequiredActions(parentId: number, options?: any): AxiosPromise<Array<AB001RequiredActionSchema>> {
            return localVarFp.getRequiredActions(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Exceptionをログに出力  ## PERMISSION 親のみ実行可能
         * @summary Log Exception
         * @param {string} title 
         * @param {string} exception 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logException(title: string, exception: string, options?: any): AxiosPromise<any> {
            return localVarFp.logException(title, exception, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング予約確定  特定の子供の親であれば実行可能
         * @summary Put Ag002
         * @param {number} childId 
         * @param {Array<AG002PutSchema>} aG002PutSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAG002(childId: number, aG002PutSchema: Array<AG002PutSchema>, options?: any): AxiosPromise<Result> {
            return localVarFp.putAG002(childId, aG002PutSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * デバイストークンを登録（プッシュ通知に使用）  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Put Mobile Device Token
         * @param {number} parentId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMobileDeviceToken(parentId: number, token: string, options?: any): AxiosPromise<Result> {
            return localVarFp.putMobileDeviceToken(parentId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 未読メッセージを既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Read Chat Message
         * @param {number} parentId 
         * @param {number} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readChatMessage(parentId: number, staffId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.readChatMessage(parentId, staffId, options).then((request) => request(axios, basePath));
        },
        /**
         * 新着情報を既読にする  ## PERMISSION 親のみ実行可能
         * @summary Read Notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(notificationId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.readNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * メッセージを登録  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Register Chat Message
         * @param {AppsRegisterChatMessageSchema} appsRegisterChatMessageSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerChatMessage(appsRegisterChatMessageSchema: AppsRegisterChatMessageSchema, options?: any): AxiosPromise<Result> {
            return localVarFp.registerChatMessage(appsRegisterChatMessageSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択したスケジュールを予約  特定の子供の親であれば実行可能
         * @summary Reserve Ag003
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveAG003(childId: number, scheduleReservationFrameId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.reserveAG003(childId, scheduleReservationFrameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択したスケジュールを予約  特定の子供の親であれば実行可能
         * @summary Reserve Ag005
         * @param {number} childId 
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveAG005(childId: number, scheduleReservationFrameId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.reserveAG005(childId, scheduleReservationFrameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼の署名を登録  特定の子供の親であれば実行可能
         * @summary Save Ac012 Sign
         * @param {number} childId 
         * @param {number} signatureRequestId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAC012Sign(childId: number, signatureRequestId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.saveAC012Sign(childId, signatureRequestId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様の画像を変更  特定の子供の親であれば実行可能
         * @summary Save Child Image
         * @param {number} childId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveChildImage(childId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.saveChildImage(childId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画の署名を保存  特定の子供の親であれば実行可能
         * @summary Save Individual Support Sign
         * @param {number} childId 
         * @param {number} individualSupportId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIndividualSupportSign(childId: number, individualSupportId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.saveIndividualSupportSign(childId, individualSupportId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の画像を変更  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Save Parent Image
         * @param {number} parentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveParentImage(parentId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.saveParentImage(parentId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * プッシュ通知フラグ更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Save Push Flg
         * @param {number} parentId 
         * @param {AH003Schema} aH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePushFlg(parentId: number, aH003Schema: AH003Schema, options?: any): AxiosPromise<Result> {
            return localVarFp.savePushFlg(parentId, aH003Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニングの署名を保存  特定の子供の親であれば実行可能
         * @summary Save Training Result Sign
         * @param {number} childId 
         * @param {number} trainingId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrainingResultSign(childId: number, trainingId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.saveTrainingResultSign(childId, trainingId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様の情報を更新  特定の子供の親であれば実行可能
         * @summary Update Child
         * @param {number} childId 
         * @param {AppsChildUpdateSchema} appsChildUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChild(childId: number, appsChildUpdateSchema: AppsChildUpdateSchema, options?: any): AxiosPromise<Result> {
            return localVarFp.updateChild(childId, appsChildUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の情報を更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Parent
         * @param {number} parentId 
         * @param {AppsParentDetailSchema} appsParentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParent(parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options?: any): AxiosPromise<Result> {
            return localVarFp.updateParent(parentId, appsParentDetailSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Push Notification Read Flg
         * @param {number} parentId 
         * @param {number} pushNotificationHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePushNotificationReadFlg(parentId: number, pushNotificationHistoryId: number, options?: any): AxiosPromise<number> {
            return localVarFp.updatePushNotificationReadFlg(parentId, pushNotificationHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Push Notification Read Flg All
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePushNotificationReadFlgAll(parentId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.updatePushNotificationReadFlgAll(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の仮登録を本登録に更新  ## PERMISSION 親かつ自分自身であれば実行可能
         * @summary Update Temp Parent
         * @param {number} parentId 
         * @param {AppsParentDetailSchema} appsParentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTempParent(parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options?: any): AxiosPromise<Result> {
            return localVarFp.updateTempParent(parentId, appsParentDetailSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     * 予約の取り消し  ## PERMISSION 親のみ実行可能
     * @summary Cancel Ag003
     * @param {number} scheduleDetailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public cancelAG003(scheduleDetailId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).cancelAG003(scheduleDetailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約の取り消し  ## PERMISSION 親のみ実行可能
     * @summary Cancel Ag005
     * @param {number} scheduleDetailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public cancelAG005(scheduleDetailId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).cancelAG005(scheduleDetailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メッセージを削除  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Delete Chat Message
     * @param {number} parentId 
     * @param {number} staffId 
     * @param {number} chatMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public deleteChatMessage(parentId: number, staffId: number, chatMessageId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).deleteChatMessage(parentId, staffId, chatMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * デバイストークンを削除  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Delete Mobile Device Token
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public deleteMobileDeviceToken(token: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).deleteMobileDeviceToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様のヘルスデータ(身長、体重）チャート情報取得  特定の子供の親であれば実行可能
     * @summary Get Ac004
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAC004(childId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAC004(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様の署名依頼一覧を取得  特定の子供の親であれば実行可能
     * @summary Get Ac011
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAC011(childId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAC011(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼詳細を取得  特定の子供の親であれば実行可能
     * @summary Get Ac012
     * @param {number} childId 
     * @param {any} signatureRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAC012(childId: number, signatureRequestId: any, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAC012(childId, signatureRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 次月トレーニング予約レコード取得  特定の子供の親であれば実行可能
     * @summary Get Ag002
     * @param {number} childId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAG002(childId: number, date: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAG002(childId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * バッジ数を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Badge Count
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getBadgeCount(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getBadgeCount(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カレンダーのマーカーを取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Calendar Marked
     * @param {number} parentId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getCalendarMarked(parentId: number, date: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getCalendarMarked(parentId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のチャット相手とのメッセージ一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Chat Messages
     * @param {number} staffId 
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChatMessages(staffId: number, parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChatMessages(staffId, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャット相手一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Chat Users
     * @param {number} parentId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChatUsers(parentId: number, search?: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChatUsers(parentId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択したイベントの詳細を取得  特定の子供の親であれば実行可能
     * @summary Get Child Event Detail Record
     * @param {number} childId 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildEventDetailRecord(childId: number, eventId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildEventDetailRecord(childId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のお子様のグラフ用ヘルスデータを取得  特定の子供の親であれば実行可能
     * @summary Get Child Health Graph
     * @param {number} childId 
     * @param {number} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildHealthGraph(childId: number, type: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildHealthGraph(childId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のお子様のヘルスデータ(身長、体重）一覧を取得  特定の子供の親であれば実行可能
     * @summary Get Child Health Records
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildHealthRecords(childId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildHealthRecords(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のお子様の個別支援計画の詳細を取得  特定の子供の親であれば実行可能
     * @summary Get Child Individual Support Detail
     * @param {number} childId 
     * @param {number} individualSupportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildIndividualSupportDetail(childId: number, individualSupportId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildIndividualSupportDetail(childId, individualSupportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のお子様の個別支援計画の一覧を取得  特定の子供の親であれば実行可能
     * @summary Get Child Individual Support Records
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildIndividualSupportRecords(childId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildIndividualSupportRecords(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のお子様の詳細を取得  特定の子供の親であれば実行可能
     * @summary Get Child Record
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildRecord(childId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildRecord(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択したスケジュールの詳細を取得  特定の子供の親であれば実行可能
     * @summary Get Child Schedule Day Record
     * @param {number} childId 
     * @param {number} scheduleReservationFrameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildScheduleDayRecord(childId: number, scheduleReservationFrameId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildScheduleDayRecord(childId, scheduleReservationFrameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のトレーニング詳細を取得  特定の子供の親であれば実行可能
     * @summary Get Child Training Detail
     * @param {number} childId 
     * @param {number} trainingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildTrainingDetail(childId: number, trainingId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildTrainingDetail(childId, trainingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択したお子様のトレーニング一覧を取得  特定の子供の親であれば実行可能
     * @summary Get Child Training Records
     * @param {number} childId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildTrainingRecords(childId: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildTrainingRecords(childId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Children
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getChildren(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getChildren(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意のお子様の障がい一覧を取得（現在は使用していない）  特定の子供の親であれば実行可能
     * @summary Get Disability Records
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getDisabilityRecords(childId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getDisabilityRecords(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 汎用レコードリストを取得  ## PERMISSION 親のみ実行可能
     * @summary Get Generals
     * @param {string} keyword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getGenerals(keyword: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getGenerals(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新着情報一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Notifications
     * @param {number} parentId 
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getNotifications(parentId: number, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getNotifications(parentId, search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択した月、教室に応じた日にちごとのイベント予約状況を取得（カレンダーのマーカーとして使用）  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Event Month Records
     * @param {number} parentId 
     * @param {string} date 
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentEventMonthRecords(parentId: number, date: string, facilityId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentEventMonthRecords(parentId, date, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 次回のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Event Next Records
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentEventNextRecords(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentEventNextRecords(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 本日のイベント一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Event Today Records
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentEventTodayRecords(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentEventTodayRecords(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教室一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Facility Records
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentFacilityRecords(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentFacilityRecords(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の画像を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Image
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentImage(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentImage(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 任意の新着情報の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Notification Detail
     * @param {number} parentId 
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentNotificationDetail(parentId: number, notificationId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentNotificationDetail(parentId, notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の詳細を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Record
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentRecord(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentRecord(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択した月、教室に応じた日にちごとのスケジュール情報を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Schedule Month Records
     * @param {number} parentId 
     * @param {string} date 
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentScheduleMonthRecords(parentId: number, date: string, facilityId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentScheduleMonthRecords(parentId, date, facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 次回のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Schedule Next Records
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentScheduleNextRecords(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentScheduleNextRecords(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 本日のスケジュール一覧を取得（ホーム画面）  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Parent Schedule Today Records
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getParentScheduleTodayRecords(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getParentScheduleTodayRecords(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プッシュ通知フラグ取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Push Flg
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getPushFlg(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getPushFlg(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プッシュ通知の履歴一覧を取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Push Notification Histories
     * @param {number} parentId 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getPushNotificationHistories(parentId: number, limit?: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getPushNotificationHistories(parentId, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 要対応リスト取得  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Get Required Actions
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getRequiredActions(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).getRequiredActions(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exceptionをログに出力  ## PERMISSION 親のみ実行可能
     * @summary Log Exception
     * @param {string} title 
     * @param {string} exception 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public logException(title: string, exception: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).logException(title, exception, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング予約確定  特定の子供の親であれば実行可能
     * @summary Put Ag002
     * @param {number} childId 
     * @param {Array<AG002PutSchema>} aG002PutSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public putAG002(childId: number, aG002PutSchema: Array<AG002PutSchema>, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).putAG002(childId, aG002PutSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * デバイストークンを登録（プッシュ通知に使用）  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Put Mobile Device Token
     * @param {number} parentId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public putMobileDeviceToken(parentId: number, token: string, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).putMobileDeviceToken(parentId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 未読メッセージを既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Read Chat Message
     * @param {number} parentId 
     * @param {number} staffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public readChatMessage(parentId: number, staffId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).readChatMessage(parentId, staffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新着情報を既読にする  ## PERMISSION 親のみ実行可能
     * @summary Read Notification
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public readNotification(notificationId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).readNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メッセージを登録  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Register Chat Message
     * @param {AppsRegisterChatMessageSchema} appsRegisterChatMessageSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public registerChatMessage(appsRegisterChatMessageSchema: AppsRegisterChatMessageSchema, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).registerChatMessage(appsRegisterChatMessageSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択したスケジュールを予約  特定の子供の親であれば実行可能
     * @summary Reserve Ag003
     * @param {number} childId 
     * @param {number} scheduleReservationFrameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public reserveAG003(childId: number, scheduleReservationFrameId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).reserveAG003(childId, scheduleReservationFrameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択したスケジュールを予約  特定の子供の親であれば実行可能
     * @summary Reserve Ag005
     * @param {number} childId 
     * @param {number} scheduleReservationFrameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public reserveAG005(childId: number, scheduleReservationFrameId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).reserveAG005(childId, scheduleReservationFrameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼の署名を登録  特定の子供の親であれば実行可能
     * @summary Save Ac012 Sign
     * @param {number} childId 
     * @param {number} signatureRequestId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public saveAC012Sign(childId: number, signatureRequestId: number, file: any, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).saveAC012Sign(childId, signatureRequestId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様の画像を変更  特定の子供の親であれば実行可能
     * @summary Save Child Image
     * @param {number} childId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public saveChildImage(childId: number, file: any, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).saveChildImage(childId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画の署名を保存  特定の子供の親であれば実行可能
     * @summary Save Individual Support Sign
     * @param {number} childId 
     * @param {number} individualSupportId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public saveIndividualSupportSign(childId: number, individualSupportId: number, file: any, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).saveIndividualSupportSign(childId, individualSupportId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の画像を変更  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Save Parent Image
     * @param {number} parentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public saveParentImage(parentId: number, file: any, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).saveParentImage(parentId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プッシュ通知フラグ更新  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Save Push Flg
     * @param {number} parentId 
     * @param {AH003Schema} aH003Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public savePushFlg(parentId: number, aH003Schema: AH003Schema, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).savePushFlg(parentId, aH003Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニングの署名を保存  特定の子供の親であれば実行可能
     * @summary Save Training Result Sign
     * @param {number} childId 
     * @param {number} trainingId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public saveTrainingResultSign(childId: number, trainingId: number, file: any, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).saveTrainingResultSign(childId, trainingId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様の情報を更新  特定の子供の親であれば実行可能
     * @summary Update Child
     * @param {number} childId 
     * @param {AppsChildUpdateSchema} appsChildUpdateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updateChild(childId: number, appsChildUpdateSchema: AppsChildUpdateSchema, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).updateChild(childId, appsChildUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の情報を更新  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Update Parent
     * @param {number} parentId 
     * @param {AppsParentDetailSchema} appsParentDetailSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updateParent(parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).updateParent(parentId, appsParentDetailSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Update Push Notification Read Flg
     * @param {number} parentId 
     * @param {number} pushNotificationHistoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updatePushNotificationReadFlg(parentId: number, pushNotificationHistoryId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).updatePushNotificationReadFlg(parentId, pushNotificationHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プッシュ通知の未読を既読に更新  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Update Push Notification Read Flg All
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updatePushNotificationReadFlgAll(parentId: number, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).updatePushNotificationReadFlgAll(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の仮登録を本登録に更新  ## PERMISSION 親かつ自分自身であれば実行可能
     * @summary Update Temp Parent
     * @param {number} parentId 
     * @param {AppsParentDetailSchema} appsParentDetailSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updateTempParent(parentId: number, appsParentDetailSchema: AppsParentDetailSchema, options?: AxiosRequestConfig) {
        return AppsApiFp(this.configuration).updateTempParent(parentId, appsParentDetailSchema, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditDocsApi - axios parameter creator
 * @export
 */
export const AuditDocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 監査レコードの削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Audit Doc
         * @param {number} auditDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditDoc: async (auditDocId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditDocId' is not null or undefined
            assertParamExists('deleteAuditDoc', 'auditDocId', auditDocId)
            const localVarPath = `/api/v1/web/audit_docs/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (auditDocId !== undefined) {
                localVarQueryParameter['audit_doc_id'] = auditDocId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * M001の一括ダウンロード実行   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Download M001 Docs
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadM001Docs: async (dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('downloadM001Docs', 'dateType', dateType)
            const localVarPath = `/api/v1/web/audit_docs/download_M001_docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (docType !== undefined) {
                localVarQueryParameter['doc_type'] = docType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * M001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {number} [status] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM001: async (dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('getM001', 'dateType', dateType)
            const localVarPath = `/api/v1/web/audit_docs/get_M001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (docType !== undefined) {
                localVarQueryParameter['doc_type'] = docType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * M001用レコード件数の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001 Count
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM001Count: async (dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('getM001Count', 'dateType', dateType)
            const localVarPath = `/api/v1/web/audit_docs/get_M001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (docType !== undefined) {
                localVarQueryParameter['doc_type'] = docType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * M001の一括ダウンロード帳票数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001 Docs Count
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM001DocsCount: async (dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateType' is not null or undefined
            assertParamExists('getM001DocsCount', 'dateType', dateType)
            const localVarPath = `/api/v1/web/audit_docs/get_M001_docs_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (dateType !== undefined) {
                localVarQueryParameter['date_type'] = dateType;
            }

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (docType !== undefined) {
                localVarQueryParameter['doc_type'] = docType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * M002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get M002
         * @param {number} auditDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM002: async (auditDocId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditDocId' is not null or undefined
            assertParamExists('getM002', 'auditDocId', auditDocId)
            const localVarPath = `/api/v1/web/audit_docs/get_M002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (auditDocId !== undefined) {
                localVarQueryParameter['audit_doc_id'] = auditDocId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 監査ファイルの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Audit Doc File
         * @param {number} auditDocId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAuditDocFile: async (auditDocId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditDocId' is not null or undefined
            assertParamExists('putAuditDocFile', 'auditDocId', auditDocId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putAuditDocFile', 'file', file)
            const localVarPath = `/api/v1/web/audit_docs/put_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (auditDocId !== undefined) {
                localVarQueryParameter['audit_doc_id'] = auditDocId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 監査レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put M002
         * @param {AuditDocM002Schema} auditDocM002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putM002: async (auditDocM002Schema: AuditDocM002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditDocM002Schema' is not null or undefined
            assertParamExists('putM002', 'auditDocM002Schema', auditDocM002Schema)
            const localVarPath = `/api/v1/web/audit_docs/put_M002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditDocM002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditDocsApi - functional programming interface
 * @export
 */
export const AuditDocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditDocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 監査レコードの削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Audit Doc
         * @param {number} auditDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAuditDoc(auditDocId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAuditDoc(auditDocId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * M001の一括ダウンロード実行   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Download M001 Docs
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadM001Docs(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadM001Docs(dateType, fcFacilityId, childId, beginDate, endDate, docType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * M001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {number} [status] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getM001(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditDocM001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getM001(dateType, fcFacilityId, childId, beginDate, endDate, docType, status, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * M001用レコード件数の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001 Count
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getM001Count(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getM001Count(dateType, fcFacilityId, childId, beginDate, endDate, docType, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * M001の一括ダウンロード帳票数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001 Docs Count
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getM001DocsCount(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getM001DocsCount(dateType, fcFacilityId, childId, beginDate, endDate, docType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * M002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get M002
         * @param {number} auditDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getM002(auditDocId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditDocM002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getM002(auditDocId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 監査ファイルの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Audit Doc File
         * @param {number} auditDocId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAuditDocFile(auditDocId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAuditDocFile(auditDocId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 監査レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put M002
         * @param {AuditDocM002Schema} auditDocM002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putM002(auditDocM002Schema: AuditDocM002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putM002(auditDocM002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditDocsApi - factory interface
 * @export
 */
export const AuditDocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditDocsApiFp(configuration)
    return {
        /**
         * 監査レコードの削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Audit Doc
         * @param {number} auditDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditDoc(auditDocId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteAuditDoc(auditDocId, options).then((request) => request(axios, basePath));
        },
        /**
         * M001の一括ダウンロード実行   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Download M001 Docs
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadM001Docs(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options?: any): AxiosPromise<void> {
            return localVarFp.downloadM001Docs(dateType, fcFacilityId, childId, beginDate, endDate, docType, options).then((request) => request(axios, basePath));
        },
        /**
         * M001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {number} [status] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM001(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<AuditDocM001Schema>> {
            return localVarFp.getM001(dateType, fcFacilityId, childId, beginDate, endDate, docType, status, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * M001用レコード件数の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001 Count
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM001Count(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, options?: any): AxiosPromise<number> {
            return localVarFp.getM001Count(dateType, fcFacilityId, childId, beginDate, endDate, docType, status, options).then((request) => request(axios, basePath));
        },
        /**
         * M001の一括ダウンロード帳票数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get M001 Docs Count
         * @param {number} dateType 
         * @param {number} [fcFacilityId] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [docType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM001DocsCount(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options?: any): AxiosPromise<number> {
            return localVarFp.getM001DocsCount(dateType, fcFacilityId, childId, beginDate, endDate, docType, options).then((request) => request(axios, basePath));
        },
        /**
         * M002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get M002
         * @param {number} auditDocId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getM002(auditDocId: number, options?: any): AxiosPromise<AuditDocM002Schema> {
            return localVarFp.getM002(auditDocId, options).then((request) => request(axios, basePath));
        },
        /**
         * 監査ファイルの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Audit Doc File
         * @param {number} auditDocId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAuditDocFile(auditDocId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putAuditDocFile(auditDocId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 監査レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put M002
         * @param {AuditDocM002Schema} auditDocM002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putM002(auditDocM002Schema: AuditDocM002Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putM002(auditDocM002Schema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditDocsApi - object-oriented interface
 * @export
 * @class AuditDocsApi
 * @extends {BaseAPI}
 */
export class AuditDocsApi extends BaseAPI {
    /**
     * 監査レコードの削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Audit Doc
     * @param {number} auditDocId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public deleteAuditDoc(auditDocId: number, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).deleteAuditDoc(auditDocId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * M001の一括ダウンロード実行   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Download M001 Docs
     * @param {number} dateType 
     * @param {number} [fcFacilityId] 
     * @param {number} [childId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [docType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public downloadM001Docs(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).downloadM001Docs(dateType, fcFacilityId, childId, beginDate, endDate, docType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * M001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get M001
     * @param {number} dateType 
     * @param {number} [fcFacilityId] 
     * @param {number} [childId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [docType] 
     * @param {number} [status] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public getM001(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).getM001(dateType, fcFacilityId, childId, beginDate, endDate, docType, status, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * M001用レコード件数の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get M001 Count
     * @param {number} dateType 
     * @param {number} [fcFacilityId] 
     * @param {number} [childId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [docType] 
     * @param {number} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public getM001Count(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, status?: number, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).getM001Count(dateType, fcFacilityId, childId, beginDate, endDate, docType, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * M001の一括ダウンロード帳票数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get M001 Docs Count
     * @param {number} dateType 
     * @param {number} [fcFacilityId] 
     * @param {number} [childId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [docType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public getM001DocsCount(dateType: number, fcFacilityId?: number, childId?: number, beginDate?: string, endDate?: string, docType?: number, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).getM001DocsCount(dateType, fcFacilityId, childId, beginDate, endDate, docType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * M002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get M002
     * @param {number} auditDocId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public getM002(auditDocId: number, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).getM002(auditDocId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 監査ファイルの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Audit Doc File
     * @param {number} auditDocId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public putAuditDocFile(auditDocId: number, file: any, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).putAuditDocFile(auditDocId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 監査レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put M002
     * @param {AuditDocM002Schema} auditDocM002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditDocsApi
     */
    public putM002(auditDocM002Schema: AuditDocM002Schema, options?: AxiosRequestConfig) {
        return AuditDocsApiFp(this.configuration).putM002(auditDocM002Schema, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アセスメントシートの自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Make Assessment Sheet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeAssessmentSheet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batch/children/make_assessment_sheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画の自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Make Individual Support Plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeIndividualSupportPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/batch/children/make_individual_support_plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchApiAxiosParamCreator(configuration)
    return {
        /**
         * アセスメントシートの自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Make Assessment Sheet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeAssessmentSheet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeAssessmentSheet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画の自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Make Individual Support Plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeIndividualSupportPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeIndividualSupportPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchApiFp(configuration)
    return {
        /**
         * アセスメントシートの自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Make Assessment Sheet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeAssessmentSheet(options?: any): AxiosPromise<Result> {
            return localVarFp.makeAssessmentSheet(options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画の自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Make Individual Support Plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeIndividualSupportPlan(options?: any): AxiosPromise<Result> {
            return localVarFp.makeIndividualSupportPlan(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI {
    /**
     * アセスメントシートの自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Make Assessment Sheet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public makeAssessmentSheet(options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).makeAssessmentSheet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画の自動レコード作成  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Make Individual Support Plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public makeIndividualSupportPlan(options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).makeIndividualSupportPlan(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatMessagesApi - axios parameter creator
 * @export
 */
export const ChatMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * チャット対象本部ユーザ、保護者取得  user_type以下の2種 - headquarter_user - parent   ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Get G001 For Fc Staff
         * @param {string} [userType] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getG001ForFcStaff: async (userType?: string, name?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/chat_messages/fc_staff/get_G001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (userType !== undefined) {
                localVarQueryParameter['user_type'] = userType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャット対象スタッフ取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get G001 For Headquarter User
         * @param {number} [facilityId] 
         * @param {number} [rank] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getG001ForHeadquarterUser: async (facilityId?: number, rank?: number, name?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/chat_messages/headquarter_user/get_G001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }

            if (rank !== undefined) {
                localVarQueryParameter['rank'] = rank;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットメッセージ取得  user_type以下の3種 - headquarter_user - staff - parent   ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get G002
         * @param {string} userTypePartner 
         * @param {number} userIdPartner 
         * @param {number} [lastMessageId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getG002: async (userTypePartner: string, userIdPartner: number, lastMessageId?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTypePartner' is not null or undefined
            assertParamExists('getG002', 'userTypePartner', userTypePartner)
            // verify required parameter 'userIdPartner' is not null or undefined
            assertParamExists('getG002', 'userIdPartner', userIdPartner)
            const localVarPath = `/api/v1/web/chat_messages/get_G002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (userTypePartner !== undefined) {
                localVarQueryParameter['user_type_partner'] = userTypePartner;
            }

            if (userIdPartner !== undefined) {
                localVarQueryParameter['user_id_partner'] = userIdPartner;
            }

            if (lastMessageId !== undefined) {
                localVarQueryParameter['last_message_id'] = lastMessageId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットメッセージ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Chat Message
         * @param {G002ChatMessageCreateSchema} g002ChatMessageCreateSchema 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChatMessage: async (g002ChatMessageCreateSchema: G002ChatMessageCreateSchema, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'g002ChatMessageCreateSchema' is not null or undefined
            assertParamExists('putChatMessage', 'g002ChatMessageCreateSchema', g002ChatMessageCreateSchema)
            const localVarPath = `/api/v1/web/chat_messages/put_chat_message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(g002ChatMessageCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatMessagesApi - functional programming interface
 * @export
 */
export const ChatMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * チャット対象本部ユーザ、保護者取得  user_type以下の2種 - headquarter_user - parent   ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Get G001 For Fc Staff
         * @param {string} [userType] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getG001ForFcStaff(userType?: string, name?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<G001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getG001ForFcStaff(userType, name, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャット対象スタッフ取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get G001 For Headquarter User
         * @param {number} [facilityId] 
         * @param {number} [rank] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getG001ForHeadquarterUser(facilityId?: number, rank?: number, name?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<G001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getG001ForHeadquarterUser(facilityId, rank, name, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットメッセージ取得  user_type以下の3種 - headquarter_user - staff - parent   ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get G002
         * @param {string} userTypePartner 
         * @param {number} userIdPartner 
         * @param {number} [lastMessageId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getG002(userTypePartner: string, userIdPartner: number, lastMessageId?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<G002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getG002(userTypePartner, userIdPartner, lastMessageId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットメッセージ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Chat Message
         * @param {G002ChatMessageCreateSchema} g002ChatMessageCreateSchema 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChatMessage(g002ChatMessageCreateSchema: G002ChatMessageCreateSchema, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putChatMessage(g002ChatMessageCreateSchema, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatMessagesApi - factory interface
 * @export
 */
export const ChatMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatMessagesApiFp(configuration)
    return {
        /**
         * チャット対象本部ユーザ、保護者取得  user_type以下の2種 - headquarter_user - parent   ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Get G001 For Fc Staff
         * @param {string} [userType] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getG001ForFcStaff(userType?: string, name?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<G001Schema>> {
            return localVarFp.getG001ForFcStaff(userType, name, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * チャット対象スタッフ取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get G001 For Headquarter User
         * @param {number} [facilityId] 
         * @param {number} [rank] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getG001ForHeadquarterUser(facilityId?: number, rank?: number, name?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<G001Schema>> {
            return localVarFp.getG001ForHeadquarterUser(facilityId, rank, name, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * チャットメッセージ取得  user_type以下の3種 - headquarter_user - staff - parent   ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get G002
         * @param {string} userTypePartner 
         * @param {number} userIdPartner 
         * @param {number} [lastMessageId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getG002(userTypePartner: string, userIdPartner: number, lastMessageId?: number, skip?: number, limit?: number, options?: any): AxiosPromise<G002Schema> {
            return localVarFp.getG002(userTypePartner, userIdPartner, lastMessageId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * チャットメッセージ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Chat Message
         * @param {G002ChatMessageCreateSchema} g002ChatMessageCreateSchema 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChatMessage(g002ChatMessageCreateSchema: G002ChatMessageCreateSchema, limit?: number, options?: any): AxiosPromise<Result> {
            return localVarFp.putChatMessage(g002ChatMessageCreateSchema, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatMessagesApi - object-oriented interface
 * @export
 * @class ChatMessagesApi
 * @extends {BaseAPI}
 */
export class ChatMessagesApi extends BaseAPI {
    /**
     * チャット対象本部ユーザ、保護者取得  user_type以下の2種 - headquarter_user - parent   ## PERMISSION スタッフかつ自分自身であれば実行可能
     * @summary Get G001 For Fc Staff
     * @param {string} [userType] 
     * @param {string} [name] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public getG001ForFcStaff(userType?: string, name?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).getG001ForFcStaff(userType, name, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャット対象スタッフ取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get G001 For Headquarter User
     * @param {number} [facilityId] 
     * @param {number} [rank] 
     * @param {string} [name] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public getG001ForHeadquarterUser(facilityId?: number, rank?: number, name?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).getG001ForHeadquarterUser(facilityId, rank, name, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャットメッセージ取得  user_type以下の3種 - headquarter_user - staff - parent   ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get G002
     * @param {string} userTypePartner 
     * @param {number} userIdPartner 
     * @param {number} [lastMessageId] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public getG002(userTypePartner: string, userIdPartner: number, lastMessageId?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).getG002(userTypePartner, userIdPartner, lastMessageId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャットメッセージ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Chat Message
     * @param {G002ChatMessageCreateSchema} g002ChatMessageCreateSchema 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatMessagesApi
     */
    public putChatMessage(g002ChatMessageCreateSchema: G002ChatMessageCreateSchema, limit?: number, options?: AxiosRequestConfig) {
        return ChatMessagesApiFp(this.configuration).putChatMessage(g002ChatMessageCreateSchema, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChildrenApi - axios parameter creator
 * @export
 */
export const ChildrenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * お子様の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Child
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChild: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('deleteChild', 'childId', childId)
            const localVarPath = `/api/v1/web/children/delete_child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 身長体重レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete D004
         * @param {number} healthRecordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteD004: async (healthRecordId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthRecordId' is not null or undefined
            assertParamExists('deleteD004', 'healthRecordId', healthRecordId)
            const localVarPath = `/api/v1/web/children/delete_D004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (healthRecordId !== undefined) {
                localVarQueryParameter['health_record_id'] = healthRecordId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセスメントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete D009
         * @param {number} assessmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteD009: async (assessmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentId' is not null or undefined
            assertParamExists('deleteD009', 'assessmentId', assessmentId)
            const localVarPath = `/api/v1/web/children/delete_D009`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (assessmentId !== undefined) {
                localVarQueryParameter['assessment_id'] = assessmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受給者証情報削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Disability Certificate
         * @param {number} disabilityCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDisabilityCertificate: async (disabilityCertificateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disabilityCertificateId' is not null or undefined
            assertParamExists('deleteDisabilityCertificate', 'disabilityCertificateId', disabilityCertificateId)
            const localVarPath = `/api/v1/web/children/delete_disability_certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (disabilityCertificateId !== undefined) {
                localVarQueryParameter['disability_certificate_id'] = disabilityCertificateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete E002
         * @param {number} individualSupportPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteE002: async (individualSupportPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'individualSupportPlanId' is not null or undefined
            assertParamExists('deleteE002', 'individualSupportPlanId', individualSupportPlanId)
            const localVarPath = `/api/v1/web/children/delete_E002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (individualSupportPlanId !== undefined) {
                localVarQueryParameter['individual_support_plan_id'] = individualSupportPlanId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary E002 Signature Notify
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e002SignatureNotify: async (individualSupportPlanId: number, fileType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'individualSupportPlanId' is not null or undefined
            assertParamExists('e002SignatureNotify', 'individualSupportPlanId', individualSupportPlanId)
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('e002SignatureNotify', 'fileType', fileType)
            const localVarPath = `/api/v1/web/children/E002_signature_notify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (individualSupportPlanId !== undefined) {
                localVarQueryParameter['individual_support_plan_id'] = individualSupportPlanId;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Child Info
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildInfo: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getChildInfo', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_child_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For H002
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForH002: async (fcFacilityId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/children/children/for_H002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For List
         * @param {number} fcFacilityId 
         * @param {string} [fullName] 
         * @param {number} [beginAge] 
         * @param {number} [endAge] 
         * @param {boolean} [endFlg] 
         * @param {number} [sortElement] 
         * @param {number} [sortOrder] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForList: async (fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, sortElement?: number, sortOrder?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getChildrenForList', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/children/children/for_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }

            if (beginAge !== undefined) {
                localVarQueryParameter['begin_age'] = beginAge;
            }

            if (endAge !== undefined) {
                localVarQueryParameter['end_age'] = endAge;
            }

            if (endFlg !== undefined) {
                localVarQueryParameter['end_flg'] = endFlg;
            }

            if (sortElement !== undefined) {
                localVarQueryParameter['sort_element'] = sortElement;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort_order'] = sortOrder;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 子供の情報件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For List Count
         * @param {number} fcFacilityId 
         * @param {string} [fullName] 
         * @param {number} [beginAge] 
         * @param {number} [endAge] 
         * @param {boolean} [endFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForListCount: async (fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getChildrenForListCount', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/children/children/for_list_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }

            if (beginAge !== undefined) {
                localVarQueryParameter['begin_age'] = beginAge;
            }

            if (endAge !== undefined) {
                localVarQueryParameter['end_age'] = endAge;
            }

            if (endFlg !== undefined) {
                localVarQueryParameter['end_flg'] = endFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For Select List
         * @param {number} [fcFacilityId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForSelectList: async (fcFacilityId?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/children/children/for_select_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 固定時間登録済のお子様のうち、当該月の受給者証が未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Children Unregister Disability Certificate
         * @param {number} facilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenUnregisterDisabilityCertificate: async (facilityId: number, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getChildrenUnregisterDisabilityCertificate', 'facilityId', facilityId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getChildrenUnregisterDisabilityCertificate', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getChildrenUnregisterDisabilityCertificate', 'month', month)
            const localVarPath = `/api/v1/web/children/get_children_unregister_disability_certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 固定時間未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Children Unregister Fts
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenUnregisterFts: async (facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getChildrenUnregisterFts', 'facilityId', facilityId)
            const localVarPath = `/api/v1/web/children/get_children_unregister_fts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 子供の情報を取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D002
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD002: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD002', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * D003用お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD003: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD003', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 身長体重一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003 List
         * @param {number} childId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD003List: async (childId: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD003List', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D003_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 身長体重一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003 List Count
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD003ListCount: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD003ListCount', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D003_list_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 身長体重情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D004
         * @param {number} childId 
         * @param {number} [healthRecordId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD004: async (childId: number, healthRecordId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD004', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (healthRecordId !== undefined) {
                localVarQueryParameter['health_record_id'] = healthRecordId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D006
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD006: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD006', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D006`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D007
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD007: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD007', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D007`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D008
         * @param {number} childId 
         * @param {number} [disabilityCertificateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD008: async (childId: number, disabilityCertificateId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD008', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D008`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (disabilityCertificateId !== undefined) {
                localVarQueryParameter['disability_certificate_id'] = disabilityCertificateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D009
         * @param {number} childId 
         * @param {number} [assessmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD009: async (childId: number, assessmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD009', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D009`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (assessmentId !== undefined) {
                localVarQueryParameter['assessment_id'] = assessmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 固定枠情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D010
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD010: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getD010', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_D010`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get E001
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {string} [fullName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE001: async (fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getE001', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/children/get_E001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get E001 By Child
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE001ByChild: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getE001ByChild', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_E001_by_child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get E001 Count
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {string} [fullName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE001Count: async (fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getE001Count', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/children/get_E001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get E002
         * @param {number} childId 
         * @param {number} [individualSupportPlanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE002: async (childId: number, individualSupportPlanId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getE002', 'childId', childId)
            const localVarPath = `/api/v1/web/children/get_E002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (individualSupportPlanId !== undefined) {
                localVarQueryParameter['individual_support_plan_id'] = individualSupportPlanId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Child Image
         * @param {number} childId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChildImage: async (childId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('putChildImage', 'childId', childId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putChildImage', 'file', file)
            const localVarPath = `/api/v1/web/children/put_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D002
         * @param {D002Schema} d002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD002: async (d002Schema: D002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'd002Schema' is not null or undefined
            assertParamExists('putD002', 'd002Schema', d002Schema)
            const localVarPath = `/api/v1/web/children/put_D002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(d002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 身長体重情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D004
         * @param {D004Schema} d004Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD004: async (d004Schema: D004Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'd004Schema' is not null or undefined
            assertParamExists('putD004', 'd004Schema', d004Schema)
            const localVarPath = `/api/v1/web/children/put_D004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(d004Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受給者証情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D008
         * @param {ChildD008Schema} childD008Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD008: async (childD008Schema: ChildD008Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childD008Schema' is not null or undefined
            assertParamExists('putD008', 'childD008Schema', childD008Schema)
            const localVarPath = `/api/v1/web/children/put_D008`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(childD008Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセスメントシート情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D009
         * @param {D009Schema} d009Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD009: async (d009Schema: D009Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'd009Schema' is not null or undefined
            assertParamExists('putD009', 'd009Schema', d009Schema)
            const localVarPath = `/api/v1/web/children/put_D009`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(d009Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アセスメントシートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D009 Doc
         * @param {number} assessmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD009Doc: async (assessmentId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentId' is not null or undefined
            assertParamExists('putD009Doc', 'assessmentId', assessmentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putD009Doc', 'file', file)
            const localVarPath = `/api/v1/web/children/put_D009_doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (assessmentId !== undefined) {
                localVarQueryParameter['assessment_id'] = assessmentId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 固定枠情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D010
         * @param {number} childId 
         * @param {Array<D010Schema>} d010Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD010: async (childId: number, d010Schema: Array<D010Schema>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('putD010', 'childId', childId)
            // verify required parameter 'd010Schema' is not null or undefined
            assertParamExists('putD010', 'd010Schema', d010Schema)
            const localVarPath = `/api/v1/web/children/put_D010`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(d010Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put E002
         * @param {E002Schema} e002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putE002: async (e002Schema: E002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'e002Schema' is not null or undefined
            assertParamExists('putE002', 'e002Schema', e002Schema)
            const localVarPath = `/api/v1/web/children/put_E002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(e002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put E002 Doc
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putE002Doc: async (individualSupportPlanId: number, fileType: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'individualSupportPlanId' is not null or undefined
            assertParamExists('putE002Doc', 'individualSupportPlanId', individualSupportPlanId)
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('putE002Doc', 'fileType', fileType)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putE002Doc', 'file', file)
            const localVarPath = `/api/v1/web/children/put_E002_doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (individualSupportPlanId !== undefined) {
                localVarQueryParameter['individual_support_plan_id'] = individualSupportPlanId;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChildrenApi - functional programming interface
 * @export
 */
export const ChildrenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChildrenApiAxiosParamCreator(configuration)
    return {
        /**
         * お子様の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Child
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChild(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChild(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 身長体重レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete D004
         * @param {number} healthRecordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteD004(healthRecordId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteD004(healthRecordId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アセスメントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete D009
         * @param {number} assessmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteD009(assessmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteD009(assessmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受給者証情報削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Disability Certificate
         * @param {number} disabilityCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDisabilityCertificate(disabilityCertificateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDisabilityCertificate(disabilityCertificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete E002
         * @param {number} individualSupportPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteE002(individualSupportPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteE002(individualSupportPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary E002 Signature Notify
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async e002SignatureNotify(individualSupportPlanId: number, fileType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.e002SignatureNotify(individualSupportPlanId, fileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Child Info
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildInfo(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildInfoSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildInfo(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For H002
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenForH002(fcFacilityId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChildH002Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenForH002(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For List
         * @param {number} fcFacilityId 
         * @param {string} [fullName] 
         * @param {number} [beginAge] 
         * @param {number} [endAge] 
         * @param {boolean} [endFlg] 
         * @param {number} [sortElement] 
         * @param {number} [sortOrder] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenForList(fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, sortElement?: number, sortOrder?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChildForListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenForList(fcFacilityId, fullName, beginAge, endAge, endFlg, sortElement, sortOrder, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 子供の情報件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For List Count
         * @param {number} fcFacilityId 
         * @param {string} [fullName] 
         * @param {number} [beginAge] 
         * @param {number} [endAge] 
         * @param {boolean} [endFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenForListCount(fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenForListCount(fcFacilityId, fullName, beginAge, endAge, endFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For Select List
         * @param {number} [fcFacilityId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenForSelectList(fcFacilityId?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChildSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenForSelectList(fcFacilityId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 固定時間登録済のお子様のうち、当該月の受給者証が未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Children Unregister Disability Certificate
         * @param {number} facilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenUnregisterDisabilityCertificate(facilityId: number, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenUnregisterDisabilityCertificate(facilityId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 固定時間未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Children Unregister Fts
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildrenUnregisterFts(facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<K001UnregisteredFTSChildSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildrenUnregisterFts(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 子供の情報を取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D002
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD002(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<D002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD002(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * D003用お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD003(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<D003Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD003(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 身長体重一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003 List
         * @param {number} childId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD003List(childId: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<D003HealthRecordSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD003List(childId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 身長体重一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003 List Count
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD003ListCount(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD003ListCount(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 身長体重情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D004
         * @param {number} childId 
         * @param {number} [healthRecordId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD004(childId: number, healthRecordId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<D004Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD004(childId, healthRecordId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D006
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD006(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<D006Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD006(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D007
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD007(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildD007Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD007(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D008
         * @param {number} childId 
         * @param {number} [disabilityCertificateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD008(childId: number, disabilityCertificateId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildD008Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD008(childId, disabilityCertificateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D009
         * @param {number} childId 
         * @param {number} [assessmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD009(childId: number, assessmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<D009Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD009(childId, assessmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 固定枠情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D010
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getD010(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<D010Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getD010(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get E001
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {string} [fullName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getE001(fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<E001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getE001(fcFacilityId, beginDate, endDate, fullName, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get E001 By Child
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getE001ByChild(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<E001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getE001ByChild(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get E001 Count
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {string} [fullName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getE001Count(fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getE001Count(fcFacilityId, beginDate, endDate, fullName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get E002
         * @param {number} childId 
         * @param {number} [individualSupportPlanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getE002(childId: number, individualSupportPlanId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<E002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getE002(childId, individualSupportPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Child Image
         * @param {number} childId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChildImage(childId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putChildImage(childId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D002
         * @param {D002Schema} d002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putD002(d002Schema: D002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putD002(d002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 身長体重情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D004
         * @param {D004Schema} d004Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putD004(d004Schema: D004Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putD004(d004Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受給者証情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D008
         * @param {ChildD008Schema} childD008Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putD008(childD008Schema: ChildD008Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putD008(childD008Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アセスメントシート情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D009
         * @param {D009Schema} d009Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putD009(d009Schema: D009Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putD009(d009Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アセスメントシートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D009 Doc
         * @param {number} assessmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putD009Doc(assessmentId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putD009Doc(assessmentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 固定枠情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D010
         * @param {number} childId 
         * @param {Array<D010Schema>} d010Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putD010(childId: number, d010Schema: Array<D010Schema>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putD010(childId, d010Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put E002
         * @param {E002Schema} e002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putE002(e002Schema: E002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putE002(e002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put E002 Doc
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putE002Doc(individualSupportPlanId: number, fileType: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putE002Doc(individualSupportPlanId, fileType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChildrenApi - factory interface
 * @export
 */
export const ChildrenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChildrenApiFp(configuration)
    return {
        /**
         * お子様の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Child
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChild(childId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteChild(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 身長体重レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete D004
         * @param {number} healthRecordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteD004(healthRecordId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteD004(healthRecordId, options).then((request) => request(axios, basePath));
        },
        /**
         * アセスメントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete D009
         * @param {number} assessmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteD009(assessmentId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteD009(assessmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 受給者証情報削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Disability Certificate
         * @param {number} disabilityCertificateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDisabilityCertificate(disabilityCertificateId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteDisabilityCertificate(disabilityCertificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete E002
         * @param {number} individualSupportPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteE002(individualSupportPlanId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteE002(individualSupportPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary E002 Signature Notify
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e002SignatureNotify(individualSupportPlanId: number, fileType: number, options?: any): AxiosPromise<Result> {
            return localVarFp.e002SignatureNotify(individualSupportPlanId, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Child Info
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildInfo(childId: number, options?: any): AxiosPromise<ChildInfoSchema> {
            return localVarFp.getChildInfo(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For H002
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForH002(fcFacilityId?: number, options?: any): AxiosPromise<Array<ChildH002Schema>> {
            return localVarFp.getChildrenForH002(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For List
         * @param {number} fcFacilityId 
         * @param {string} [fullName] 
         * @param {number} [beginAge] 
         * @param {number} [endAge] 
         * @param {boolean} [endFlg] 
         * @param {number} [sortElement] 
         * @param {number} [sortOrder] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForList(fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, sortElement?: number, sortOrder?: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ChildForListSchema>> {
            return localVarFp.getChildrenForList(fcFacilityId, fullName, beginAge, endAge, endFlg, sortElement, sortOrder, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 子供の情報件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For List Count
         * @param {number} fcFacilityId 
         * @param {string} [fullName] 
         * @param {number} [beginAge] 
         * @param {number} [endAge] 
         * @param {boolean} [endFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForListCount(fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.getChildrenForListCount(fcFacilityId, fullName, beginAge, endAge, endFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Children For Select List
         * @param {number} [fcFacilityId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenForSelectList(fcFacilityId?: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ChildSchema>> {
            return localVarFp.getChildrenForSelectList(fcFacilityId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 固定時間登録済のお子様のうち、当該月の受給者証が未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Children Unregister Disability Certificate
         * @param {number} facilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenUnregisterDisabilityCertificate(facilityId: number, year: number, month: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getChildrenUnregisterDisabilityCertificate(facilityId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 固定時間未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Children Unregister Fts
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenUnregisterFts(facilityId: number, options?: any): AxiosPromise<Array<K001UnregisteredFTSChildSchema>> {
            return localVarFp.getChildrenUnregisterFts(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 子供の情報を取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D002
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD002(childId: number, options?: any): AxiosPromise<D002Schema> {
            return localVarFp.getD002(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * D003用お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD003(childId: number, options?: any): AxiosPromise<D003Schema> {
            return localVarFp.getD003(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 身長体重一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003 List
         * @param {number} childId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD003List(childId: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<D003HealthRecordSchema>> {
            return localVarFp.getD003List(childId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 身長体重一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D003 List Count
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD003ListCount(childId: number, options?: any): AxiosPromise<number> {
            return localVarFp.getD003ListCount(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 身長体重情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D004
         * @param {number} childId 
         * @param {number} [healthRecordId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD004(childId: number, healthRecordId?: number, options?: any): AxiosPromise<D004Schema> {
            return localVarFp.getD004(childId, healthRecordId, options).then((request) => request(axios, basePath));
        },
        /**
         * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D006
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD006(childId: number, options?: any): AxiosPromise<D006Schema> {
            return localVarFp.getD006(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D007
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD007(childId: number, options?: any): AxiosPromise<ChildD007Schema> {
            return localVarFp.getD007(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D008
         * @param {number} childId 
         * @param {number} [disabilityCertificateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD008(childId: number, disabilityCertificateId?: number, options?: any): AxiosPromise<ChildD008Schema> {
            return localVarFp.getD008(childId, disabilityCertificateId, options).then((request) => request(axios, basePath));
        },
        /**
         * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D009
         * @param {number} childId 
         * @param {number} [assessmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD009(childId: number, assessmentId?: number, options?: any): AxiosPromise<D009Schema> {
            return localVarFp.getD009(childId, assessmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 固定枠情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get D010
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getD010(childId: number, options?: any): AxiosPromise<Array<D010Schema>> {
            return localVarFp.getD010(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get E001
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {string} [fullName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE001(fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<E001Schema>> {
            return localVarFp.getE001(fcFacilityId, beginDate, endDate, fullName, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get E001 By Child
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE001ByChild(childId: number, options?: any): AxiosPromise<Array<E001Schema>> {
            return localVarFp.getE001ByChild(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get E001 Count
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {string} [fullName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE001Count(fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getE001Count(fcFacilityId, beginDate, endDate, fullName, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get E002
         * @param {number} childId 
         * @param {number} [individualSupportPlanId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getE002(childId: number, individualSupportPlanId?: number, options?: any): AxiosPromise<E002Schema> {
            return localVarFp.getE002(childId, individualSupportPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Child Image
         * @param {number} childId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChildImage(childId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putChildImage(childId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D002
         * @param {D002Schema} d002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD002(d002Schema: D002Schema, options?: any): AxiosPromise<Result> {
            return localVarFp.putD002(d002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 身長体重情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D004
         * @param {D004Schema} d004Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD004(d004Schema: D004Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putD004(d004Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 受給者証情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D008
         * @param {ChildD008Schema} childD008Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD008(childD008Schema: ChildD008Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putD008(childD008Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * アセスメントシート情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D009
         * @param {D009Schema} d009Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD009(d009Schema: D009Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putD009(d009Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * アセスメントシートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D009 Doc
         * @param {number} assessmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD009Doc(assessmentId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putD009Doc(assessmentId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 固定枠情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put D010
         * @param {number} childId 
         * @param {Array<D010Schema>} d010Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putD010(childId: number, d010Schema: Array<D010Schema>, options?: any): AxiosPromise<number> {
            return localVarFp.putD010(childId, d010Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put E002
         * @param {E002Schema} e002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putE002(e002Schema: E002Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putE002(e002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put E002 Doc
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putE002Doc(individualSupportPlanId: number, fileType: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putE002Doc(individualSupportPlanId, fileType, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChildrenApi - object-oriented interface
 * @export
 * @class ChildrenApi
 * @extends {BaseAPI}
 */
export class ChildrenApi extends BaseAPI {
    /**
     * お子様の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Child
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public deleteChild(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).deleteChild(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 身長体重レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete D004
     * @param {number} healthRecordId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public deleteD004(healthRecordId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).deleteD004(healthRecordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセスメントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete D009
     * @param {number} assessmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public deleteD009(assessmentId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).deleteD009(assessmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受給者証情報削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Disability Certificate
     * @param {number} disabilityCertificateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public deleteDisabilityCertificate(disabilityCertificateId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).deleteDisabilityCertificate(disabilityCertificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画レコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete E002
     * @param {number} individualSupportPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public deleteE002(individualSupportPlanId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).deleteE002(individualSupportPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary E002 Signature Notify
     * @param {number} individualSupportPlanId 
     * @param {number} fileType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public e002SignatureNotify(individualSupportPlanId: number, fileType: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).e002SignatureNotify(individualSupportPlanId, fileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Child Info
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildInfo(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildInfo(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Children For H002
     * @param {number} [fcFacilityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildrenForH002(fcFacilityId?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildrenForH002(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Children For List
     * @param {number} fcFacilityId 
     * @param {string} [fullName] 
     * @param {number} [beginAge] 
     * @param {number} [endAge] 
     * @param {boolean} [endFlg] 
     * @param {number} [sortElement] 
     * @param {number} [sortOrder] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildrenForList(fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, sortElement?: number, sortOrder?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildrenForList(fcFacilityId, fullName, beginAge, endAge, endFlg, sortElement, sortOrder, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 子供の情報件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Children For List Count
     * @param {number} fcFacilityId 
     * @param {string} [fullName] 
     * @param {number} [beginAge] 
     * @param {number} [endAge] 
     * @param {boolean} [endFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildrenForListCount(fcFacilityId: number, fullName?: string, beginAge?: number, endAge?: number, endFlg?: boolean, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildrenForListCount(fcFacilityId, fullName, beginAge, endAge, endFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 子供の情報を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Children For Select List
     * @param {number} [fcFacilityId] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildrenForSelectList(fcFacilityId?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildrenForSelectList(fcFacilityId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 固定時間登録済のお子様のうち、当該月の受給者証が未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Children Unregister Disability Certificate
     * @param {number} facilityId 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildrenUnregisterDisabilityCertificate(facilityId: number, year: number, month: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildrenUnregisterDisabilityCertificate(facilityId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 固定時間未登録のお子様を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Children Unregister Fts
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getChildrenUnregisterFts(facilityId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getChildrenUnregisterFts(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 子供の情報を取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D002
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD002(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD002(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * D003用お子様情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D003
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD003(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD003(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 身長体重一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D003 List
     * @param {number} childId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD003List(childId: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD003List(childId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 身長体重一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D003 List Count
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD003ListCount(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD003ListCount(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 身長体重情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D004
     * @param {number} childId 
     * @param {number} [healthRecordId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD004(childId: number, healthRecordId?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD004(childId, healthRecordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D006
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD006(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD006(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D007
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD007(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD007(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受給者証情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D008
     * @param {number} childId 
     * @param {number} [disabilityCertificateId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD008(childId: number, disabilityCertificateId?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD008(childId, disabilityCertificateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセスメントシート一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D009
     * @param {number} childId 
     * @param {number} [assessmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD009(childId: number, assessmentId?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD009(childId, assessmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 固定枠情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get D010
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getD010(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getD010(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get E001
     * @param {number} fcFacilityId 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {string} [fullName] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getE001(fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getE001(fcFacilityId, beginDate, endDate, fullName, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get E001 By Child
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getE001ByChild(childId: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getE001ByChild(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get E001 Count
     * @param {number} fcFacilityId 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {string} [fullName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getE001Count(fcFacilityId: number, beginDate?: string, endDate?: string, fullName?: string, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getE001Count(fcFacilityId, beginDate, endDate, fullName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get E002
     * @param {number} childId 
     * @param {number} [individualSupportPlanId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public getE002(childId: number, individualSupportPlanId?: number, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).getE002(childId, individualSupportPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Child Image
     * @param {number} childId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putChildImage(childId: number, file: any, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putChildImage(childId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put D002
     * @param {D002Schema} d002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putD002(d002Schema: D002Schema, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putD002(d002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 身長体重情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put D004
     * @param {D004Schema} d004Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putD004(d004Schema: D004Schema, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putD004(d004Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受給者証情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put D008
     * @param {ChildD008Schema} childD008Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putD008(childD008Schema: ChildD008Schema, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putD008(childD008Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセスメントシート情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put D009
     * @param {D009Schema} d009Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putD009(d009Schema: D009Schema, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putD009(d009Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アセスメントシートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put D009 Doc
     * @param {number} assessmentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putD009Doc(assessmentId: number, file: any, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putD009Doc(assessmentId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 固定枠情報登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put D010
     * @param {number} childId 
     * @param {Array<D010Schema>} d010Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putD010(childId: number, d010Schema: Array<D010Schema>, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putD010(childId, d010Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put E002
     * @param {E002Schema} e002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putE002(e002Schema: E002Schema, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putE002(e002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put E002 Doc
     * @param {number} individualSupportPlanId 
     * @param {number} fileType 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChildrenApi
     */
    public putE002Doc(individualSupportPlanId: number, fileType: number, file: any, options?: AxiosRequestConfig) {
        return ChildrenApiFp(this.configuration).putE002Doc(individualSupportPlanId, fileType, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 問い合わせフォームのメッセージ送信
         * @summary Send Message
         * @param {string} email 
         * @param {string} name 
         * @param {string} message 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (email: string, name: string, message: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sendMessage', 'email', email)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('sendMessage', 'name', name)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('sendMessage', 'message', message)
            const localVarPath = `/api/v1/web/contacts/send_message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 問い合わせフォームのメッセージ送信
         * @summary Send Message
         * @param {string} email 
         * @param {string} name 
         * @param {string} message 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(email: string, name: string, message: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(email, name, message, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsApiFp(configuration)
    return {
        /**
         * 問い合わせフォームのメッセージ送信
         * @summary Send Message
         * @param {string} email 
         * @param {string} name 
         * @param {string} message 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(email: string, name: string, message: string, authorization?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.sendMessage(email, name, message, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * 問い合わせフォームのメッセージ送信
     * @summary Send Message
     * @param {string} email 
     * @param {string} name 
     * @param {string} message 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public sendMessage(email: string, name: string, message: string, authorization?: string, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).sendMessage(email, name, message, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentsApi - axios parameter creator
 * @export
 */
export const EquipmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 備品削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete L004
         * @param {number} equipmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteL004: async (equipmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('deleteL004', 'equipmentId', equipmentId)
            const localVarPath = `/api/v1/web/equipments/delete_L004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (equipmentId !== undefined) {
                localVarQueryParameter['equipment_id'] = equipmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L001
         * @param {string} [serial] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL001: async (serial?: string, name?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/equipments/get_L001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L001 Count
         * @param {string} [serial] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL001Count: async (serial?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/equipments/get_L001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受発注一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get L002
         * @param {number} deliveryStatus 
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL002: async (deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryStatus' is not null or undefined
            assertParamExists('getL002', 'deliveryStatus', deliveryStatus)
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getL002', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/equipments/get_L002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (deliveryStatus !== undefined) {
                localVarQueryParameter['delivery_status'] = deliveryStatus;
            }

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受発注一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get L002 Count
         * @param {number} deliveryStatus 
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL002Count: async (deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryStatus' is not null or undefined
            assertParamExists('getL002Count', 'deliveryStatus', deliveryStatus)
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getL002Count', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/equipments/get_L002_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (deliveryStatus !== undefined) {
                localVarQueryParameter['delivery_status'] = deliveryStatus;
            }

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受発注詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L003
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL003: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getL003', 'orderId', orderId)
            const localVarPath = `/api/v1/web/equipments/get_L003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 備品詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L004
         * @param {number} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL004: async (equipmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/equipments/get_L004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (equipmentId !== undefined) {
                localVarQueryParameter['equipment_id'] = equipmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カート用備品情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L008
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL008: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getL008', 'requestBody', requestBody)
            const localVarPath = `/api/v1/web/equipments/get_L008`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 備品登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004
         * @param {L004Schema} l004Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL004: async (l004Schema: L004Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'l004Schema' is not null or undefined
            assertParamExists('putL004', 'l004Schema', l004Schema)
            const localVarPath = `/api/v1/web/equipments/put_L004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(l004Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004 Image
         * @param {number} equipmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL004Image: async (equipmentId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('putL004Image', 'equipmentId', equipmentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putL004Image', 'file', file)
            const localVarPath = `/api/v1/web/equipments/put_L004_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (equipmentId !== undefined) {
                localVarQueryParameter['equipment_id'] = equipmentId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004 Order Form
         * @param {number} equipmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL004OrderForm: async (equipmentId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('putL004OrderForm', 'equipmentId', equipmentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putL004OrderForm', 'file', file)
            const localVarPath = `/api/v1/web/equipments/put_L004_order_form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (equipmentId !== undefined) {
                localVarQueryParameter['equipment_id'] = equipmentId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カート登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put L008
         * @param {L008Schema} l008Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL008: async (l008Schema: L008Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'l008Schema' is not null or undefined
            assertParamExists('putL008', 'l008Schema', l008Schema)
            const localVarPath = `/api/v1/web/equipments/put_L008`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(l008Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 受注ステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Order Detail Status
         * @param {number} orderDetailId 
         * @param {number} deliveryStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderDetailStatus: async (orderDetailId: number, deliveryStatus: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDetailId' is not null or undefined
            assertParamExists('updateOrderDetailStatus', 'orderDetailId', orderDetailId)
            // verify required parameter 'deliveryStatus' is not null or undefined
            assertParamExists('updateOrderDetailStatus', 'deliveryStatus', deliveryStatus)
            const localVarPath = `/api/v1/web/equipments/update_order_detail_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (orderDetailId !== undefined) {
                localVarQueryParameter['order_detail_id'] = orderDetailId;
            }

            if (deliveryStatus !== undefined) {
                localVarQueryParameter['delivery_status'] = deliveryStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentsApi - functional programming interface
 * @export
 */
export const EquipmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 備品削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete L004
         * @param {number} equipmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteL004(equipmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteL004(equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L001
         * @param {string} [serial] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL001(serial?: string, name?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<L001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL001(serial, name, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L001 Count
         * @param {string} [serial] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL001Count(serial?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL001Count(serial, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受発注一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get L002
         * @param {number} deliveryStatus 
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL002(deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<L002Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL002(deliveryStatus, fcFacilityId, beginDate, endDate, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受発注一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get L002 Count
         * @param {number} deliveryStatus 
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL002Count(deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL002Count(deliveryStatus, fcFacilityId, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受発注詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L003
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL003(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<L003Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL003(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 備品詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L004
         * @param {number} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL004(equipmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<L004Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL004(equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カート用備品情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L008
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getL008(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<L008EquipmentSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getL008(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 備品登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004
         * @param {L004Schema} l004Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putL004(l004Schema: L004Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putL004(l004Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004 Image
         * @param {number} equipmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putL004Image(equipmentId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putL004Image(equipmentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004 Order Form
         * @param {number} equipmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putL004OrderForm(equipmentId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putL004OrderForm(equipmentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カート登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put L008
         * @param {L008Schema} l008Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putL008(l008Schema: L008Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putL008(l008Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 受注ステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Order Detail Status
         * @param {number} orderDetailId 
         * @param {number} deliveryStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderDetailStatus(orderDetailId: number, deliveryStatus: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderDetailStatus(orderDetailId, deliveryStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentsApi - factory interface
 * @export
 */
export const EquipmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentsApiFp(configuration)
    return {
        /**
         * 備品削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete L004
         * @param {number} equipmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteL004(equipmentId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteL004(equipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L001
         * @param {string} [serial] 
         * @param {string} [name] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL001(serial?: string, name?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<L001Schema>> {
            return localVarFp.getL001(serial, name, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L001 Count
         * @param {string} [serial] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL001Count(serial?: string, name?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getL001Count(serial, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 受発注一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get L002
         * @param {number} deliveryStatus 
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL002(deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<L002Schema>> {
            return localVarFp.getL002(deliveryStatus, fcFacilityId, beginDate, endDate, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 受発注一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get L002 Count
         * @param {number} deliveryStatus 
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL002Count(deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getL002Count(deliveryStatus, fcFacilityId, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 受発注詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L003
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL003(orderId: number, options?: any): AxiosPromise<Array<L003Schema>> {
            return localVarFp.getL003(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 備品詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L004
         * @param {number} [equipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL004(equipmentId?: number, options?: any): AxiosPromise<L004Schema> {
            return localVarFp.getL004(equipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * カート用備品情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get L008
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getL008(requestBody: Array<number>, options?: any): AxiosPromise<Array<L008EquipmentSchema>> {
            return localVarFp.getL008(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 備品登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004
         * @param {L004Schema} l004Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL004(l004Schema: L004Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putL004(l004Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004 Image
         * @param {number} equipmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL004Image(equipmentId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putL004Image(equipmentId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put L004 Order Form
         * @param {number} equipmentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL004OrderForm(equipmentId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putL004OrderForm(equipmentId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * カート登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put L008
         * @param {L008Schema} l008Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putL008(l008Schema: L008Schema, options?: any): AxiosPromise<Result> {
            return localVarFp.putL008(l008Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 受注ステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Order Detail Status
         * @param {number} orderDetailId 
         * @param {number} deliveryStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderDetailStatus(orderDetailId: number, deliveryStatus: number, options?: any): AxiosPromise<Result> {
            return localVarFp.updateOrderDetailStatus(orderDetailId, deliveryStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentsApi - object-oriented interface
 * @export
 * @class EquipmentsApi
 * @extends {BaseAPI}
 */
export class EquipmentsApi extends BaseAPI {
    /**
     * 備品削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Delete L004
     * @param {number} equipmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public deleteL004(equipmentId: number, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).deleteL004(equipmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get L001
     * @param {string} [serial] 
     * @param {string} [name] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL001(serial?: string, name?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL001(serial, name, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 備品一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get L001 Count
     * @param {string} [serial] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL001Count(serial?: string, name?: string, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL001Count(serial, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受発注一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get L002
     * @param {number} deliveryStatus 
     * @param {number} fcFacilityId 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL002(deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL002(deliveryStatus, fcFacilityId, beginDate, endDate, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受発注一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get L002 Count
     * @param {number} deliveryStatus 
     * @param {number} fcFacilityId 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL002Count(deliveryStatus: number, fcFacilityId: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL002Count(deliveryStatus, fcFacilityId, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受発注詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get L003
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL003(orderId: number, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL003(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 備品詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get L004
     * @param {number} [equipmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL004(equipmentId?: number, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL004(equipmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カート用備品情報取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get L008
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public getL008(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).getL008(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 備品登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put L004
     * @param {L004Schema} l004Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public putL004(l004Schema: L004Schema, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).putL004(l004Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put L004 Image
     * @param {number} equipmentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public putL004Image(equipmentId: number, file: any, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).putL004Image(equipmentId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put L004 Order Form
     * @param {number} equipmentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public putL004OrderForm(equipmentId: number, file: any, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).putL004OrderForm(equipmentId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カート登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put L008
     * @param {L008Schema} l008Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public putL008(l008Schema: L008Schema, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).putL008(l008Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 受注ステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Update Order Detail Status
     * @param {number} orderDetailId 
     * @param {number} deliveryStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public updateOrderDetailStatus(orderDetailId: number, deliveryStatus: number, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).updateOrderDetailStatus(orderDetailId, deliveryStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * イベントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Event
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteEvent', 'eventId', eventId)
            const localVarPath = `/api/v1/web/events/delete_event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get V001
         * @param {number} [fcFacilityId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV001: async (fcFacilityId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/events/get_V001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V001用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get V001 Count
         * @param {number} [fcFacilityId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV001Count: async (fcFacilityId?: number, beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/events/get_V001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get V002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV002: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getV002', 'id', id)
            const localVarPath = `/api/v1/web/events/get_V002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V003用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get V003
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV003: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getV003', 'id', id)
            const localVarPath = `/api/v1/web/events/get_V003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Event Image
         * @param {number} eventId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEventImage: async (eventId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('putEventImage', 'eventId', eventId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putEventImage', 'file', file)
            const localVarPath = `/api/v1/web/events/put_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * V003レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put V003
         * @param {EventV003Schema} eventV003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV003: async (eventV003Schema: EventV003Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventV003Schema' is not null or undefined
            assertParamExists('putV003', 'eventV003Schema', eventV003Schema)
            const localVarPath = `/api/v1/web/events/put_V003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventV003Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イベントへの追加予約不可変更  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Stop Event Reservation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopEventReservation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stopEventReservation', 'id', id)
            const localVarPath = `/api/v1/web/events/stop_event_reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イベント詳細のステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Event Detail Status
         * @param {number} status 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventDetailStatus: async (status: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('updateEventDetailStatus', 'status', status)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateEventDetailStatus', 'requestBody', requestBody)
            const localVarPath = `/api/v1/web/events/event_detail_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * イベントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Event
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get V001
         * @param {number} [fcFacilityId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV001(fcFacilityId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventV001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV001(fcFacilityId, beginDate, endDate, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V001用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get V001 Count
         * @param {number} [fcFacilityId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV001Count(fcFacilityId?: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV001Count(fcFacilityId, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get V002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV002(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventV002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV002(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V003用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get V003
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV003(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventV003Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV003(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Event Image
         * @param {number} eventId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEventImage(eventId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEventImage(eventId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * V003レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put V003
         * @param {EventV003Schema} eventV003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putV003(eventV003Schema: EventV003Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putV003(eventV003Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * イベントへの追加予約不可変更  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Stop Event Reservation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopEventReservation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopEventReservation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * イベント詳細のステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Event Detail Status
         * @param {number} status 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventDetailStatus(status: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventDetailStatus(status, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * イベントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Event
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(eventId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * V001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get V001
         * @param {number} [fcFacilityId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV001(fcFacilityId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<EventV001Schema>> {
            return localVarFp.getV001(fcFacilityId, beginDate, endDate, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * V001用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get V001 Count
         * @param {number} [fcFacilityId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV001Count(fcFacilityId?: number, beginDate?: string, endDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getV001Count(fcFacilityId, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * V002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get V002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV002(id: number, options?: any): AxiosPromise<EventV002Schema> {
            return localVarFp.getV002(id, options).then((request) => request(axios, basePath));
        },
        /**
         * V003用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get V003
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV003(id: number, options?: any): AxiosPromise<EventV003Schema> {
            return localVarFp.getV003(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Event Image
         * @param {number} eventId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEventImage(eventId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putEventImage(eventId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * V003レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put V003
         * @param {EventV003Schema} eventV003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV003(eventV003Schema: EventV003Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putV003(eventV003Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * イベントへの追加予約不可変更  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Stop Event Reservation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopEventReservation(id: number, options?: any): AxiosPromise<Result> {
            return localVarFp.stopEventReservation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * イベント詳細のステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Event Detail Status
         * @param {number} status 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventDetailStatus(status: number, requestBody: Array<number>, options?: any): AxiosPromise<Result> {
            return localVarFp.updateEventDetailStatus(status, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * イベントレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Event
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public deleteEvent(eventId: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).deleteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V001用レコードの取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get V001
     * @param {number} [fcFacilityId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getV001(fcFacilityId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getV001(fcFacilityId, beginDate, endDate, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V001用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get V001 Count
     * @param {number} [fcFacilityId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getV001Count(fcFacilityId?: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getV001Count(fcFacilityId, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V002用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get V002
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getV002(id: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getV002(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V003用レコードの取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get V003
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getV003(id: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getV003(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Event Image
     * @param {number} eventId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public putEventImage(eventId: number, file: any, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).putEventImage(eventId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * V003レコードの登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put V003
     * @param {EventV003Schema} eventV003Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public putV003(eventV003Schema: EventV003Schema, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).putV003(eventV003Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * イベントへの追加予約不可変更  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Stop Event Reservation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public stopEventReservation(id: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).stopEventReservation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * イベント詳細のステータス更新  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Update Event Detail Status
     * @param {number} status 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public updateEventDetailStatus(status: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).updateEventDetailStatus(status, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FcFacilitiesApi - axios parameter creator
 * @export
 */
export const FcFacilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 特定の施設を削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete F002
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteF002: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('deleteF002', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/fc_facilities/delete_F002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ダッシュボード表示用情報の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get B001
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB001: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getB001', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/B001`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ダッシュボード表示用情報の取得  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Get B001 Headquarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB001Headquarter: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/fc_facilities/B001_headquarter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の施設を取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get F002
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getF002: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getF002', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/F002`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fc_facilities_tableに登録されているFC店舗の一覧を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Fc Facilities
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcFacilities: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/fc_facilities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の施設の休日を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Facility Holiday
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcFacilityHoliday: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getFcFacilityHoliday', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/holiday`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の施設の名前を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Facility Name
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcFacilityName: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getFcFacilityName', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/name`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の施設の1カ月のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K001
         * @param {number} fcFacilityId 
         * @param {string} beginDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK001: async (fcFacilityId: number, beginDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getK001', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'beginDate' is not null or undefined
            assertParamExists('getK001', 'beginDate', beginDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getK001', 'endDate', endDate)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/K001`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の施設の1週間のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K001 Weekly
         * @param {number} fcFacilityId 
         * @param {string} beginDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK001Weekly: async (fcFacilityId: number, beginDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getK001Weekly', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'beginDate' is not null or undefined
            assertParamExists('getK001Weekly', 'beginDate', beginDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getK001Weekly', 'endDate', endDate)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/Weekly`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 当該施設にてトレーニング予約依頼を通知した最終月を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Last Reservation Request Month
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastReservationRequestMonth: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getLastReservationRequestMonth', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/fc_facilities/{fc_facility_id}/last_reservation_request_month`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の施設を登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put F002
         * @param {FCFacilityF002Schema} fCFacilityF002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putF002: async (fCFacilityF002Schema: FCFacilityF002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fCFacilityF002Schema' is not null or undefined
            assertParamExists('putF002', 'fCFacilityF002Schema', fCFacilityF002Schema)
            const localVarPath = `/api/v1/web/fc_facilities/put_F002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fCFacilityF002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FcFacilitiesApi - functional programming interface
 * @export
 */
export const FcFacilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FcFacilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 特定の施設を削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete F002
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteF002(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteF002(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ダッシュボード表示用情報の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get B001
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getB001(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<B001Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB001(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ダッシュボード表示用情報の取得  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Get B001 Headquarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getB001Headquarter(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<B001Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB001Headquarter(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の施設を取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get F002
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getF002(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCFacilityF002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getF002(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * fc_facilities_tableに登録されているFC店舗の一覧を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Fc Facilities
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFcFacilities(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FCFacilitiesSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFcFacilities(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の施設の休日を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Facility Holiday
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFcFacilityHoliday(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFcFacilityHoliday(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の施設の名前を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Facility Name
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFcFacilityName(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFcFacilityName(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の施設の1カ月のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K001
         * @param {number} fcFacilityId 
         * @param {string} beginDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getK001(fcFacilityId: number, beginDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FCFacilityK001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getK001(fcFacilityId, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の施設の1週間のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K001 Weekly
         * @param {number} fcFacilityId 
         * @param {string} beginDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getK001Weekly(fcFacilityId: number, beginDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<K001WeeklySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getK001Weekly(fcFacilityId, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 当該施設にてトレーニング予約依頼を通知した最終月を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Last Reservation Request Month
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastReservationRequestMonth(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastReservationRequestMonth(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の施設を登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put F002
         * @param {FCFacilityF002Schema} fCFacilityF002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putF002(fCFacilityF002Schema: FCFacilityF002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putF002(fCFacilityF002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FcFacilitiesApi - factory interface
 * @export
 */
export const FcFacilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FcFacilitiesApiFp(configuration)
    return {
        /**
         * 特定の施設を削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete F002
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteF002(fcFacilityId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteF002(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * ダッシュボード表示用情報の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get B001
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB001(fcFacilityId: number, options?: any): AxiosPromise<B001Schema> {
            return localVarFp.getB001(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * ダッシュボード表示用情報の取得  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Get B001 Headquarter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB001Headquarter(options?: any): AxiosPromise<B001Schema> {
            return localVarFp.getB001Headquarter(options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の施設を取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get F002
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getF002(fcFacilityId: number, options?: any): AxiosPromise<FCFacilityF002Schema> {
            return localVarFp.getF002(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * fc_facilities_tableに登録されているFC店舗の一覧を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Fc Facilities
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcFacilities(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<FCFacilitiesSchema>> {
            return localVarFp.getFcFacilities(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の施設の休日を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Facility Holiday
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcFacilityHoliday(fcFacilityId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getFcFacilityHoliday(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の施設の名前を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Facility Name
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcFacilityName(fcFacilityId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getFcFacilityName(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の施設の1カ月のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K001
         * @param {number} fcFacilityId 
         * @param {string} beginDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK001(fcFacilityId: number, beginDate: string, endDate: string, options?: any): AxiosPromise<Array<FCFacilityK001Schema>> {
            return localVarFp.getK001(fcFacilityId, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の施設の1週間のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K001 Weekly
         * @param {number} fcFacilityId 
         * @param {string} beginDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK001Weekly(fcFacilityId: number, beginDate: string, endDate: string, options?: any): AxiosPromise<K001WeeklySchema> {
            return localVarFp.getK001Weekly(fcFacilityId, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 当該施設にてトレーニング予約依頼を通知した最終月を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Last Reservation Request Month
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastReservationRequestMonth(fcFacilityId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getLastReservationRequestMonth(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の施設を登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put F002
         * @param {FCFacilityF002Schema} fCFacilityF002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putF002(fCFacilityF002Schema: FCFacilityF002Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putF002(fCFacilityF002Schema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FcFacilitiesApi - object-oriented interface
 * @export
 * @class FcFacilitiesApi
 * @extends {BaseAPI}
 */
export class FcFacilitiesApi extends BaseAPI {
    /**
     * 特定の施設を削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Delete F002
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public deleteF002(fcFacilityId: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).deleteF002(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ダッシュボード表示用情報の取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get B001
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getB001(fcFacilityId: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getB001(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ダッシュボード表示用情報の取得  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Get B001 Headquarter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getB001Headquarter(options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getB001Headquarter(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の施設を取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get F002
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getF002(fcFacilityId: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getF002(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fc_facilities_tableに登録されているFC店舗の一覧を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Fc Facilities
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getFcFacilities(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getFcFacilities(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の施設の休日を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Fc Facility Holiday
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getFcFacilityHoliday(fcFacilityId: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getFcFacilityHoliday(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の施設の名前を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Fc Facility Name
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getFcFacilityName(fcFacilityId: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getFcFacilityName(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の施設の1カ月のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get K001
     * @param {number} fcFacilityId 
     * @param {string} beginDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getK001(fcFacilityId: number, beginDate: string, endDate: string, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getK001(fcFacilityId, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の施設の1週間のスケジュールを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get K001 Weekly
     * @param {number} fcFacilityId 
     * @param {string} beginDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getK001Weekly(fcFacilityId: number, beginDate: string, endDate: string, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getK001Weekly(fcFacilityId, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 当該施設にてトレーニング予約依頼を通知した最終月を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Last Reservation Request Month
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public getLastReservationRequestMonth(fcFacilityId: number, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).getLastReservationRequestMonth(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の施設を登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put F002
     * @param {FCFacilityF002Schema} fCFacilityF002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcFacilitiesApi
     */
    public putF002(fCFacilityF002Schema: FCFacilityF002Schema, options?: AxiosRequestConfig) {
        return FcFacilitiesApiFp(this.configuration).putF002(fCFacilityF002Schema, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FcStaffsApi - axios parameter creator
 * @export
 */
export const FcStaffsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * スタッフのメールアドレスを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Email
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffEmail: async (newEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newEmail' is not null or undefined
            assertParamExists('changeStaffEmail', 'newEmail', newEmail)
            const localVarPath = `/api/v1/web/fc_staffs/me/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (newEmail !== undefined) {
                localVarQueryParameter['new_email'] = newEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Image
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffImage: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('changeStaffImage', 'file', file)
            const localVarPath = `/api/v1/web/fc_staffs/me/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スタッフの名前を変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff User Name
         * @param {string} newFamilyName 
         * @param {string} newGivenName 
         * @param {string} newFamilyNameKana 
         * @param {string} newGivenNameKana 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffName: async (newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newFamilyName' is not null or undefined
            assertParamExists('changeStaffName', 'newFamilyName', newFamilyName)
            // verify required parameter 'newGivenName' is not null or undefined
            assertParamExists('changeStaffName', 'newGivenName', newGivenName)
            // verify required parameter 'newFamilyNameKana' is not null or undefined
            assertParamExists('changeStaffName', 'newFamilyNameKana', newFamilyNameKana)
            // verify required parameter 'newGivenNameKana' is not null or undefined
            assertParamExists('changeStaffName', 'newGivenNameKana', newGivenNameKana)
            const localVarPath = `/api/v1/web/fc_staffs/me/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (newFamilyName !== undefined) {
                localVarQueryParameter['new_family_name'] = newFamilyName;
            }

            if (newGivenName !== undefined) {
                localVarQueryParameter['new_given_name'] = newGivenName;
            }

            if (newFamilyNameKana !== undefined) {
                localVarQueryParameter['new_family_name_kana'] = newFamilyNameKana;
            }

            if (newGivenNameKana !== undefined) {
                localVarQueryParameter['new_given_name_kana'] = newGivenNameKana;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スタッフのパスワードを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Password
         * @param {string} password 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffPassword: async (password: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changeStaffPassword', 'password', password)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('changeStaffPassword', 'newPassword', newPassword)
            const localVarPath = `/api/v1/web/fc_staffs/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['new_password'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スタッフ情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Fc Staff
         * @param {number} fcStaffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFcStaff: async (fcStaffId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcStaffId' is not null or undefined
            assertParamExists('deleteFcStaff', 'fcStaffId', fcStaffId)
            const localVarPath = `/api/v1/web/fc_staffs/delete_fc_staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcStaffId !== undefined) {
                localVarQueryParameter['fc_staff_id'] = fcStaffId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Fc Staff
         * @param {number} fcStaffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcStaff: async (fcStaffId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcStaffId' is not null or undefined
            assertParamExists('getFcStaff', 'fcStaffId', fcStaffId)
            const localVarPath = `/api/v1/web/fc_staffs/{fc_staff_id}`
                .replace(`{${"fc_staff_id"}}`, encodeURIComponent(String(fcStaffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Staff By Fc Id
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcStaffByFcId: async (fcFacilityId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/fc_staffs/get_fc_staff_by_fc_id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Fc Staff
         * @param {FCStaffAllInfoSchema} fCStaffAllInfoSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFcStaff: async (fCStaffAllInfoSchema: FCStaffAllInfoSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fCStaffAllInfoSchema' is not null or undefined
            assertParamExists('putFcStaff', 'fCStaffAllInfoSchema', fCStaffAllInfoSchema)
            const localVarPath = `/api/v1/web/fc_staffs/put_fc_staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fCStaffAllInfoSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Fc Staff Image
         * @param {number} fcStaffId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFcStaffImage: async (fcStaffId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcStaffId' is not null or undefined
            assertParamExists('putFcStaffImage', 'fcStaffId', fcStaffId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putFcStaffImage', 'file', file)
            const localVarPath = `/api/v1/web/fc_staffs/put_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcStaffId !== undefined) {
                localVarQueryParameter['fc_staff_id'] = fcStaffId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FcStaffsApi - functional programming interface
 * @export
 */
export const FcStaffsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FcStaffsApiAxiosParamCreator(configuration)
    return {
        /**
         * スタッフのメールアドレスを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Email
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStaffEmail(newEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenSchema | Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStaffEmail(newEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Image
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStaffImage(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStaffImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スタッフの名前を変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff User Name
         * @param {string} newFamilyName 
         * @param {string} newGivenName 
         * @param {string} newFamilyNameKana 
         * @param {string} newGivenNameKana 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStaffName(newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStaffName(newFamilyName, newGivenName, newFamilyNameKana, newGivenNameKana, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スタッフのパスワードを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Password
         * @param {string} password 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStaffPassword(password: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStaffPassword(password, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スタッフ情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Fc Staff
         * @param {number} fcStaffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFcStaff(fcStaffId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFcStaff(fcStaffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Fc Staff
         * @param {number} fcStaffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFcStaff(fcStaffId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FCStaffAllInfoSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFcStaff(fcStaffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Staff By Fc Id
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFcStaffByFcId(fcFacilityId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FCStaffForListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFcStaffByFcId(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Fc Staff
         * @param {FCStaffAllInfoSchema} fCStaffAllInfoSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFcStaff(fCStaffAllInfoSchema: FCStaffAllInfoSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWithIdSchema | Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFcStaff(fCStaffAllInfoSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Fc Staff Image
         * @param {number} fcStaffId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFcStaffImage(fcStaffId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFcStaffImage(fcStaffId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FcStaffsApi - factory interface
 * @export
 */
export const FcStaffsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FcStaffsApiFp(configuration)
    return {
        /**
         * スタッフのメールアドレスを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Email
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffEmail(newEmail: string, options?: any): AxiosPromise<TokenSchema | Result> {
            return localVarFp.changeStaffEmail(newEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Image
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffImage(file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.changeStaffImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * スタッフの名前を変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff User Name
         * @param {string} newFamilyName 
         * @param {string} newGivenName 
         * @param {string} newFamilyNameKana 
         * @param {string} newGivenNameKana 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffName(newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options?: any): AxiosPromise<Result> {
            return localVarFp.changeStaffName(newFamilyName, newGivenName, newFamilyNameKana, newGivenNameKana, options).then((request) => request(axios, basePath));
        },
        /**
         * スタッフのパスワードを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
         * @summary Change Staff Password
         * @param {string} password 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStaffPassword(password: string, newPassword: string, options?: any): AxiosPromise<Result> {
            return localVarFp.changeStaffPassword(password, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * スタッフ情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Fc Staff
         * @param {number} fcStaffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFcStaff(fcStaffId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteFcStaff(fcStaffId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Fc Staff
         * @param {number} fcStaffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcStaff(fcStaffId: number, options?: any): AxiosPromise<FCStaffAllInfoSchema> {
            return localVarFp.getFcStaff(fcStaffId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Fc Staff By Fc Id
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFcStaffByFcId(fcFacilityId?: number, options?: any): AxiosPromise<Array<FCStaffForListSchema>> {
            return localVarFp.getFcStaffByFcId(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Fc Staff
         * @param {FCStaffAllInfoSchema} fCStaffAllInfoSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFcStaff(fCStaffAllInfoSchema: FCStaffAllInfoSchema, options?: any): AxiosPromise<TokenWithIdSchema | Result> {
            return localVarFp.putFcStaff(fCStaffAllInfoSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Fc Staff Image
         * @param {number} fcStaffId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFcStaffImage(fcStaffId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putFcStaffImage(fcStaffId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FcStaffsApi - object-oriented interface
 * @export
 * @class FcStaffsApi
 * @extends {BaseAPI}
 */
export class FcStaffsApi extends BaseAPI {
    /**
     * スタッフのメールアドレスを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
     * @summary Change Staff Email
     * @param {string} newEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public changeStaffEmail(newEmail: string, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).changeStaffEmail(newEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION スタッフかつ自分自身であれば実行可能
     * @summary Change Staff Image
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public changeStaffImage(file: any, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).changeStaffImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スタッフの名前を変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
     * @summary Change Staff User Name
     * @param {string} newFamilyName 
     * @param {string} newGivenName 
     * @param {string} newFamilyNameKana 
     * @param {string} newGivenNameKana 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public changeStaffName(newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).changeStaffName(newFamilyName, newGivenName, newFamilyNameKana, newGivenNameKana, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スタッフのパスワードを変更する  ## PERMISSION スタッフかつ自分自身であれば実行可能
     * @summary Change Staff Password
     * @param {string} password 
     * @param {string} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public changeStaffPassword(password: string, newPassword: string, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).changeStaffPassword(password, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スタッフ情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Fc Staff
     * @param {number} fcStaffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public deleteFcStaff(fcStaffId: number, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).deleteFcStaff(fcStaffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Fc Staff
     * @param {number} fcStaffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public getFcStaff(fcStaffId: number, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).getFcStaff(fcStaffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Fc Staff By Fc Id
     * @param {number} [fcFacilityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public getFcStaffByFcId(fcFacilityId?: number, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).getFcStaffByFcId(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Fc Staff
     * @param {FCStaffAllInfoSchema} fCStaffAllInfoSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public putFcStaff(fCStaffAllInfoSchema: FCStaffAllInfoSchema, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).putFcStaff(fCStaffAllInfoSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Fc Staff Image
     * @param {number} fcStaffId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FcStaffsApi
     */
    public putFcStaffImage(fcStaffId: number, file: any, options?: AxiosRequestConfig) {
        return FcStaffsApiFp(this.configuration).putFcStaffImage(fcStaffId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralsApi - axios parameter creator
 * @export
 */
export const GeneralsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals
         * @param {string} keyword 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneral: async (keyword: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getGeneral', 'keyword', keyword)
            const localVarPath = `/api/v1/web/generals/get_generals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals For List
         * @param {string} keyword 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralsForList: async (keyword: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getGeneralsForList', 'keyword', keyword)
            const localVarPath = `/api/v1/web/generals/get_generals_for_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals For List Multi
         * @param {Array<string>} requestBody 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralsForListMulti: async (requestBody: Array<string>, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getGeneralsForListMulti', 'requestBody', requestBody)
            const localVarPath = `/api/v1/web/generals/get_generals_for_list_multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralsApi - functional programming interface
 * @export
 */
export const GeneralsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralsApiAxiosParamCreator(configuration)
    return {
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals
         * @param {string} keyword 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneral(keyword: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneral(keyword, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals For List
         * @param {string} keyword 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralsForList(keyword: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralForListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralsForList(keyword, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals For List Multi
         * @param {Array<string>} requestBody 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralsForListMulti(requestBody: Array<string>, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralsForListMulti(requestBody, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralsApi - factory interface
 * @export
 */
export const GeneralsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralsApiFp(configuration)
    return {
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals
         * @param {string} keyword 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneral(keyword: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<GeneralSchema>> {
            return localVarFp.getGeneral(keyword, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals For List
         * @param {string} keyword 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralsForList(keyword: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<GeneralForListSchema>> {
            return localVarFp.getGeneralsForList(keyword, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Generals For List Multi
         * @param {Array<string>} requestBody 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralsForListMulti(requestBody: Array<string>, skip?: number, limit?: number, options?: any): AxiosPromise<object> {
            return localVarFp.getGeneralsForListMulti(requestBody, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralsApi - object-oriented interface
 * @export
 * @class GeneralsApi
 * @extends {BaseAPI}
 */
export class GeneralsApi extends BaseAPI {
    /**
     * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Generals
     * @param {string} keyword 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralsApi
     */
    public getGeneral(keyword: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return GeneralsApiFp(this.configuration).getGeneral(keyword, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Generals For List
     * @param {string} keyword 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralsApi
     */
    public getGeneralsForList(keyword: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return GeneralsApiFp(this.configuration).getGeneralsForList(keyword, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 汎用レコードリストを取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Generals For List Multi
     * @param {Array<string>} requestBody 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralsApi
     */
    public getGeneralsForListMulti(requestBody: Array<string>, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return GeneralsApiFp(this.configuration).getGeneralsForListMulti(requestBody, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HeadquartersApi - axios parameter creator
 * @export
 */
export const HeadquartersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 本部ユーザーのメールアドレスを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter Email
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHeadquarterEmail: async (newEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newEmail' is not null or undefined
            assertParamExists('changeHeadquarterEmail', 'newEmail', newEmail)
            const localVarPath = `/api/v1/web/headquarters/me/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (newEmail !== undefined) {
                localVarQueryParameter['new_email'] = newEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 本部ユーザーの名前を変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter User Name
         * @param {string} newFamilyName 
         * @param {string} newGivenName 
         * @param {string} newFamilyNameKana 
         * @param {string} newGivenNameKana 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHeadquarterName: async (newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newFamilyName' is not null or undefined
            assertParamExists('changeHeadquarterName', 'newFamilyName', newFamilyName)
            // verify required parameter 'newGivenName' is not null or undefined
            assertParamExists('changeHeadquarterName', 'newGivenName', newGivenName)
            // verify required parameter 'newFamilyNameKana' is not null or undefined
            assertParamExists('changeHeadquarterName', 'newFamilyNameKana', newFamilyNameKana)
            // verify required parameter 'newGivenNameKana' is not null or undefined
            assertParamExists('changeHeadquarterName', 'newGivenNameKana', newGivenNameKana)
            const localVarPath = `/api/v1/web/headquarters/me/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (newFamilyName !== undefined) {
                localVarQueryParameter['new_family_name'] = newFamilyName;
            }

            if (newGivenName !== undefined) {
                localVarQueryParameter['new_given_name'] = newGivenName;
            }

            if (newFamilyNameKana !== undefined) {
                localVarQueryParameter['new_family_name_kana'] = newFamilyNameKana;
            }

            if (newGivenNameKana !== undefined) {
                localVarQueryParameter['new_given_name_kana'] = newGivenNameKana;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 本部ユーザーのパスワードを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter Password
         * @param {string} password 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHeadquarterPassword: async (password: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changeHeadquarterPassword', 'password', password)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('changeHeadquarterPassword', 'newPassword', newPassword)
            const localVarPath = `/api/v1/web/headquarters/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['new_password'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スタッフ情報を論理削除  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Delete Headquarter User
         * @param {number} headquarterUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHeadquarterUser: async (headquarterUserId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'headquarterUserId' is not null or undefined
            assertParamExists('deleteHeadquarterUser', 'headquarterUserId', headquarterUserId)
            const localVarPath = `/api/v1/web/headquarters/delete_headquarter_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (headquarterUserId !== undefined) {
                localVarQueryParameter['headquarter_user_id'] = headquarterUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get Headquarter User R001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeadquarterUserR001: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/headquarters/get_headquarter_user_R001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get Headquarter User R002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeadquarterUserR002: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHeadquarterUserR002', 'id', id)
            const localVarPath = `/api/v1/web/headquarters/get_headquarter_user_R002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Put Headquarter User Image
         * @param {number} headquarterUserId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHeadquarterUserImage: async (headquarterUserId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'headquarterUserId' is not null or undefined
            assertParamExists('putHeadquarterUserImage', 'headquarterUserId', headquarterUserId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putHeadquarterUserImage', 'file', file)
            const localVarPath = `/api/v1/web/headquarters/put_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (headquarterUserId !== undefined) {
                localVarQueryParameter['headquarter_user_id'] = headquarterUserId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put Headquarter User R002
         * @param {HeadquarterUserR002Schema} headquarterUserR002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHeadquarterUserR002: async (headquarterUserR002Schema: HeadquarterUserR002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'headquarterUserR002Schema' is not null or undefined
            assertParamExists('putHeadquarterUserR002', 'headquarterUserR002Schema', headquarterUserR002Schema)
            const localVarPath = `/api/v1/web/headquarters/put_headquarter_user_R002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(headquarterUserR002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Update Headquarter User Image
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHeadquarterUserImage: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateHeadquarterUserImage', 'file', file)
            const localVarPath = `/api/v1/web/headquarters/me/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HeadquartersApi - functional programming interface
 * @export
 */
export const HeadquartersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HeadquartersApiAxiosParamCreator(configuration)
    return {
        /**
         * 本部ユーザーのメールアドレスを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter Email
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeHeadquarterEmail(newEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenSchema | Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeHeadquarterEmail(newEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 本部ユーザーの名前を変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter User Name
         * @param {string} newFamilyName 
         * @param {string} newGivenName 
         * @param {string} newFamilyNameKana 
         * @param {string} newGivenNameKana 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeHeadquarterName(newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeHeadquarterName(newFamilyName, newGivenName, newFamilyNameKana, newGivenNameKana, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 本部ユーザーのパスワードを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter Password
         * @param {string} password 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeHeadquarterPassword(password: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeHeadquarterPassword(password, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スタッフ情報を論理削除  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Delete Headquarter User
         * @param {number} headquarterUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHeadquarterUser(headquarterUserId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHeadquarterUser(headquarterUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get Headquarter User R001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeadquarterUserR001(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HeadquarterUserR001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeadquarterUserR001(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get Headquarter User R002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeadquarterUserR002(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeadquarterUserR002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeadquarterUserR002(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Put Headquarter User Image
         * @param {number} headquarterUserId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putHeadquarterUserImage(headquarterUserId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putHeadquarterUserImage(headquarterUserId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put Headquarter User R002
         * @param {HeadquarterUserR002Schema} headquarterUserR002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putHeadquarterUserR002(headquarterUserR002Schema: HeadquarterUserR002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWithIdSchema | Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putHeadquarterUserR002(headquarterUserR002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Update Headquarter User Image
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHeadquarterUserImage(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeadquarterUserInfoSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHeadquarterUserImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HeadquartersApi - factory interface
 * @export
 */
export const HeadquartersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HeadquartersApiFp(configuration)
    return {
        /**
         * 本部ユーザーのメールアドレスを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter Email
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHeadquarterEmail(newEmail: string, options?: any): AxiosPromise<TokenSchema | Result> {
            return localVarFp.changeHeadquarterEmail(newEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 本部ユーザーの名前を変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter User Name
         * @param {string} newFamilyName 
         * @param {string} newGivenName 
         * @param {string} newFamilyNameKana 
         * @param {string} newGivenNameKana 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHeadquarterName(newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options?: any): AxiosPromise<Result> {
            return localVarFp.changeHeadquarterName(newFamilyName, newGivenName, newFamilyNameKana, newGivenNameKana, options).then((request) => request(axios, basePath));
        },
        /**
         * 本部ユーザーのパスワードを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Change Headquarter Password
         * @param {string} password 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeHeadquarterPassword(password: string, newPassword: string, options?: any): AxiosPromise<Result> {
            return localVarFp.changeHeadquarterPassword(password, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * スタッフ情報を論理削除  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Delete Headquarter User
         * @param {number} headquarterUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHeadquarterUser(headquarterUserId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteHeadquarterUser(headquarterUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get Headquarter User R001
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeadquarterUserR001(options?: any): AxiosPromise<Array<HeadquarterUserR001Schema>> {
            return localVarFp.getHeadquarterUserR001(options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get Headquarter User R002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeadquarterUserR002(id: number, options?: any): AxiosPromise<HeadquarterUserR002Schema> {
            return localVarFp.getHeadquarterUserR002(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Put Headquarter User Image
         * @param {number} headquarterUserId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHeadquarterUserImage(headquarterUserId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putHeadquarterUserImage(headquarterUserId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put Headquarter User R002
         * @param {HeadquarterUserR002Schema} headquarterUserR002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHeadquarterUserR002(headquarterUserR002Schema: HeadquarterUserR002Schema, options?: any): AxiosPromise<TokenWithIdSchema | Result> {
            return localVarFp.putHeadquarterUserR002(headquarterUserR002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Update Headquarter User Image
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHeadquarterUserImage(file: any, options?: any): AxiosPromise<HeadquarterUserInfoSchema> {
            return localVarFp.updateHeadquarterUserImage(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HeadquartersApi - object-oriented interface
 * @export
 * @class HeadquartersApi
 * @extends {BaseAPI}
 */
export class HeadquartersApi extends BaseAPI {
    /**
     * 本部ユーザーのメールアドレスを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Change Headquarter Email
     * @param {string} newEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public changeHeadquarterEmail(newEmail: string, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).changeHeadquarterEmail(newEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 本部ユーザーの名前を変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Change Headquarter User Name
     * @param {string} newFamilyName 
     * @param {string} newGivenName 
     * @param {string} newFamilyNameKana 
     * @param {string} newGivenNameKana 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public changeHeadquarterName(newFamilyName: string, newGivenName: string, newFamilyNameKana: string, newGivenNameKana: string, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).changeHeadquarterName(newFamilyName, newGivenName, newFamilyNameKana, newGivenNameKana, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 本部ユーザーのパスワードを変更する  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Change Headquarter Password
     * @param {string} password 
     * @param {string} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public changeHeadquarterPassword(password: string, newPassword: string, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).changeHeadquarterPassword(password, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スタッフ情報を論理削除  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Delete Headquarter User
     * @param {number} headquarterUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public deleteHeadquarterUser(headquarterUserId: number, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).deleteHeadquarterUser(headquarterUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get Headquarter User R001
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public getHeadquarterUserR001(options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).getHeadquarterUserR001(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get Headquarter User R002
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public getHeadquarterUserR002(id: number, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).getHeadquarterUserR002(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Put Headquarter User Image
     * @param {number} headquarterUserId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public putHeadquarterUserImage(headquarterUserId: number, file: any, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).putHeadquarterUserImage(headquarterUserId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put Headquarter User R002
     * @param {HeadquarterUserR002Schema} headquarterUserR002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public putHeadquarterUserR002(headquarterUserR002Schema: HeadquarterUserR002Schema, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).putHeadquarterUserR002(headquarterUserR002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Update Headquarter User Image
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeadquartersApi
     */
    public updateHeadquarterUserImage(file: any, options?: AxiosRequestConfig) {
        return HeadquartersApiFp(this.configuration).updateHeadquarterUserImage(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HolidayPlannerApi - axios parameter creator
 * @export
 */
export const HolidayPlannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 祝日設定を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Holiday Planner
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidayPlanner: async (fcFacilityId: number, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getHolidayPlanner', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getHolidayPlanner', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getHolidayPlanner', 'month', month)
            const localVarPath = `/api/v1/web/holiday_planner/holidayPlanner/getHolidayPlanner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 祝日設定を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Holiday Planner
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {K003PutRequestSchema} k003PutRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHolidayPlanner: async (fcFacilityId: number, year: number, month: number, k003PutRequestSchema: K003PutRequestSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('putHolidayPlanner', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('putHolidayPlanner', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('putHolidayPlanner', 'month', month)
            // verify required parameter 'k003PutRequestSchema' is not null or undefined
            assertParamExists('putHolidayPlanner', 'k003PutRequestSchema', k003PutRequestSchema)
            const localVarPath = `/api/v1/web/holiday_planner/holidayPlanner/putHolidayPlanner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(k003PutRequestSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HolidayPlannerApi - functional programming interface
 * @export
 */
export const HolidayPlannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HolidayPlannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 祝日設定を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Holiday Planner
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHolidayPlanner(fcFacilityId: number, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<K003Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHolidayPlanner(fcFacilityId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 祝日設定を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Holiday Planner
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {K003PutRequestSchema} k003PutRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putHolidayPlanner(fcFacilityId: number, year: number, month: number, k003PutRequestSchema: K003PutRequestSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putHolidayPlanner(fcFacilityId, year, month, k003PutRequestSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HolidayPlannerApi - factory interface
 * @export
 */
export const HolidayPlannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HolidayPlannerApiFp(configuration)
    return {
        /**
         * 祝日設定を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Holiday Planner
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidayPlanner(fcFacilityId: number, year: number, month: number, options?: any): AxiosPromise<Array<K003Schema>> {
            return localVarFp.getHolidayPlanner(fcFacilityId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 祝日設定を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Holiday Planner
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {K003PutRequestSchema} k003PutRequestSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHolidayPlanner(fcFacilityId: number, year: number, month: number, k003PutRequestSchema: K003PutRequestSchema, options?: any): AxiosPromise<Result> {
            return localVarFp.putHolidayPlanner(fcFacilityId, year, month, k003PutRequestSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HolidayPlannerApi - object-oriented interface
 * @export
 * @class HolidayPlannerApi
 * @extends {BaseAPI}
 */
export class HolidayPlannerApi extends BaseAPI {
    /**
     * 祝日設定を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Holiday Planner
     * @param {number} fcFacilityId 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayPlannerApi
     */
    public getHolidayPlanner(fcFacilityId: number, year: number, month: number, options?: AxiosRequestConfig) {
        return HolidayPlannerApiFp(this.configuration).getHolidayPlanner(fcFacilityId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 祝日設定を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Put Holiday Planner
     * @param {number} fcFacilityId 
     * @param {number} year 
     * @param {number} month 
     * @param {K003PutRequestSchema} k003PutRequestSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayPlannerApi
     */
    public putHolidayPlanner(fcFacilityId: number, year: number, month: number, k003PutRequestSchema: K003PutRequestSchema, options?: AxiosRequestConfig) {
        return HolidayPlannerApiFp(this.configuration).putHolidayPlanner(fcFacilityId, year, month, k003PutRequestSchema, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 送信用新着情報詳細削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJ002: async (notificationMakeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationMakeId' is not null or undefined
            assertParamExists('deleteJ002', 'notificationMakeId', notificationMakeId)
            const localVarPath = `/api/v1/web/notifications/delete_J002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (notificationMakeId !== undefined) {
                localVarQueryParameter['notification_make_id'] = notificationMakeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J001 Receive
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001Receive: async (beginDate?: string, endDate?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/notifications/get_J001_receive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J001 Receive Count
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001ReceiveCount: async (beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/notifications/get_J001_receive_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get J001 Send
         * @param {number} fcFacilityId 
         * @param {number} toType 
         * @param {number} recipient 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001Send: async (fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getJ001Send', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'toType' is not null or undefined
            assertParamExists('getJ001Send', 'toType', toType)
            // verify required parameter 'recipient' is not null or undefined
            assertParamExists('getJ001Send', 'recipient', recipient)
            const localVarPath = `/api/v1/web/notifications/get_J001_send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (toType !== undefined) {
                localVarQueryParameter['to_type'] = toType;
            }

            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get J001 Send Count
         * @param {number} fcFacilityId 
         * @param {number} toType 
         * @param {number} recipient 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001SendCount: async (fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getJ001SendCount', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'toType' is not null or undefined
            assertParamExists('getJ001SendCount', 'toType', toType)
            // verify required parameter 'recipient' is not null or undefined
            assertParamExists('getJ001SendCount', 'recipient', recipient)
            const localVarPath = `/api/v1/web/notifications/get_J001_send_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (toType !== undefined) {
                localVarQueryParameter['to_type'] = toType;
            }

            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ002: async (notificationMakeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationMakeId' is not null or undefined
            assertParamExists('getJ002', 'notificationMakeId', notificationMakeId)
            const localVarPath = `/api/v1/web/notifications/get_J002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (notificationMakeId !== undefined) {
                localVarQueryParameter['notification_make_id'] = notificationMakeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J003
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ003: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('getJ003', 'notificationId', notificationId)
            const localVarPath = `/api/v1/web/notifications/get_J003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (notificationId !== undefined) {
                localVarQueryParameter['notification_id'] = notificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報詳細登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put J002
         * @param {J002Schema} j002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJ002: async (j002Schema: J002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'j002Schema' is not null or undefined
            assertParamExists('putJ002', 'j002Schema', j002Schema)
            const localVarPath = `/api/v1/web/notifications/put_J002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(j002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 送信用新着情報画像登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put J002 Image
         * @param {number} notificationMakeId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJ002Image: async (notificationMakeId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationMakeId' is not null or undefined
            assertParamExists('putJ002Image', 'notificationMakeId', notificationMakeId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putJ002Image', 'file', file)
            const localVarPath = `/api/v1/web/notifications/put_J002_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (notificationMakeId !== undefined) {
                localVarQueryParameter['notification_make_id'] = notificationMakeId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新着情報送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Send J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendJ002: async (notificationMakeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationMakeId' is not null or undefined
            assertParamExists('sendJ002', 'notificationMakeId', notificationMakeId)
            const localVarPath = `/api/v1/web/notifications/send_J002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (notificationMakeId !== undefined) {
                localVarQueryParameter['notification_make_id'] = notificationMakeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 送信用新着情報詳細削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJ002(notificationMakeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJ002(notificationMakeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J001 Receive
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJ001Receive(beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<J001ReceiveSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJ001Receive(beginDate, endDate, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J001 Receive Count
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJ001ReceiveCount(beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJ001ReceiveCount(beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get J001 Send
         * @param {number} fcFacilityId 
         * @param {number} toType 
         * @param {number} recipient 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJ001Send(fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<J001SendSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJ001Send(fcFacilityId, toType, recipient, beginDate, endDate, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get J001 Send Count
         * @param {number} fcFacilityId 
         * @param {number} toType 
         * @param {number} recipient 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJ001SendCount(fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJ001SendCount(fcFacilityId, toType, recipient, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJ002(notificationMakeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<J002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJ002(notificationMakeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J003
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJ003(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<J003Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJ003(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報詳細登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put J002
         * @param {J002Schema} j002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putJ002(j002Schema: J002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putJ002(j002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 送信用新着情報画像登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put J002 Image
         * @param {number} notificationMakeId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putJ002Image(notificationMakeId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putJ002Image(notificationMakeId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新着情報送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Send J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendJ002(notificationMakeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendJ002(notificationMakeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 送信用新着情報詳細削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJ002(notificationMakeId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteJ002(notificationMakeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J001 Receive
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001Receive(beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<J001ReceiveSchema>> {
            return localVarFp.getJ001Receive(beginDate, endDate, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J001 Receive Count
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001ReceiveCount(beginDate?: string, endDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getJ001ReceiveCount(beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get J001 Send
         * @param {number} fcFacilityId 
         * @param {number} toType 
         * @param {number} recipient 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001Send(fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<J001SendSchema>> {
            return localVarFp.getJ001Send(fcFacilityId, toType, recipient, beginDate, endDate, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get J001 Send Count
         * @param {number} fcFacilityId 
         * @param {number} toType 
         * @param {number} recipient 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ001SendCount(fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getJ001SendCount(fcFacilityId, toType, recipient, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ002(notificationMakeId: number, options?: any): AxiosPromise<J002Schema> {
            return localVarFp.getJ002(notificationMakeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get J003
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJ003(notificationId: number, options?: any): AxiosPromise<J003Schema> {
            return localVarFp.getJ003(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報詳細登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put J002
         * @param {J002Schema} j002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJ002(j002Schema: J002Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putJ002(j002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 送信用新着情報画像登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put J002 Image
         * @param {number} notificationMakeId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJ002Image(notificationMakeId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putJ002Image(notificationMakeId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 新着情報送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Send J002
         * @param {number} notificationMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendJ002(notificationMakeId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.sendJ002(notificationMakeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 送信用新着情報詳細削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete J002
     * @param {number} notificationMakeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public deleteJ002(notificationMakeId: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).deleteJ002(notificationMakeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get J001 Receive
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getJ001Receive(beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getJ001Receive(beginDate, endDate, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get J001 Receive Count
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getJ001ReceiveCount(beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getJ001ReceiveCount(beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get J001 Send
     * @param {number} fcFacilityId 
     * @param {number} toType 
     * @param {number} recipient 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getJ001Send(fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getJ001Send(fcFacilityId, toType, recipient, beginDate, endDate, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get J001 Send Count
     * @param {number} fcFacilityId 
     * @param {number} toType 
     * @param {number} recipient 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getJ001SendCount(fcFacilityId: number, toType: number, recipient: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getJ001SendCount(fcFacilityId, toType, recipient, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get J002
     * @param {number} notificationMakeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getJ002(notificationMakeId: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getJ002(notificationMakeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get J003
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getJ003(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getJ003(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報詳細登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put J002
     * @param {J002Schema} j002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public putJ002(j002Schema: J002Schema, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).putJ002(j002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 送信用新着情報画像登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put J002 Image
     * @param {number} notificationMakeId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public putJ002Image(notificationMakeId: number, file: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).putJ002Image(notificationMakeId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新着情報送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Send J002
     * @param {number} notificationMakeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendJ002(notificationMakeId: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).sendJ002(notificationMakeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Oauth2Api - axios parameter creator
 * @export
 */
export const Oauth2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * versionが一致するか確認
         * @summary Check Version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVersion: async (version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('checkVersion', 'version', version)
            const localVarPath = `/check_version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールアドレス、パスワード、クライアントIDからユーザー認証を行い、トークンを発行する。<br> クライアントIDは - headquarter_user - staff - parent  の3種類
         * @summary Token From Form Data
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('token', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('token', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在ログインしているユーザーの情報を返す。
         * @summary User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Oauth2Api - functional programming interface
 * @export
 */
export const Oauth2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Oauth2ApiAxiosParamCreator(configuration)
    return {
        /**
         * versionが一致するか確認
         * @summary Check Version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkVersion(version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkVersion(version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールアドレス、パスワード、クライアントIDからユーザー認証を行い、トークンを発行する。<br> クライアントIDは - headquarter_user - staff - parent  の3種類
         * @summary Token From Form Data
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async token(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.token(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在ログインしているユーザーの情報を返す。
         * @summary User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentInfoSchema | FCStaffInfoSchema | HeadquarterUserInfoSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Oauth2Api - factory interface
 * @export
 */
export const Oauth2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Oauth2ApiFp(configuration)
    return {
        /**
         * versionが一致するか確認
         * @summary Check Version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVersion(version: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkVersion(version, options).then((request) => request(axios, basePath));
        },
        /**
         * メールアドレス、パスワード、クライアントIDからユーザー認証を行い、トークンを発行する。<br> クライアントIDは - headquarter_user - staff - parent  の3種類
         * @summary Token From Form Data
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<TokenSchema> {
            return localVarFp.token(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在ログインしているユーザーの情報を返す。
         * @summary User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfo(options?: any): AxiosPromise<ParentInfoSchema | FCStaffInfoSchema | HeadquarterUserInfoSchema> {
            return localVarFp.userInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Oauth2Api - object-oriented interface
 * @export
 * @class Oauth2Api
 * @extends {BaseAPI}
 */
export class Oauth2Api extends BaseAPI {
    /**
     * versionが一致するか確認
     * @summary Check Version
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Oauth2Api
     */
    public checkVersion(version: string, options?: AxiosRequestConfig) {
        return Oauth2ApiFp(this.configuration).checkVersion(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールアドレス、パスワード、クライアントIDからユーザー認証を行い、トークンを発行する。<br> クライアントIDは - headquarter_user - staff - parent  の3種類
     * @summary Token From Form Data
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Oauth2Api
     */
    public token(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return Oauth2ApiFp(this.configuration).token(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在ログインしているユーザーの情報を返す。
     * @summary User Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Oauth2Api
     */
    public userInfo(options?: AxiosRequestConfig) {
        return Oauth2ApiFp(this.configuration).userInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParentsApi - axios parameter creator
 * @export
 */
export const ParentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Create Parent Children
         * @param {ParentChildrenCreateSchema} parentChildrenCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParentChildren: async (parentChildrenCreateSchema: ParentChildrenCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentChildrenCreateSchema' is not null or undefined
            assertParamExists('createParentChildren', 'parentChildrenCreateSchema', parentChildrenCreateSchema)
            const localVarPath = `/api/v1/web/parents/create_parent_children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentChildrenCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 親の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Parent
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParent: async (parentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('deleteParent', 'parentId', parentId)
            const localVarPath = `/api/v1/web/parents/delete_parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定の親の情報を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parent
         * @param {number} parentId 
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParent: async (parentId: number, fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getParent', 'parentId', parentId)
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getParent', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/parents/{parent_id}`
                .replace(`{${"parent_id"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * すべての親の一覧を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parents For Parent List
         * @param {number} [fcFacilityId] 
         * @param {boolean} [endFlg] 
         * @param {string} [parentName] 
         * @param {string} [childName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentsForParentList: async (fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/parents/all/parent_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (endFlg !== undefined) {
                localVarQueryParameter['end_flg'] = endFlg;
            }

            if (parentName !== undefined) {
                localVarQueryParameter['parent_name'] = parentName;
            }

            if (childName !== undefined) {
                localVarQueryParameter['child_name'] = childName;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * すべての親の一覧件数を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parents For Parent List Count
         * @param {number} [fcFacilityId] 
         * @param {boolean} [endFlg] 
         * @param {string} [parentName] 
         * @param {string} [childName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentsForParentListCount: async (fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/parents/all/parent_list_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (endFlg !== undefined) {
                localVarQueryParameter['end_flg'] = endFlg;
            }

            if (parentName !== undefined) {
                localVarQueryParameter['parent_name'] = parentName;
            }

            if (childName !== undefined) {
                localVarQueryParameter['child_name'] = childName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 親の情報を登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Parent
         * @param {ParentDetailSchema} parentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParent: async (parentDetailSchema: ParentDetailSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentDetailSchema' is not null or undefined
            assertParamExists('putParent', 'parentDetailSchema', parentDetailSchema)
            const localVarPath = `/api/v1/web/parents/put_parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentDetailSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Parent Image
         * @param {number} parentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParentImage: async (parentId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('putParentImage', 'parentId', parentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putParentImage', 'file', file)
            const localVarPath = `/api/v1/web/parents/put_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parent_id'] = parentId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParentsApi - functional programming interface
 * @export
 */
export const ParentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParentsApiAxiosParamCreator(configuration)
    return {
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Create Parent Children
         * @param {ParentChildrenCreateSchema} parentChildrenCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParentChildren(parentChildrenCreateSchema: ParentChildrenCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParentChildren(parentChildrenCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 親の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Parent
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParent(parentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParent(parentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定の親の情報を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parent
         * @param {number} parentId 
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParent(parentId: number, fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentDetailSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParent(parentId, fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * すべての親の一覧を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parents For Parent List
         * @param {number} [fcFacilityId] 
         * @param {boolean} [endFlg] 
         * @param {string} [parentName] 
         * @param {string} [childName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentsForParentList(fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParentForParentListSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentsForParentList(fcFacilityId, endFlg, parentName, childName, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * すべての親の一覧件数を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parents For Parent List Count
         * @param {number} [fcFacilityId] 
         * @param {boolean} [endFlg] 
         * @param {string} [parentName] 
         * @param {string} [childName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentsForParentListCount(fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParentsForParentListCount(fcFacilityId, endFlg, parentName, childName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 親の情報を登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Parent
         * @param {ParentDetailSchema} parentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParent(parentDetailSchema: ParentDetailSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number | Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParent(parentDetailSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Parent Image
         * @param {number} parentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParentImage(parentId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParentImage(parentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParentsApi - factory interface
 * @export
 */
export const ParentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParentsApiFp(configuration)
    return {
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Create Parent Children
         * @param {ParentChildrenCreateSchema} parentChildrenCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParentChildren(parentChildrenCreateSchema: ParentChildrenCreateSchema, options?: any): AxiosPromise<Result> {
            return localVarFp.createParentChildren(parentChildrenCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 親の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Parent
         * @param {number} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParent(parentId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteParent(parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定の親の情報を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parent
         * @param {number} parentId 
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParent(parentId: number, fcFacilityId: number, options?: any): AxiosPromise<ParentDetailSchema> {
            return localVarFp.getParent(parentId, fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * すべての親の一覧を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parents For Parent List
         * @param {number} [fcFacilityId] 
         * @param {boolean} [endFlg] 
         * @param {string} [parentName] 
         * @param {string} [childName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentsForParentList(fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ParentForParentListSchema>> {
            return localVarFp.getParentsForParentList(fcFacilityId, endFlg, parentName, childName, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * すべての親の一覧件数を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Parents For Parent List Count
         * @param {number} [fcFacilityId] 
         * @param {boolean} [endFlg] 
         * @param {string} [parentName] 
         * @param {string} [childName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentsForParentListCount(fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getParentsForParentListCount(fcFacilityId, endFlg, parentName, childName, options).then((request) => request(axios, basePath));
        },
        /**
         * 親の情報を登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Parent
         * @param {ParentDetailSchema} parentDetailSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParent(parentDetailSchema: ParentDetailSchema, options?: any): AxiosPromise<number | Result> {
            return localVarFp.putParent(parentDetailSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Parent Image
         * @param {number} parentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParentImage(parentId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putParentImage(parentId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParentsApi - object-oriented interface
 * @export
 * @class ParentsApi
 * @extends {BaseAPI}
 */
export class ParentsApi extends BaseAPI {
    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Create Parent Children
     * @param {ParentChildrenCreateSchema} parentChildrenCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public createParentChildren(parentChildrenCreateSchema: ParentChildrenCreateSchema, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).createParentChildren(parentChildrenCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 親の情報を論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Parent
     * @param {number} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public deleteParent(parentId: number, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).deleteParent(parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特定の親の情報を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Parent
     * @param {number} parentId 
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getParent(parentId: number, fcFacilityId: number, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).getParent(parentId, fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * すべての親の一覧を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Parents For Parent List
     * @param {number} [fcFacilityId] 
     * @param {boolean} [endFlg] 
     * @param {string} [parentName] 
     * @param {string} [childName] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getParentsForParentList(fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).getParentsForParentList(fcFacilityId, endFlg, parentName, childName, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * すべての親の一覧件数を取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Parents For Parent List Count
     * @param {number} [fcFacilityId] 
     * @param {boolean} [endFlg] 
     * @param {string} [parentName] 
     * @param {string} [childName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public getParentsForParentListCount(fcFacilityId?: number, endFlg?: boolean, parentName?: string, childName?: string, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).getParentsForParentListCount(fcFacilityId, endFlg, parentName, childName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 親の情報を登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Parent
     * @param {ParentDetailSchema} parentDetailSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public putParent(parentDetailSchema: ParentDetailSchema, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).putParent(parentDetailSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Parent Image
     * @param {number} parentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentsApi
     */
    public putParentImage(parentId: number, file: any, options?: AxiosRequestConfig) {
        return ParentsApiFp(this.configuration).putParentImage(parentId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProgramItemsApi - axios parameter creator
 * @export
 */
export const ProgramItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * プログラム動画論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete P002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteP002: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteP002', 'id', id)
            const localVarPath = `/api/v1/web/program_items/program_items/delete_P002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete P002 Movie
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteP002Movie: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteP002Movie', 'id', id)
            const localVarPath = `/api/v1/web/program_items/program_items/delete_P002_movie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P001
         * @param {number} [step] 
         * @param {number} [reflection] 
         * @param {string} [programName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getP001: async (step?: number, reflection?: number, programName?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/program_items/program_items/get_P001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (reflection !== undefined) {
                localVarQueryParameter['reflection'] = reflection;
            }

            if (programName !== undefined) {
                localVarQueryParameter['program_name'] = programName;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラムレコード一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P001 Count
         * @param {number} [step] 
         * @param {number} [reflection] 
         * @param {string} [programName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getP001Count: async (step?: number, reflection?: number, programName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/program_items/program_items/get_P001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (reflection !== undefined) {
                localVarQueryParameter['reflection'] = reflection;
            }

            if (programName !== undefined) {
                localVarQueryParameter['program_name'] = programName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラムレコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getP002: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getP002', 'id', id)
            const localVarPath = `/api/v1/web/program_items/program_items/get_P002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Program Items For Select
         * @param {Array<number>} requestBody 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramItemsForSelect: async (requestBody: Array<number>, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getProgramItemsForSelect', 'requestBody', requestBody)
            const localVarPath = `/api/v1/web/program_items/program_items/get_items_for_select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラムレコード登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put P002
         * @param {P002Schema} p002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putP002: async (p002Schema: P002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'p002Schema' is not null or undefined
            assertParamExists('putP002', 'p002Schema', p002Schema)
            const localVarPath = `/api/v1/web/program_items/program_items/put_P002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(p002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put P002 Movie
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putP002Movie: async (id: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putP002Movie', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putP002Movie', 'file', file)
            const localVarPath = `/api/v1/web/program_items/program_items/put_P002_movie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProgramItemsApi - functional programming interface
 * @export
 */
export const ProgramItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProgramItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * プログラム動画論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete P002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteP002(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteP002(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete P002 Movie
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteP002Movie(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteP002Movie(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P001
         * @param {number} [step] 
         * @param {number} [reflection] 
         * @param {string} [programName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getP001(step?: number, reflection?: number, programName?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<P001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getP001(step, reflection, programName, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラムレコード一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P001 Count
         * @param {number} [step] 
         * @param {number} [reflection] 
         * @param {string} [programName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getP001Count(step?: number, reflection?: number, programName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getP001Count(step, reflection, programName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラムレコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getP002(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<P002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getP002(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Program Items For Select
         * @param {Array<number>} requestBody 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramItemsForSelect(requestBody: Array<number>, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProgramItemForSelectSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramItemsForSelect(requestBody, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラムレコード登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put P002
         * @param {P002Schema} p002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putP002(p002Schema: P002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putP002(p002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put P002 Movie
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putP002Movie(id: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putP002Movie(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProgramItemsApi - factory interface
 * @export
 */
export const ProgramItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProgramItemsApiFp(configuration)
    return {
        /**
         * プログラム動画論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete P002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteP002(id: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteP002(id, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete P002 Movie
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteP002Movie(id: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteP002Movie(id, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P001
         * @param {number} [step] 
         * @param {number} [reflection] 
         * @param {string} [programName] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getP001(step?: number, reflection?: number, programName?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<P001Schema>> {
            return localVarFp.getP001(step, reflection, programName, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラムレコード一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P001 Count
         * @param {number} [step] 
         * @param {number} [reflection] 
         * @param {string} [programName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getP001Count(step?: number, reflection?: number, programName?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getP001Count(step, reflection, programName, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラムレコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get P002
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getP002(id: number, options?: any): AxiosPromise<P002Schema> {
            return localVarFp.getP002(id, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Program Items For Select
         * @param {Array<number>} requestBody 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramItemsForSelect(requestBody: Array<number>, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ProgramItemForSelectSchema>> {
            return localVarFp.getProgramItemsForSelect(requestBody, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラムレコード登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put P002
         * @param {P002Schema} p002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putP002(p002Schema: P002Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putP002(p002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put P002 Movie
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putP002Movie(id: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putP002Movie(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProgramItemsApi - object-oriented interface
 * @export
 * @class ProgramItemsApi
 * @extends {BaseAPI}
 */
export class ProgramItemsApi extends BaseAPI {
    /**
     * プログラム動画論理削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete P002
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public deleteP002(id: number, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).deleteP002(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete P002 Movie
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public deleteP002Movie(id: number, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).deleteP002Movie(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get P001
     * @param {number} [step] 
     * @param {number} [reflection] 
     * @param {string} [programName] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public getP001(step?: number, reflection?: number, programName?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).getP001(step, reflection, programName, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラムレコード一覧件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get P001 Count
     * @param {number} [step] 
     * @param {number} [reflection] 
     * @param {string} [programName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public getP001Count(step?: number, reflection?: number, programName?: string, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).getP001Count(step, reflection, programName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラムレコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get P002
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public getP002(id: number, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).getP002(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラムレコード一覧取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Program Items For Select
     * @param {Array<number>} requestBody 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public getProgramItemsForSelect(requestBody: Array<number>, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).getProgramItemsForSelect(requestBody, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラムレコード登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put P002
     * @param {P002Schema} p002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public putP002(p002Schema: P002Schema, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).putP002(p002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プログラム動画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put P002 Movie
     * @param {number} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramItemsApi
     */
    public putP002Movie(id: number, file: any, options?: AxiosRequestConfig) {
        return ProgramItemsApiFp(this.configuration).putP002Movie(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScheduleApi - axios parameter creator
 * @export
 */
export const ScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 予約を承認する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Approve Reservations
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveReservations: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('approveReservations', 'requestBody', requestBody)
            const localVarPath = `/api/v1/web/schedules/schedule_details/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約をキャンセルする  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Cancel Reservations
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReservations: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('cancelReservations', 'requestBody', requestBody)
            const localVarPath = `/api/v1/web/schedules/schedule_details/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * FC IDに紐づく、今日以降の予約枠レコードを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get All Reservation Frames
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReservationFrames: async (fcFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getAllReservationFrames', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/schedules/get_all_reservation_frames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 日付、予約状況に紐づくレコードを取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K002
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [status] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK002: async (fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getK002', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/schedules/{fc_facility_id}/get_K002`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString().substr(0,10) :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 日付、予約状況に紐づくレコード件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K002 Count
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK002Count: async (fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('getK002Count', 'fcFacilityId', fcFacilityId)
            const localVarPath = `/api/v1/web/schedules/{fc_facility_id}/get_K002_count`
                .replace(`{${"fc_facility_id"}}`, encodeURIComponent(String(fcFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString().substr(0,10) :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約枠IDに紐づくレコードを取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get K005
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK005: async (scheduleReservationFrameId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleReservationFrameId' is not null or undefined
            assertParamExists('getK005', 'scheduleReservationFrameId', scheduleReservationFrameId)
            const localVarPath = `/api/v1/web/schedules/schedule_details/get_K005`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (scheduleReservationFrameId !== undefined) {
                localVarQueryParameter['schedule_reservation_frame_id'] = scheduleReservationFrameId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 当月の固定枠登録が済んでいないお子様の数を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Temporary Reservation Child Count
         * @param {number} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemporaryReservationChildCount: async (facilityId: number, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getTemporaryReservationChildCount', 'facilityId', facilityId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTemporaryReservationChildCount', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTemporaryReservationChildCount', 'endDate', endDate)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/getTemporaryReservationChildCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * K001weeklyより予約枠を作成または増枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Reservation Frame For K001 Weekly
         * @param {number} facilityId 
         * @param {string} date 
         * @param {string} startTimeStr 
         * @param {string} endTimeStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReservationFrameForK001Weekly: async (facilityId: number, date: string, startTimeStr: string, endTimeStr: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('putReservationFrameForK001Weekly', 'facilityId', facilityId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('putReservationFrameForK001Weekly', 'date', date)
            // verify required parameter 'startTimeStr' is not null or undefined
            assertParamExists('putReservationFrameForK001Weekly', 'startTimeStr', startTimeStr)
            // verify required parameter 'endTimeStr' is not null or undefined
            assertParamExists('putReservationFrameForK001Weekly', 'endTimeStr', endTimeStr)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/putReservationFrameForK001Weekly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (startTimeStr !== undefined) {
                localVarQueryParameter['start_time_str'] = startTimeStr;
            }

            if (endTimeStr !== undefined) {
                localVarQueryParameter['end_time_str'] = endTimeStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 固定枠に沿って予約枠を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Reservation Frame On Fixed Time Slot
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReservationFrameOnFixedTimeSlot: async (fcFacilityId: number, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('putReservationFrameOnFixedTimeSlot', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('putReservationFrameOnFixedTimeSlot', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('putReservationFrameOnFixedTimeSlot', 'month', month)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/putReservationFrameOnFixedTimeSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約枠レコード登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Service Reservation Frame
         * @param {number} fcFacilityId 
         * @param {Array<SRFCreateSchema>} sRFCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServiceReservationFrame: async (fcFacilityId: number, sRFCreateSchema: Array<SRFCreateSchema>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('putServiceReservationFrame', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'sRFCreateSchema' is not null or undefined
            assertParamExists('putServiceReservationFrame', 'sRFCreateSchema', sRFCreateSchema)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/putServiceReservationFrame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sRFCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * K001weeklyより予約枠を減枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Reduce Reservation Frame For K001 Weekly
         * @param {number} scheduleReservationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reduceReservationFrameForK001Weekly: async (scheduleReservationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleReservationId' is not null or undefined
            assertParamExists('reduceReservationFrameForK001Weekly', 'scheduleReservationId', scheduleReservationId)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/reduceReservationFrameForK001Weekly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (scheduleReservationId !== undefined) {
                localVarQueryParameter['schedule_reservation_id'] = scheduleReservationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スケジュールの予約枠を更新する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Reservation Frame
         * @param {number} reservationFrameId 
         * @param {number} reservationFrameNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservationFrame: async (reservationFrameId: number, reservationFrameNum: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationFrameId' is not null or undefined
            assertParamExists('updateReservationFrame', 'reservationFrameId', reservationFrameId)
            // verify required parameter 'reservationFrameNum' is not null or undefined
            assertParamExists('updateReservationFrame', 'reservationFrameNum', reservationFrameNum)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/{reservation_frame_id}`
                .replace(`{${"reservation_frame_id"}}`, encodeURIComponent(String(reservationFrameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (reservationFrameNum !== undefined) {
                localVarQueryParameter['reservation_frame_num'] = reservationFrameNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した期間内の未通知の空枠を保護者に通知する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Srf Notified
         * @param {number} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSRFNotified: async (facilityId: number, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('updateSRFNotified', 'facilityId', facilityId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('updateSRFNotified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('updateSRFNotified', 'endDate', endDate)
            const localVarPath = `/api/v1/web/schedules/reservation_frame/updateSRFNotified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityId !== undefined) {
                localVarQueryParameter['facility_id'] = facilityId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduleApi - functional programming interface
 * @export
 */
export const ScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 予約を承認する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Approve Reservations
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveReservations(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveReservations(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約をキャンセルする  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Cancel Reservations
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReservations(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReservations(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * FC IDに紐づく、今日以降の予約枠レコードを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get All Reservation Frames
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReservationFrames(fcFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SRFBaseSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReservationFrames(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 日付、予約状況に紐づくレコードを取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K002
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [status] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getK002(fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SRFSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getK002(fcFacilityId, beginDate, endDate, status, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 日付、予約状況に紐づくレコード件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K002 Count
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getK002Count(fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getK002Count(fcFacilityId, beginDate, endDate, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約枠IDに紐づくレコードを取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get K005
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getK005(scheduleReservationFrameId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<K005Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getK005(scheduleReservationFrameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 当月の固定枠登録が済んでいないお子様の数を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Temporary Reservation Child Count
         * @param {number} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemporaryReservationChildCount(facilityId: number, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemporaryReservationChildCount(facilityId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * K001weeklyより予約枠を作成または増枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Reservation Frame For K001 Weekly
         * @param {number} facilityId 
         * @param {string} date 
         * @param {string} startTimeStr 
         * @param {string} endTimeStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReservationFrameForK001Weekly(facilityId: number, date: string, startTimeStr: string, endTimeStr: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReservationFrameForK001Weekly(facilityId, date, startTimeStr, endTimeStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 固定枠に沿って予約枠を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Reservation Frame On Fixed Time Slot
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReservationFrameOnFixedTimeSlot(fcFacilityId: number, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReservationFrameOnFixedTimeSlot(fcFacilityId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約枠レコード登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Service Reservation Frame
         * @param {number} fcFacilityId 
         * @param {Array<SRFCreateSchema>} sRFCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putServiceReservationFrame(fcFacilityId: number, sRFCreateSchema: Array<SRFCreateSchema>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putServiceReservationFrame(fcFacilityId, sRFCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * K001weeklyより予約枠を減枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Reduce Reservation Frame For K001 Weekly
         * @param {number} scheduleReservationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reduceReservationFrameForK001Weekly(scheduleReservationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reduceReservationFrameForK001Weekly(scheduleReservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スケジュールの予約枠を更新する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Reservation Frame
         * @param {number} reservationFrameId 
         * @param {number} reservationFrameNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReservationFrame(reservationFrameId: number, reservationFrameNum: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReservationFrame(reservationFrameId, reservationFrameNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した期間内の未通知の空枠を保護者に通知する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Srf Notified
         * @param {number} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSRFNotified(facilityId: number, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSRFNotified(facilityId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScheduleApi - factory interface
 * @export
 */
export const ScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScheduleApiFp(configuration)
    return {
        /**
         * 予約を承認する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Approve Reservations
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveReservations(requestBody: Array<number>, options?: any): AxiosPromise<Result> {
            return localVarFp.approveReservations(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約をキャンセルする  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Cancel Reservations
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReservations(requestBody: Array<number>, options?: any): AxiosPromise<Result> {
            return localVarFp.cancelReservations(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * FC IDに紐づく、今日以降の予約枠レコードを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get All Reservation Frames
         * @param {number} fcFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReservationFrames(fcFacilityId: number, options?: any): AxiosPromise<Array<SRFBaseSchema>> {
            return localVarFp.getAllReservationFrames(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 日付、予約状況に紐づくレコードを取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K002
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [status] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK002(fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<SRFSchema>> {
            return localVarFp.getK002(fcFacilityId, beginDate, endDate, status, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 日付、予約状況に紐づくレコード件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get K002 Count
         * @param {number} fcFacilityId 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK002Count(fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, options?: any): AxiosPromise<number> {
            return localVarFp.getK002Count(fcFacilityId, beginDate, endDate, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約枠IDに紐づくレコードを取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get K005
         * @param {number} scheduleReservationFrameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getK005(scheduleReservationFrameId: number, options?: any): AxiosPromise<K005Schema> {
            return localVarFp.getK005(scheduleReservationFrameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 当月の固定枠登録が済んでいないお子様の数を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Temporary Reservation Child Count
         * @param {number} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemporaryReservationChildCount(facilityId: number, startDate: string, endDate: string, options?: any): AxiosPromise<number> {
            return localVarFp.getTemporaryReservationChildCount(facilityId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * K001weeklyより予約枠を作成または増枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Reservation Frame For K001 Weekly
         * @param {number} facilityId 
         * @param {string} date 
         * @param {string} startTimeStr 
         * @param {string} endTimeStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReservationFrameForK001Weekly(facilityId: number, date: string, startTimeStr: string, endTimeStr: string, options?: any): AxiosPromise<Result> {
            return localVarFp.putReservationFrameForK001Weekly(facilityId, date, startTimeStr, endTimeStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 固定枠に沿って予約枠を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Reservation Frame On Fixed Time Slot
         * @param {number} fcFacilityId 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReservationFrameOnFixedTimeSlot(fcFacilityId: number, year: number, month: number, options?: any): AxiosPromise<Result> {
            return localVarFp.putReservationFrameOnFixedTimeSlot(fcFacilityId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約枠レコード登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put Service Reservation Frame
         * @param {number} fcFacilityId 
         * @param {Array<SRFCreateSchema>} sRFCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServiceReservationFrame(fcFacilityId: number, sRFCreateSchema: Array<SRFCreateSchema>, options?: any): AxiosPromise<Result> {
            return localVarFp.putServiceReservationFrame(fcFacilityId, sRFCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * K001weeklyより予約枠を減枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Reduce Reservation Frame For K001 Weekly
         * @param {number} scheduleReservationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reduceReservationFrameForK001Weekly(scheduleReservationId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.reduceReservationFrameForK001Weekly(scheduleReservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * スケジュールの予約枠を更新する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Reservation Frame
         * @param {number} reservationFrameId 
         * @param {number} reservationFrameNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservationFrame(reservationFrameId: number, reservationFrameNum: number, options?: any): AxiosPromise<Result> {
            return localVarFp.updateReservationFrame(reservationFrameId, reservationFrameNum, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した期間内の未通知の空枠を保護者に通知する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Update Srf Notified
         * @param {number} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSRFNotified(facilityId: number, startDate: string, endDate: string, options?: any): AxiosPromise<Result> {
            return localVarFp.updateSRFNotified(facilityId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScheduleApi - object-oriented interface
 * @export
 * @class ScheduleApi
 * @extends {BaseAPI}
 */
export class ScheduleApi extends BaseAPI {
    /**
     * 予約を承認する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Approve Reservations
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public approveReservations(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).approveReservations(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約をキャンセルする  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Cancel Reservations
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public cancelReservations(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).cancelReservations(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * FC IDに紐づく、今日以降の予約枠レコードを取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get All Reservation Frames
     * @param {number} fcFacilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getAllReservationFrames(fcFacilityId: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getAllReservationFrames(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 日付、予約状況に紐づくレコードを取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get K002
     * @param {number} fcFacilityId 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [status] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getK002(fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getK002(fcFacilityId, beginDate, endDate, status, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 日付、予約状況に紐づくレコード件数を取得する   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get K002 Count
     * @param {number} fcFacilityId 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getK002Count(fcFacilityId: number, beginDate?: string, endDate?: string, status?: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getK002Count(fcFacilityId, beginDate, endDate, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約枠IDに紐づくレコードを取得する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get K005
     * @param {number} scheduleReservationFrameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getK005(scheduleReservationFrameId: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getK005(scheduleReservationFrameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 当月の固定枠登録が済んでいないお子様の数を取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Temporary Reservation Child Count
     * @param {number} facilityId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public getTemporaryReservationChildCount(facilityId: number, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).getTemporaryReservationChildCount(facilityId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * K001weeklyより予約枠を作成または増枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Reservation Frame For K001 Weekly
     * @param {number} facilityId 
     * @param {string} date 
     * @param {string} startTimeStr 
     * @param {string} endTimeStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public putReservationFrameForK001Weekly(facilityId: number, date: string, startTimeStr: string, endTimeStr: string, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).putReservationFrameForK001Weekly(facilityId, date, startTimeStr, endTimeStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 固定枠に沿って予約枠を登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Put Reservation Frame On Fixed Time Slot
     * @param {number} fcFacilityId 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public putReservationFrameOnFixedTimeSlot(fcFacilityId: number, year: number, month: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).putReservationFrameOnFixedTimeSlot(fcFacilityId, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約枠レコード登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Put Service Reservation Frame
     * @param {number} fcFacilityId 
     * @param {Array<SRFCreateSchema>} sRFCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public putServiceReservationFrame(fcFacilityId: number, sRFCreateSchema: Array<SRFCreateSchema>, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).putServiceReservationFrame(fcFacilityId, sRFCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * K001weeklyより予約枠を減枠  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Reduce Reservation Frame For K001 Weekly
     * @param {number} scheduleReservationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public reduceReservationFrameForK001Weekly(scheduleReservationId: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).reduceReservationFrameForK001Weekly(scheduleReservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スケジュールの予約枠を更新する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Update Reservation Frame
     * @param {number} reservationFrameId 
     * @param {number} reservationFrameNum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public updateReservationFrame(reservationFrameId: number, reservationFrameNum: number, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).updateReservationFrame(reservationFrameId, reservationFrameNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した期間内の未通知の空枠を保護者に通知する  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Update Srf Notified
     * @param {number} facilityId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    public updateSRFNotified(facilityId: number, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return ScheduleApiFp(this.configuration).updateSRFNotified(facilityId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SignatureRequestsApi - axios parameter creator
 * @export
 */
export const SignatureRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 署名依頼帳削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteW002: async (signatureRequestMakeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signatureRequestMakeId' is not null or undefined
            assertParamExists('deleteW002', 'signatureRequestMakeId', signatureRequestMakeId)
            const localVarPath = `/api/v1/web/signature_requests/delete_W002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (signatureRequestMakeId !== undefined) {
                localVarQueryParameter['signature_request_make_id'] = signatureRequestMakeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼帳票一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get W001
         * @param {number} [fcFacilityId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW001: async (fcFacilityId?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/signature_requests/get_W001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼帳票一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get W001 Count
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW001Count: async (fcFacilityId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/signature_requests/get_W001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼帳票詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW002: async (signatureRequestMakeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signatureRequestMakeId' is not null or undefined
            assertParamExists('getW002', 'signatureRequestMakeId', signatureRequestMakeId)
            const localVarPath = `/api/v1/web/signature_requests/get_W002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (signatureRequestMakeId !== undefined) {
                localVarQueryParameter['signature_request_make_id'] = signatureRequestMakeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼帳再通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Notify W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyW002: async (signatureRequestMakeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signatureRequestMakeId' is not null or undefined
            assertParamExists('notifyW002', 'signatureRequestMakeId', signatureRequestMakeId)
            const localVarPath = `/api/v1/web/signature_requests/notify_W002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (signatureRequestMakeId !== undefined) {
                localVarQueryParameter['signature_request_make_id'] = signatureRequestMakeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 署名依頼帳票詳細登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put W002
         * @param {number} fcFacilityId 
         * @param {number} docType 
         * @param {string} publicationDate 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putW002: async (fcFacilityId: number, docType: number, publicationDate: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fcFacilityId' is not null or undefined
            assertParamExists('putW002', 'fcFacilityId', fcFacilityId)
            // verify required parameter 'docType' is not null or undefined
            assertParamExists('putW002', 'docType', docType)
            // verify required parameter 'publicationDate' is not null or undefined
            assertParamExists('putW002', 'publicationDate', publicationDate)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putW002', 'file', file)
            const localVarPath = `/api/v1/web/signature_requests/put_W002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (docType !== undefined) {
                localVarQueryParameter['doc_type'] = docType;
            }

            if (publicationDate !== undefined) {
                localVarQueryParameter['publication_date'] = publicationDate;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignatureRequestsApi - functional programming interface
 * @export
 */
export const SignatureRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignatureRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 署名依頼帳削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteW002(signatureRequestMakeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteW002(signatureRequestMakeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼帳票一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get W001
         * @param {number} [fcFacilityId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getW001(fcFacilityId?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<W001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getW001(fcFacilityId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼帳票一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get W001 Count
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getW001Count(fcFacilityId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getW001Count(fcFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼帳票詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getW002(signatureRequestMakeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<W002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getW002(signatureRequestMakeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼帳再通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Notify W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyW002(signatureRequestMakeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyW002(signatureRequestMakeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 署名依頼帳票詳細登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put W002
         * @param {number} fcFacilityId 
         * @param {number} docType 
         * @param {string} publicationDate 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putW002(fcFacilityId: number, docType: number, publicationDate: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putW002(fcFacilityId, docType, publicationDate, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SignatureRequestsApi - factory interface
 * @export
 */
export const SignatureRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignatureRequestsApiFp(configuration)
    return {
        /**
         * 署名依頼帳削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteW002(signatureRequestMakeId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteW002(signatureRequestMakeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼帳票一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get W001
         * @param {number} [fcFacilityId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW001(fcFacilityId?: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<W001Schema>> {
            return localVarFp.getW001(fcFacilityId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼帳票一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get W001 Count
         * @param {number} [fcFacilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW001Count(fcFacilityId?: number, options?: any): AxiosPromise<number> {
            return localVarFp.getW001Count(fcFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼帳票詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getW002(signatureRequestMakeId: number, options?: any): AxiosPromise<W002Schema> {
            return localVarFp.getW002(signatureRequestMakeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼帳再通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Notify W002
         * @param {number} signatureRequestMakeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyW002(signatureRequestMakeId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.notifyW002(signatureRequestMakeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 署名依頼帳票詳細登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Put W002
         * @param {number} fcFacilityId 
         * @param {number} docType 
         * @param {string} publicationDate 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putW002(fcFacilityId: number, docType: number, publicationDate: string, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putW002(fcFacilityId, docType, publicationDate, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignatureRequestsApi - object-oriented interface
 * @export
 * @class SignatureRequestsApi
 * @extends {BaseAPI}
 */
export class SignatureRequestsApi extends BaseAPI {
    /**
     * 署名依頼帳削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete W002
     * @param {number} signatureRequestMakeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureRequestsApi
     */
    public deleteW002(signatureRequestMakeId: number, options?: AxiosRequestConfig) {
        return SignatureRequestsApiFp(this.configuration).deleteW002(signatureRequestMakeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼帳票一覧取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get W001
     * @param {number} [fcFacilityId] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureRequestsApi
     */
    public getW001(fcFacilityId?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return SignatureRequestsApiFp(this.configuration).getW001(fcFacilityId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼帳票一覧件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get W001 Count
     * @param {number} [fcFacilityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureRequestsApi
     */
    public getW001Count(fcFacilityId?: number, options?: AxiosRequestConfig) {
        return SignatureRequestsApiFp(this.configuration).getW001Count(fcFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼帳票詳細取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get W002
     * @param {number} signatureRequestMakeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureRequestsApi
     */
    public getW002(signatureRequestMakeId: number, options?: AxiosRequestConfig) {
        return SignatureRequestsApiFp(this.configuration).getW002(signatureRequestMakeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼帳再通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Notify W002
     * @param {number} signatureRequestMakeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureRequestsApi
     */
    public notifyW002(signatureRequestMakeId: number, options?: AxiosRequestConfig) {
        return SignatureRequestsApiFp(this.configuration).notifyW002(signatureRequestMakeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 署名依頼帳票詳細登録   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Put W002
     * @param {number} fcFacilityId 
     * @param {number} docType 
     * @param {string} publicationDate 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureRequestsApi
     */
    public putW002(fcFacilityId: number, docType: number, publicationDate: string, file: any, options?: AxiosRequestConfig) {
        return SignatureRequestsApiFp(this.configuration).putW002(fcFacilityId, docType, publicationDate, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplatesApi - axios parameter creator
 * @export
 */
export const TemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * テンプレートファイルURL取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Template
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (fileType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('getTemplate', 'fileType', fileType)
            const localVarPath = `/api/v1/web/templates/get_template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テンプレートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Template
         * @param {number} fileType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplate: async (fileType: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('putTemplate', 'fileType', fileType)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putTemplate', 'file', file)
            const localVarPath = `/api/v1/web/templates/put_template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * テンプレートファイルURL取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Template
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(fileType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(fileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テンプレートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Template
         * @param {number} fileType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTemplate(fileType: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTemplate(fileType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplatesApiFp(configuration)
    return {
        /**
         * テンプレートファイルURL取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Template
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(fileType: number, options?: any): AxiosPromise<string> {
            return localVarFp.getTemplate(fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * テンプレートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Template
         * @param {number} fileType 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplate(fileType: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putTemplate(fileType, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
    /**
     * テンプレートファイルURL取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Template
     * @param {number} fileType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public getTemplate(fileType: number, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).getTemplate(fileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テンプレートファイル登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Template
     * @param {number} fileType 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public putTemplate(fileType: number, file: any, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).putTemplate(fileType, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *     施設、保護者、お子様の大量追加      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Facility Parent Children
         * @param {number} facilityNum 
         * @param {number} parentNum 
         * @param {number} childrenNum 
         * @param {number} [startNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFacilityParentChildren: async (facilityNum: number, parentNum: number, childrenNum: number, startNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityNum' is not null or undefined
            assertParamExists('addFacilityParentChildren', 'facilityNum', facilityNum)
            // verify required parameter 'parentNum' is not null or undefined
            assertParamExists('addFacilityParentChildren', 'parentNum', parentNum)
            // verify required parameter 'childrenNum' is not null or undefined
            assertParamExists('addFacilityParentChildren', 'childrenNum', childrenNum)
            const localVarPath = `/api/v1/test/test/add_facility_parent_children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (facilityNum !== undefined) {
                localVarQueryParameter['facility_num'] = facilityNum;
            }

            if (parentNum !== undefined) {
                localVarQueryParameter['parent_num'] = parentNum;
            }

            if (childrenNum !== undefined) {
                localVarQueryParameter['children_num'] = childrenNum;
            }

            if (startNum !== undefined) {
                localVarQueryParameter['start_num'] = startNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デバイストークン登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Mobile Device Token
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMobileDeviceToken: async (deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('addMobileDeviceToken', 'deviceToken', deviceToken)
            const localVarPath = `/api/v1/test/test/add_mobile_device_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (deviceToken !== undefined) {
                localVarQueryParameter['device_token'] = deviceToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     本部から施設への送信      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add N Send Notifications
         * @param {string} title 
         * @param {number} num 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNSendNotifications: async (title: string, num: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('addNSendNotifications', 'title', title)
            // verify required parameter 'num' is not null or undefined
            assertParamExists('addNSendNotifications', 'num', num)
            const localVarPath = `/api/v1/test/test/add_n_send_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約枠作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Schedule Reservation Frame
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} num 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScheduleReservationFrame: async (startDate: string, endDate: string, num: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('addScheduleReservationFrame', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('addScheduleReservationFrame', 'endDate', endDate)
            // verify required parameter 'num' is not null or undefined
            assertParamExists('addScheduleReservationFrame', 'num', num)
            const localVarPath = `/api/v1/test/test/add_schedule_reservation_frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スタッフのいない施設にスタッフを登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Staff At Empty Fc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStaffAtEmptyFc: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/add_staff_at_empty_fc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 負荷テスト用にchild_idからトレーニングを登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Training
         * @param {number} childId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTraining: async (childId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('addTraining', 'childId', childId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('addTraining', 'date', date)
            const localVarPath = `/api/v1/test/test/add_training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お子様のトレーニングと、署名を追加  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Training Children
         * @param {number} childIdStart 
         * @param {number} childIdEnd 
         * @param {string} dateStart 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrainingChildren: async (childIdStart: number, childIdEnd: number, dateStart: string, startTime: string, endTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childIdStart' is not null or undefined
            assertParamExists('addTrainingChildren', 'childIdStart', childIdStart)
            // verify required parameter 'childIdEnd' is not null or undefined
            assertParamExists('addTrainingChildren', 'childIdEnd', childIdEnd)
            // verify required parameter 'dateStart' is not null or undefined
            assertParamExists('addTrainingChildren', 'dateStart', dateStart)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('addTrainingChildren', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('addTrainingChildren', 'endTime', endTime)
            const localVarPath = `/api/v1/test/test/add_training_children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childIdStart !== undefined) {
                localVarQueryParameter['child_id_start'] = childIdStart;
            }

            if (childIdEnd !== undefined) {
                localVarQueryParameter['child_id_end'] = childIdEnd;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['date_start'] = dateStart;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アップロードファイル追加  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Upload
         * @param {number} num 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpload: async (num: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'num' is not null or undefined
            assertParamExists('addUpload', 'num', num)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addUpload', 'file', file)
            const localVarPath = `/api/v1/test/test/add_upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 翌日のトレーニングを通知する ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Notify Tomorrow Training
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyTomorrowTraining: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/notify_tomorrow_training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開イベントに予約を1件入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Each Event One
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEachEventOne: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/put_each_event_one`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     トレーニングの署名を保存      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Save Training Result Sign
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSign: async (childId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('saveSign', 'childId', childId)
            const localVarPath = `/api/v1/test/test/save_sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約枠の埋まっていない枠に1件予約を入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Schedule Reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleReservation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/schedule_reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 重要事項説明書の変更同意書へのサイン  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Change Consent Form For Important Information Manual
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signChangeConsentFormForImportantInformationManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/sign_change_consent_form_for_important_information_manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Individual Support Plan
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIndividualSupportPlan: async (individualSupportPlanId: number, fileType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'individualSupportPlanId' is not null or undefined
            assertParamExists('signIndividualSupportPlan', 'individualSupportPlanId', individualSupportPlanId)
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('signIndividualSupportPlan', 'fileType', fileType)
            const localVarPath = `/api/v1/test/test/sign_individual_support_plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (individualSupportPlanId !== undefined) {
                localVarQueryParameter['individual_support_plan_id'] = individualSupportPlanId;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Individual Support Plan All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIndividualSupportPlanAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/sign_individual_support_plan_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サイン依頼済みのトレーニング結果にサイン実行  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Training Result For All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signTrainingResultForAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/sign_training_result_for_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 重要事項説明書変更同意書  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Change Consent Form For Important Information Manual
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeChangeConsentFormForImportantInformationManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/test/write_change_consent_form_for_important_information_manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 児童発達支援提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Child Development Support Provision Record Sheet
         * @param {number} provisionRecordSheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeChildDevelopmentSupportProvisionRecordSheet: async (provisionRecordSheetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provisionRecordSheetId' is not null or undefined
            assertParamExists('writeChildDevelopmentSupportProvisionRecordSheet', 'provisionRecordSheetId', provisionRecordSheetId)
            const localVarPath = `/api/v1/test/test/write_child_development_support_provision_record_sheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (provisionRecordSheetId !== undefined) {
                localVarQueryParameter['provision_record_sheet_id'] = provisionRecordSheetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 放課後等デイサービス提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Day Service Record Sheet
         * @param {number} provisionRecordSheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDayServiceRecordSheet: async (provisionRecordSheetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provisionRecordSheetId' is not null or undefined
            assertParamExists('writeDayServiceRecordSheet', 'provisionRecordSheetId', provisionRecordSheetId)
            const localVarPath = `/api/v1/test/test/write_day_service_record_sheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (provisionRecordSheetId !== undefined) {
                localVarQueryParameter['provision_record_sheet_id'] = provisionRecordSheetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング結果作成テスト  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Training Result
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeTrainingResult: async (trainingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('writeTrainingResult', 'trainingId', trainingId)
            const localVarPath = `/api/v1/test/test/write_training_result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (trainingId !== undefined) {
                localVarQueryParameter['training_id'] = trainingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         *     施設、保護者、お子様の大量追加      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Facility Parent Children
         * @param {number} facilityNum 
         * @param {number} parentNum 
         * @param {number} childrenNum 
         * @param {number} [startNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFacilityParentChildren(facilityNum: number, parentNum: number, childrenNum: number, startNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFacilityParentChildren(facilityNum, parentNum, childrenNum, startNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デバイストークン登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Mobile Device Token
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMobileDeviceToken(deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMobileDeviceToken(deviceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *     本部から施設への送信      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add N Send Notifications
         * @param {string} title 
         * @param {number} num 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNSendNotifications(title: string, num: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNSendNotifications(title, num, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約枠作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Schedule Reservation Frame
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} num 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addScheduleReservationFrame(startDate: string, endDate: string, num: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addScheduleReservationFrame(startDate, endDate, num, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スタッフのいない施設にスタッフを登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Staff At Empty Fc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStaffAtEmptyFc(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStaffAtEmptyFc(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 負荷テスト用にchild_idからトレーニングを登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Training
         * @param {number} childId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTraining(childId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTraining(childId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お子様のトレーニングと、署名を追加  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Training Children
         * @param {number} childIdStart 
         * @param {number} childIdEnd 
         * @param {string} dateStart 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTrainingChildren(childIdStart: number, childIdEnd: number, dateStart: string, startTime: string, endTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTrainingChildren(childIdStart, childIdEnd, dateStart, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アップロードファイル追加  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Upload
         * @param {number} num 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUpload(num: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUpload(num, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 翌日のトレーニングを通知する ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Notify Tomorrow Training
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyTomorrowTraining(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyTomorrowTraining(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開イベントに予約を1件入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Each Event One
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEachEventOne(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEachEventOne(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *     トレーニングの署名を保存      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Save Training Result Sign
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSign(childId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSign(childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約枠の埋まっていない枠に1件予約を入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Schedule Reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleReservation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleReservation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 重要事項説明書の変更同意書へのサイン  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Change Consent Form For Important Information Manual
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signChangeConsentFormForImportantInformationManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signChangeConsentFormForImportantInformationManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Individual Support Plan
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIndividualSupportPlan(individualSupportPlanId: number, fileType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIndividualSupportPlan(individualSupportPlanId, fileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Individual Support Plan All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIndividualSupportPlanAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIndividualSupportPlanAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サイン依頼済みのトレーニング結果にサイン実行  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Training Result For All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signTrainingResultForAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signTrainingResultForAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 重要事項説明書変更同意書  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Change Consent Form For Important Information Manual
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeChangeConsentFormForImportantInformationManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeChangeConsentFormForImportantInformationManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 児童発達支援提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Child Development Support Provision Record Sheet
         * @param {number} provisionRecordSheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeChildDevelopmentSupportProvisionRecordSheet(provisionRecordSheetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeChildDevelopmentSupportProvisionRecordSheet(provisionRecordSheetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 放課後等デイサービス提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Day Service Record Sheet
         * @param {number} provisionRecordSheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeDayServiceRecordSheet(provisionRecordSheetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeDayServiceRecordSheet(provisionRecordSheetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング結果作成テスト  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Training Result
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeTrainingResult(trainingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeTrainingResult(trainingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         *     施設、保護者、お子様の大量追加      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Facility Parent Children
         * @param {number} facilityNum 
         * @param {number} parentNum 
         * @param {number} childrenNum 
         * @param {number} [startNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFacilityParentChildren(facilityNum: number, parentNum: number, childrenNum: number, startNum?: number, options?: any): AxiosPromise<any> {
            return localVarFp.addFacilityParentChildren(facilityNum, parentNum, childrenNum, startNum, options).then((request) => request(axios, basePath));
        },
        /**
         * デバイストークン登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Mobile Device Token
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMobileDeviceToken(deviceToken: string, options?: any): AxiosPromise<any> {
            return localVarFp.addMobileDeviceToken(deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         *     本部から施設への送信      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add N Send Notifications
         * @param {string} title 
         * @param {number} num 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNSendNotifications(title: string, num: number, options?: any): AxiosPromise<any> {
            return localVarFp.addNSendNotifications(title, num, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約枠作成  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Schedule Reservation Frame
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} num 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScheduleReservationFrame(startDate: string, endDate: string, num: number, options?: any): AxiosPromise<any> {
            return localVarFp.addScheduleReservationFrame(startDate, endDate, num, options).then((request) => request(axios, basePath));
        },
        /**
         * スタッフのいない施設にスタッフを登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Staff At Empty Fc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStaffAtEmptyFc(options?: any): AxiosPromise<any> {
            return localVarFp.addStaffAtEmptyFc(options).then((request) => request(axios, basePath));
        },
        /**
         * 負荷テスト用にchild_idからトレーニングを登録  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Training
         * @param {number} childId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTraining(childId: number, date: string, options?: any): AxiosPromise<any> {
            return localVarFp.addTraining(childId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * お子様のトレーニングと、署名を追加  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Training Children
         * @param {number} childIdStart 
         * @param {number} childIdEnd 
         * @param {string} dateStart 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrainingChildren(childIdStart: number, childIdEnd: number, dateStart: string, startTime: string, endTime: string, options?: any): AxiosPromise<any> {
            return localVarFp.addTrainingChildren(childIdStart, childIdEnd, dateStart, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * アップロードファイル追加  ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Add Upload
         * @param {number} num 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpload(num: number, file: any, options?: any): AxiosPromise<any> {
            return localVarFp.addUpload(num, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 翌日のトレーニングを通知する ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Notify Tomorrow Training
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyTomorrowTraining(options?: any): AxiosPromise<any> {
            return localVarFp.notifyTomorrowTraining(options).then((request) => request(axios, basePath));
        },
        /**
         * 公開イベントに予約を1件入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Each Event One
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEachEventOne(options?: any): AxiosPromise<Result> {
            return localVarFp.putEachEventOne(options).then((request) => request(axios, basePath));
        },
        /**
         *     トレーニングの署名を保存      ## PERMISSION 本部ユーザーのみ実行可能
         * @summary Save Training Result Sign
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSign(childId: number, options?: any): AxiosPromise<any> {
            return localVarFp.saveSign(childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約枠の埋まっていない枠に1件予約を入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Schedule Reservation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleReservation(options?: any): AxiosPromise<Result> {
            return localVarFp.scheduleReservation(options).then((request) => request(axios, basePath));
        },
        /**
         * 重要事項説明書の変更同意書へのサイン  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Change Consent Form For Important Information Manual
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signChangeConsentFormForImportantInformationManual(options?: any): AxiosPromise<Result> {
            return localVarFp.signChangeConsentFormForImportantInformationManual(options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Individual Support Plan
         * @param {number} individualSupportPlanId 
         * @param {number} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIndividualSupportPlan(individualSupportPlanId: number, fileType: number, options?: any): AxiosPromise<any> {
            return localVarFp.signIndividualSupportPlan(individualSupportPlanId, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Individual Support Plan All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIndividualSupportPlanAll(options?: any): AxiosPromise<Result> {
            return localVarFp.signIndividualSupportPlanAll(options).then((request) => request(axios, basePath));
        },
        /**
         * サイン依頼済みのトレーニング結果にサイン実行  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Sign Training Result For All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signTrainingResultForAll(options?: any): AxiosPromise<Result> {
            return localVarFp.signTrainingResultForAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 重要事項説明書変更同意書  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Change Consent Form For Important Information Manual
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeChangeConsentFormForImportantInformationManual(options?: any): AxiosPromise<any> {
            return localVarFp.writeChangeConsentFormForImportantInformationManual(options).then((request) => request(axios, basePath));
        },
        /**
         * 児童発達支援提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Child Development Support Provision Record Sheet
         * @param {number} provisionRecordSheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeChildDevelopmentSupportProvisionRecordSheet(provisionRecordSheetId: number, options?: any): AxiosPromise<any> {
            return localVarFp.writeChildDevelopmentSupportProvisionRecordSheet(provisionRecordSheetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 放課後等デイサービス提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Day Service Record Sheet
         * @param {number} provisionRecordSheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDayServiceRecordSheet(provisionRecordSheetId: number, options?: any): AxiosPromise<any> {
            return localVarFp.writeDayServiceRecordSheet(provisionRecordSheetId, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング結果作成テスト  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Write Training Result
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeTrainingResult(trainingId: number, options?: any): AxiosPromise<any> {
            return localVarFp.writeTrainingResult(trainingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     *     施設、保護者、お子様の大量追加      ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Facility Parent Children
     * @param {number} facilityNum 
     * @param {number} parentNum 
     * @param {number} childrenNum 
     * @param {number} [startNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addFacilityParentChildren(facilityNum: number, parentNum: number, childrenNum: number, startNum?: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addFacilityParentChildren(facilityNum, parentNum, childrenNum, startNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * デバイストークン登録  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Mobile Device Token
     * @param {string} deviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addMobileDeviceToken(deviceToken: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addMobileDeviceToken(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     本部から施設への送信      ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add N Send Notifications
     * @param {string} title 
     * @param {number} num 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addNSendNotifications(title: string, num: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addNSendNotifications(title, num, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約枠作成  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Schedule Reservation Frame
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} num 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addScheduleReservationFrame(startDate: string, endDate: string, num: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addScheduleReservationFrame(startDate, endDate, num, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スタッフのいない施設にスタッフを登録  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Staff At Empty Fc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addStaffAtEmptyFc(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addStaffAtEmptyFc(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 負荷テスト用にchild_idからトレーニングを登録  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Training
     * @param {number} childId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addTraining(childId: number, date: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addTraining(childId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お子様のトレーニングと、署名を追加  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Training Children
     * @param {number} childIdStart 
     * @param {number} childIdEnd 
     * @param {string} dateStart 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addTrainingChildren(childIdStart: number, childIdEnd: number, dateStart: string, startTime: string, endTime: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addTrainingChildren(childIdStart, childIdEnd, dateStart, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アップロードファイル追加  ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Add Upload
     * @param {number} num 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addUpload(num: number, file: any, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addUpload(num, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 翌日のトレーニングを通知する ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Notify Tomorrow Training
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public notifyTomorrowTraining(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).notifyTomorrowTraining(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 公開イベントに予約を1件入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Each Event One
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public putEachEventOne(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).putEachEventOne(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     トレーニングの署名を保存      ## PERMISSION 本部ユーザーのみ実行可能
     * @summary Save Training Result Sign
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public saveSign(childId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).saveSign(childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約枠の埋まっていない枠に1件予約を入れる  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Schedule Reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public scheduleReservation(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).scheduleReservation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 重要事項説明書の変更同意書へのサイン  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Sign Change Consent Form For Important Information Manual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public signChangeConsentFormForImportantInformationManual(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).signChangeConsentFormForImportantInformationManual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Sign Individual Support Plan
     * @param {number} individualSupportPlanId 
     * @param {number} fileType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public signIndividualSupportPlan(individualSupportPlanId: number, fileType: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).signIndividualSupportPlan(individualSupportPlanId, fileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個別支援計画サイン記入  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Sign Individual Support Plan All
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public signIndividualSupportPlanAll(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).signIndividualSupportPlanAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サイン依頼済みのトレーニング結果にサイン実行  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Sign Training Result For All
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public signTrainingResultForAll(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).signTrainingResultForAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 重要事項説明書変更同意書  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Write Change Consent Form For Important Information Manual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public writeChangeConsentFormForImportantInformationManual(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).writeChangeConsentFormForImportantInformationManual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 児童発達支援提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Write Child Development Support Provision Record Sheet
     * @param {number} provisionRecordSheetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public writeChildDevelopmentSupportProvisionRecordSheet(provisionRecordSheetId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).writeChildDevelopmentSupportProvisionRecordSheet(provisionRecordSheetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 放課後等デイサービス提供実績記録票  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Write Day Service Record Sheet
     * @param {number} provisionRecordSheetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public writeDayServiceRecordSheet(provisionRecordSheetId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).writeDayServiceRecordSheet(provisionRecordSheetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング結果作成テスト  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Write Training Result
     * @param {number} trainingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public writeTrainingResult(trainingId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).writeTrainingResult(trainingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainingsApi - axios parameter creator
 * @export
 */
export const TrainingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * トレーニングレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Training
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTraining: async (trainingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('deleteTraining', 'trainingId', trainingId)
            const localVarPath = `/api/v1/web/trainings/trainings/delete_training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (trainingId !== undefined) {
                localVarQueryParameter['training_id'] = trainingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング一覧表示用レコード取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Training H001
         * @param {number} [fcFacilityId] 
         * @param {number} [status] 
         * @param {number} [absenceType] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH001: async (fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/trainings/trainings/get_H001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (absenceType !== undefined) {
                localVarQueryParameter['absence_type'] = absenceType;
            }

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング一覧表示用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Training H001 Count
         * @param {number} [fcFacilityId] 
         * @param {number} [status] 
         * @param {number} [absenceType] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH001Count: async (fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/trainings/trainings/get_H001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fcFacilityId !== undefined) {
                localVarQueryParameter['fc_facility_id'] = fcFacilityId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (absenceType !== undefined) {
                localVarQueryParameter['absence_type'] = absenceType;
            }

            if (childId !== undefined) {
                localVarQueryParameter['child_id'] = childId;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['begin_date'] = beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Training H002
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH002: async (trainingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('getTrainingH002', 'trainingId', trainingId)
            const localVarPath = `/api/v1/web/trainings/trainings/{training_id}/H002`
                .replace(`{${"training_id"}}`, encodeURIComponent(String(trainingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Training H003
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH003: async (trainingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('getTrainingH003', 'trainingId', trainingId)
            const localVarPath = `/api/v1/web/trainings/trainings/{training_id}/H003`
                .replace(`{${"training_id"}}`, encodeURIComponent(String(trainingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング計画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put H002
         * @param {TrainingH002Schema} trainingH002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putH002: async (trainingH002Schema: TrainingH002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingH002Schema' is not null or undefined
            assertParamExists('putH002', 'trainingH002Schema', trainingH002Schema)
            const localVarPath = `/api/v1/web/trainings/trainings/put_H002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingH002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング結果登録、監査ドキュメントの作成  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putH003: async (trainingH003Schema: TrainingH003Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingH003Schema' is not null or undefined
            assertParamExists('putH003', 'trainingH003Schema', trainingH003Schema)
            const localVarPath = `/api/v1/web/trainings/trainings/put_H003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingH003Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング担当スタッフ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put K001 Weekly Pic
         * @param {number} trainingId 
         * @param {number} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putK001WeeklyPic: async (trainingId: number, staffId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingId' is not null or undefined
            assertParamExists('putK001WeeklyPic', 'trainingId', trainingId)
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('putK001WeeklyPic', 'staffId', staffId)
            const localVarPath = `/api/v1/web/trainings/trainings/put_K001_weekly_pic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (trainingId !== undefined) {
                localVarQueryParameter['training_id'] = trainingId;
            }

            if (staffId !== undefined) {
                localVarQueryParameter['staff_id'] = staffId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トレーニング結果登録、監査ドキュメントの作成、サイン依頼通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Send H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSendH003: async (trainingH003Schema: TrainingH003Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingH003Schema' is not null or undefined
            assertParamExists('putSendH003', 'trainingH003Schema', trainingH003Schema)
            const localVarPath = `/api/v1/web/trainings/trainings/put_send_H003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingH003Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サイン依頼通知送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Send H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendH003: async (trainingH003Schema: TrainingH003Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainingH003Schema' is not null or undefined
            assertParamExists('sendH003', 'trainingH003Schema', trainingH003Schema)
            const localVarPath = `/api/v1/web/trainings/trainings/send_H003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingH003Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingsApi - functional programming interface
 * @export
 */
export const TrainingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingsApiAxiosParamCreator(configuration)
    return {
        /**
         * トレーニングレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Training
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTraining(trainingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTraining(trainingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング一覧表示用レコード取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Training H001
         * @param {number} [fcFacilityId] 
         * @param {number} [status] 
         * @param {number} [absenceType] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingH001(fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainingH001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingH001(fcFacilityId, status, absenceType, childId, beginDate, endDate, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング一覧表示用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Training H001 Count
         * @param {number} [fcFacilityId] 
         * @param {number} [status] 
         * @param {number} [absenceType] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingH001Count(fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingH001Count(fcFacilityId, status, absenceType, childId, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Training H002
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingH002(trainingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingH002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingH002(trainingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Training H003
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingH003(trainingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingH003Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingH003(trainingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング計画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put H002
         * @param {TrainingH002Schema} trainingH002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putH002(trainingH002Schema: TrainingH002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number | Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putH002(trainingH002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング結果登録、監査ドキュメントの作成  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putH003(trainingH003Schema: TrainingH003Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putH003(trainingH003Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング担当スタッフ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put K001 Weekly Pic
         * @param {number} trainingId 
         * @param {number} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putK001WeeklyPic(trainingId: number, staffId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putK001WeeklyPic(trainingId, staffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トレーニング結果登録、監査ドキュメントの作成、サイン依頼通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Send H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSendH003(trainingH003Schema: TrainingH003Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSendH003(trainingH003Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サイン依頼通知送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Send H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendH003(trainingH003Schema: TrainingH003Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendH003(trainingH003Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrainingsApi - factory interface
 * @export
 */
export const TrainingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingsApiFp(configuration)
    return {
        /**
         * トレーニングレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Delete Training
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTraining(trainingId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteTraining(trainingId, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング一覧表示用レコード取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Training H001
         * @param {number} [fcFacilityId] 
         * @param {number} [status] 
         * @param {number} [absenceType] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH001(fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<TrainingH001Schema>> {
            return localVarFp.getTrainingH001(fcFacilityId, status, absenceType, childId, beginDate, endDate, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング一覧表示用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
         * @summary Get Training H001 Count
         * @param {number} [fcFacilityId] 
         * @param {number} [status] 
         * @param {number} [absenceType] 
         * @param {number} [childId] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH001Count(fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getTrainingH001Count(fcFacilityId, status, absenceType, childId, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Training H002
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH002(trainingId: number, options?: any): AxiosPromise<TrainingH002Schema> {
            return localVarFp.getTrainingH002(trainingId, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get Training H003
         * @param {number} trainingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingH003(trainingId: number, options?: any): AxiosPromise<TrainingH003Schema> {
            return localVarFp.getTrainingH003(trainingId, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング計画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put H002
         * @param {TrainingH002Schema} trainingH002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putH002(trainingH002Schema: TrainingH002Schema, options?: any): AxiosPromise<number | Result> {
            return localVarFp.putH002(trainingH002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング結果登録、監査ドキュメントの作成  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putH003(trainingH003Schema: TrainingH003Schema, options?: any): AxiosPromise<Result> {
            return localVarFp.putH003(trainingH003Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング担当スタッフ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put K001 Weekly Pic
         * @param {number} trainingId 
         * @param {number} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putK001WeeklyPic(trainingId: number, staffId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.putK001WeeklyPic(trainingId, staffId, options).then((request) => request(axios, basePath));
        },
        /**
         * トレーニング結果登録、監査ドキュメントの作成、サイン依頼通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Put Send H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSendH003(trainingH003Schema: TrainingH003Schema, options?: any): AxiosPromise<Result> {
            return localVarFp.putSendH003(trainingH003Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * サイン依頼通知送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Send H003
         * @param {TrainingH003Schema} trainingH003Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendH003(trainingH003Schema: TrainingH003Schema, options?: any): AxiosPromise<Result> {
            return localVarFp.sendH003(trainingH003Schema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainingsApi - object-oriented interface
 * @export
 * @class TrainingsApi
 * @extends {BaseAPI}
 */
export class TrainingsApi extends BaseAPI {
    /**
     * トレーニングレコード削除  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Delete Training
     * @param {number} trainingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public deleteTraining(trainingId: number, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).deleteTraining(trainingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング一覧表示用レコード取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Training H001
     * @param {number} [fcFacilityId] 
     * @param {number} [status] 
     * @param {number} [absenceType] 
     * @param {number} [childId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getTrainingH001(fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getTrainingH001(fcFacilityId, status, absenceType, childId, beginDate, endDate, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング一覧表示用レコード件数取得   ## FACILITY PERMISSION  本部ユーザーは実行可能。  スタッフは、特定の施設に所属していれば実行可能。  親は実行不可。
     * @summary Get Training H001 Count
     * @param {number} [fcFacilityId] 
     * @param {number} [status] 
     * @param {number} [absenceType] 
     * @param {number} [childId] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getTrainingH001Count(fcFacilityId?: number, status?: number, absenceType?: number, childId?: number, beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getTrainingH001Count(fcFacilityId, status, absenceType, childId, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Training H002
     * @param {number} trainingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getTrainingH002(trainingId: number, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getTrainingH002(trainingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング詳細用レコード取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get Training H003
     * @param {number} trainingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getTrainingH003(trainingId: number, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getTrainingH003(trainingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング計画登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put H002
     * @param {TrainingH002Schema} trainingH002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public putH002(trainingH002Schema: TrainingH002Schema, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).putH002(trainingH002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング結果登録、監査ドキュメントの作成  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put H003
     * @param {TrainingH003Schema} trainingH003Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public putH003(trainingH003Schema: TrainingH003Schema, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).putH003(trainingH003Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング担当スタッフ登録  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put K001 Weekly Pic
     * @param {number} trainingId 
     * @param {number} staffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public putK001WeeklyPic(trainingId: number, staffId: number, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).putK001WeeklyPic(trainingId, staffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トレーニング結果登録、監査ドキュメントの作成、サイン依頼通知  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Put Send H003
     * @param {TrainingH003Schema} trainingH003Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public putSendH003(trainingH003Schema: TrainingH003Schema, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).putSendH003(trainingH003Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サイン依頼通知送信  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Send H003
     * @param {TrainingH003Schema} trainingH003Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public sendH003(trainingH003Schema: TrainingH003Schema, options?: AxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).sendH003(trainingH003Schema, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadsApi - axios parameter creator
 * @export
 */
export const UploadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アップロードデータ詳細削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete N002
         * @param {number} uploadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteN002: async (uploadId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('deleteN002', 'uploadId', uploadId)
            const localVarPath = `/api/v1/web/uploads/delete_N002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アップロードデータ一覧取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N001
         * @param {number} [category] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN001: async (category?: number, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/uploads/get_N001`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アップロードデータ一覧件数取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N001 Count
         * @param {number} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN001Count: async (category?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/uploads/get_N001_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アップロードデータ詳細取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N002
         * @param {number} [uploadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN002: async (uploadId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/uploads/get_N002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ダウンロードデータ取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get N003
         * @param {number} [category] 
         * @param {string} [title] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN003: async (category?: number, title?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/uploads/get_N003`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ダウンロードデータ件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get N003 Count
         * @param {number} [category] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN003Count: async (category?: number, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/web/uploads/get_N003_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アップロードデータ詳細登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put N002
         * @param {N002Schema} n002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putN002: async (n002Schema: N002Schema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'n002Schema' is not null or undefined
            assertParamExists('putN002', 'n002Schema', n002Schema)
            const localVarPath = `/api/v1/web/uploads/put_N002`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(n002Schema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アップロードデータファイル登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put N002 File
         * @param {number} uploadId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putN002File: async (uploadId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('putN002File', 'uploadId', uploadId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('putN002File', 'file', file)
            const localVarPath = `/api/v1/web/uploads/put_N002_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadsApi - functional programming interface
 * @export
 */
export const UploadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadsApiAxiosParamCreator(configuration)
    return {
        /**
         * アップロードデータ詳細削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete N002
         * @param {number} uploadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteN002(uploadId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteN002(uploadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アップロードデータ一覧取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N001
         * @param {number} [category] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getN001(category?: number, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<N001Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getN001(category, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アップロードデータ一覧件数取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N001 Count
         * @param {number} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getN001Count(category?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getN001Count(category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アップロードデータ詳細取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N002
         * @param {number} [uploadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getN002(uploadId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<N002Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getN002(uploadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ダウンロードデータ取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get N003
         * @param {number} [category] 
         * @param {string} [title] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getN003(category?: number, title?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<N003Schema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getN003(category, title, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ダウンロードデータ件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get N003 Count
         * @param {number} [category] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getN003Count(category?: number, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getN003Count(category, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アップロードデータ詳細登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put N002
         * @param {N002Schema} n002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putN002(n002Schema: N002Schema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putN002(n002Schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アップロードデータファイル登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put N002 File
         * @param {number} uploadId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putN002File(uploadId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putN002File(uploadId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadsApi - factory interface
 * @export
 */
export const UploadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadsApiFp(configuration)
    return {
        /**
         * アップロードデータ詳細削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Delete N002
         * @param {number} uploadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteN002(uploadId: number, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteN002(uploadId, options).then((request) => request(axios, basePath));
        },
        /**
         * アップロードデータ一覧取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N001
         * @param {number} [category] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN001(category?: number, skip?: number, limit?: number, options?: any): AxiosPromise<Array<N001Schema>> {
            return localVarFp.getN001(category, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * アップロードデータ一覧件数取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N001 Count
         * @param {number} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN001Count(category?: number, options?: any): AxiosPromise<number> {
            return localVarFp.getN001Count(category, options).then((request) => request(axios, basePath));
        },
        /**
         * アップロードデータ詳細取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Get N002
         * @param {number} [uploadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN002(uploadId?: number, options?: any): AxiosPromise<N002Schema> {
            return localVarFp.getN002(uploadId, options).then((request) => request(axios, basePath));
        },
        /**
         * ダウンロードデータ取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get N003
         * @param {number} [category] 
         * @param {string} [title] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN003(category?: number, title?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<N003Schema>> {
            return localVarFp.getN003(category, title, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * ダウンロードデータ件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
         * @summary Get N003 Count
         * @param {number} [category] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getN003Count(category?: number, title?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getN003Count(category, title, options).then((request) => request(axios, basePath));
        },
        /**
         * アップロードデータ詳細登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put N002
         * @param {N002Schema} n002Schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putN002(n002Schema: N002Schema, options?: any): AxiosPromise<number> {
            return localVarFp.putN002(n002Schema, options).then((request) => request(axios, basePath));
        },
        /**
         * アップロードデータファイル登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
         * @summary Put N002 File
         * @param {number} uploadId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putN002File(uploadId: number, file: any, options?: any): AxiosPromise<Result> {
            return localVarFp.putN002File(uploadId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadsApi - object-oriented interface
 * @export
 * @class UploadsApi
 * @extends {BaseAPI}
 */
export class UploadsApi extends BaseAPI {
    /**
     * アップロードデータ詳細削除  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Delete N002
     * @param {number} uploadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public deleteN002(uploadId: number, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).deleteN002(uploadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アップロードデータ一覧取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get N001
     * @param {number} [category] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public getN001(category?: number, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).getN001(category, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アップロードデータ一覧件数取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get N001 Count
     * @param {number} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public getN001Count(category?: number, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).getN001Count(category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アップロードデータ詳細取得  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Get N002
     * @param {number} [uploadId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public getN002(uploadId?: number, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).getN002(uploadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ダウンロードデータ取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get N003
     * @param {number} [category] 
     * @param {string} [title] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public getN003(category?: number, title?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).getN003(category, title, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ダウンロードデータ件数取得  ## PERMISSION 本部ユーザー or スタッフのみ実行可能
     * @summary Get N003 Count
     * @param {number} [category] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public getN003Count(category?: number, title?: string, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).getN003Count(category, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アップロードデータ詳細登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put N002
     * @param {N002Schema} n002Schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public putN002(n002Schema: N002Schema, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).putN002(n002Schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アップロードデータファイル登録  ## PERMISSION 本部スタッフかつ自分自身であれば実行可能
     * @summary Put N002 File
     * @param {number} uploadId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public putN002File(uploadId: number, file: any, options?: AxiosRequestConfig) {
        return UploadsApiFp(this.configuration).putN002File(uploadId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


